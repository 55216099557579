import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef } from 'react'


const InputTag = ({ inputValue, inputType, placeholderText, FaIcon, size, clickedFn, Height, inputvalue, onKeyDown, icon = false, disabled = false }: any) => {
    const inputElement = useRef<HTMLInputElement>(null);
    return (
        <>
            <div className={`input-group  ${size}`}>

                <input type={inputType} ref={inputElement} className="form-control" placeholder={placeholderText} onChange={e => {inputValue?.(e.target.value)}} onKeyDown={onKeyDown} value={inputvalue} disabled={disabled} />
                {
                    icon && <div className="input-group-prepend">
                        <span className="input-group-text">
                            <a ><FontAwesomeIcon icon={FaIcon} onClick={() => clickedFn(inputElement.current?.value)} /></a>
                        </span>
                    </div>
                }
            </div >
        </>
    )
}

export default InputTag
