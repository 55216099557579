/*    
<summary>
  This functional component "LoginForm" provides form to login into application on the basis of the credentials provided.
  Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import ILogin from "../../../models/ILogin";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormCheckBox from "../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { LoginValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/Label/Label";
import { Button } from "react-bootstrap";
import RoutesConstants from "../../../shared-components/Routes/routes.constants";
import { useNavigate } from "react-router-dom";

interface IProps {
  loginHandler: (data: ILogin) => void;
  initialValues: ILogin;
  isLoading: boolean;
  isResetPassword: boolean;
  resetPasswordError: string;
  redirectURL: string | null;
}

const LoginForm = (props: IProps) => {
  const navigate = useNavigate();
  const [showHidePassword, setShowHidePassword] = useState(false);
  return (
    <>
      <Formik
        initialValues={props.initialValues}
        validationSchema={LoginValidateSchema}
        validateOnBlur={false}
        onSubmit={async (values) => {
          props.loginHandler(values);
        }}
      >
        <Form className="login">
            {props.isResetPassword 
            ?
              <div className="card-body p-2 rounded mb-4" style={{ backgroundColor: "green" }}>
                  <h4 className="text-white ">
                      <i className="fa fa-check-circle"></i> &nbsp;New password set successfully.
                  </h4>
              </div>
            :
            props.resetPasswordError && props.resetPasswordError != "" &&
              <div className="card-body p-2 rounded mb-4" style={{ backgroundColor: "red" }}>
                  <h4 className="text-white ">
                      <i className="fa fa-times-circle"></i> &nbsp;{props.resetPasswordError}.
                  </h4>
              </div>
            }


          <div className="loginBox">
            <div className="form-group">
              <Label
                required={true}
                label="user_label_login_id"
                htmlFor="email"
              />
              <Field
                data-testid="Username"
                className="form-control"
                name="email"
                type="text"
                invalidcharacter={[" "]}
                placeholder="user_label_login_id"
                validcharacter={[
                  "-",
                  "_",
                  "[",
                  "]",
                  "(",
                  ")",
                  ":",
                  "#",
                  "@",
                  ".",
                ]}
                as={FormikFormInput}
              />
            </div>
            <div className="showHideOuterDiv form-group">
              <Label
                required={true}
                label="user_label_password"
                htmlFor="password"
              />
              <Field
                data-testid="Password"
                className="form-control"
                name="password"
                type={showHidePassword ? "text" : "password"}
                invalidcharacter={[" "]}
                validcharacter={[
                  "~",
                  "`",
                  "!",
                  "@",
                  "#",
                  "$",
                  "%",
                  "^",
                  "&",
                  "*",
                  "(",
                  ")",
                  "_",
                  "-",
                  "+",
                  "=",
                  "{",
                  "[",
                  "}",
                  "]",
                  "|",
                  "\\",
                  ":",
                  ";",
                  "'",
                  '"',
                  "<",
                  ",",
                  ">",
                  ".",
                  "?",
                  "/",
                ]}
                placeholder="user_label_password"
                as={FormikFormInput}
              />

              {showHidePassword ? (
                <svg
                  type="button"
                  onClick={() => {
                    setShowHidePassword(!showHidePassword);
                  }}
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="showHidePass css-i6dzq1"
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              ) : (
                <svg
                  type="button"
                  onClick={() => {
                    setShowHidePassword(!showHidePassword);
                  }}
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="showHidePass css-i6dzq1"
                >
                  <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                  <line x1="1" y1="1" x2="23" y2="23"></line>
                </svg>
              )}

            </div>
            <div className="checkbox">
              {/* <label className="withlabel-checkbox1"> */}
                <Field
                  data-testid="RememberMe"
                  className="form-check-input mt-1"
                  name="rememberMe"
                  type="checkbox"
                  id="inlineFormCheck"
                  as={FormikFormCheckBox}
                />
                {/* <span className="cr">
                  <i className="cr-icon fas fa-check"></i>
                </span> */}
              {/* </label> */}
              <h4 className="remember">
                <FormattedMessage id="remember_me" />
              </h4>
            </div>
            <p className="text-end"><a role='button' onClick={() => navigate(RoutesConstants.ForgotPassword)}><FormattedMessage id="forgot_password" /></a></p>
          </div>
          <LoaderButton
            type="submit"
            id="LoginButton"
            className="btn btn-primary btn-user"
            isLoading={props.isLoading}
            text="login"
            // text="Login with OTP"
          />
          <p className="text-center mt-2">
            <FormattedMessage id="not_a_member" /> &nbsp;
            <a
              href="javascript:;"
              className=""
              onClick={() =>  (props.redirectURL != null && props.redirectURL != "") ? navigate(`${RoutesConstants.Registration}?continue=${encodeURIComponent(props.redirectURL)}`) : navigate(RoutesConstants.Registration)}>
              <FormattedMessage id="create_your_account" />
            </a>
          </p>
        </Form>
      </Formik>

    </>
  );
};

export default LoginForm;
