import React, { useState } from "react";
const FriendRequestPopupHOC = (Component: any) =>
    function Comp(props: any) {
        const [showFriendRequestPopup, setShowFriendRequestPopup] = useState<boolean>(false);

        const friendRequestPopupToggleHandler = () => {
            setShowFriendRequestPopup(!showFriendRequestPopup);
        };

        return (
            <Component
                showFriendRequestPopup={showFriendRequestPopup}
                friendRequestPopupToggleHandler={friendRequestPopupToggleHandler}
                {...props}
            />
        );
    };

export default FriendRequestPopupHOC;