import React from 'react'
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const SubCategoryDetailsModal = (props: any) => {
    return (
        <>
            <Modal show={true} centered
                onHide={props.modalClosed}
                scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id={props.HiddenData.ResourceKey} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="modal-form">
                            <p><FormattedMessage id={props.HiddenData.Details} /></p>
                        </div>
                    </>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default SubCategoryDetailsModal
