import React from 'react';
import { FormattedMessage } from 'react-intl';
import './Contact.css';
import ContactForm from './ContactForm';

const Contact = (props: any) => {
    return (
        <>
            <div className="banner--light breadcrumb-area">
                <div className="container">
                    <h1 className="page-title"><FormattedMessage id="contact_us" /></h1>
                </div>
            </div>
            <div className="container py-5">
                <div className='container--contact'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="about_content ">
                                <div className="about-item">                                                 
                                    <h2 className="about-title"> <FormattedMessage id="Main_Contact" /></h2>
                                    <div className="about-des"> <FormattedMessage id="contact_Us_Para" /> </div>            
                                    <div className="icon-box">
                                        <div className="icon"><i aria-hidden="true" className="fas fa-envelope"></i>                                    </div>
                                        <div className="icon-box-content"> 
                                            <div className="icon-box-title-label"> <FormattedMessage id="user_label_login_id"/> </div>
                                            <h6 className="icon-box-title"><a href="mailto:kaleidocompass@humanskills.jp"><FormattedMessage id="contact_mail" /></a></h6>
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <ContactForm invertFormColors={false} />
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}
export default Contact;