import logo from '../../assets/Images/logo_original.png';
import React, { useState } from 'react';
import './Instructions.css';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import { useStore } from '../../../contexts/StoreProvider';
import firstStepImg from '../../../assets/Images/app-kaleido-en-1030x340.png';
import resultStepImg from '../../../assets/Images/coppia-EN-300x207.png';

const Instructions = (props: any) => {
    const { preferencesStore } = useStore();
    const lang = preferencesStore.getLanguage;
    return (
        <>
            <div className="banner--light breadcrumb-area">
                <div className="container">
                    <h1 className="page-title"><FormattedMessage id="instructions" /></h1>
                </div>
            </div>

            <section className="container py-5">
                <div className='row'>
                    <div className='col-lg-6 col-12 mb-5'>
                        <span className='about-small-stitle'><FormattedMessage id="firststep" /></span>
                        <h2 className='about-title'><FormattedMessage id="how_to" /></h2>
                        <p><FormattedMessage id="how_to_1" /></p>
                        <p><FormattedMessage id="how_to_2" /></p>
                        <p><FormattedMessage id="how_to_3" /></p>
                    </div>
                    <div className='col-lg-6 col-12 mb-5'>
                        <img src={firstStepImg} alt='' className='img-fluid' />
                    </div>
                </div>
            </section>
            <section className="container py-5">
                <div className='row flex-md-row-reverse'>
                    <div className='col-lg-6 col-12 mb-5'>
                        <span className='about-small-stitle'><FormattedMessage id="secondstep" /></span>
                        <h2 className='about-title'><FormattedMessage id="how_to_result" /></h2>
                        <p><FormattedMessage id="how_to_result_1" /></p>
                        <p>
                            <FormattedMessage id="how_to_result_2" />                            
                        </p>
                        <h3 className='mt-4'>
                            <FormattedMessage id="how_to_result_3" />
                        </h3>
                        <ul className="customList">
                            <li><FormattedMessage id="how_to_result_4" /></li>
                            <li><FormattedMessage id="how_to_result_5" /></li>
                        </ul>

                        <h3 className='mt-4'>
                        <FormattedMessage id="how_to_result_6" />
                        </h3>
                        <ul className="customList">
                            <li><FormattedMessage id="how_to_result_7" /></li>
                            <li><FormattedMessage id="how_to_result_8" /></li>
                        </ul>
                    </div>
                    <div className='col-lg-6 col-12 mb-5'>
                        <img src={resultStepImg} alt='' className='img-fluid' />
                    </div>
                </div>
            </section>
            <div className="figures_section">
                <div className="container text-center">
                <h1 className="section-title text-center text-white"><FormattedMessage id="detailed" /></h1>
                <Button
                    onClick={props.onClick}
                    variant={props.variant}
                    type="button"
                    target='_blank'
                    href={lang == "jp" ? "https://humanskills.jp/" : "https://humanskills.jp/english/"}
                    className={`btn btn-blue primary-btn`}>
                    <FormattedMessage id="click_here" />
                </Button>
                </div>
            </div>
        </>
    )
}
export default observer(Instructions);