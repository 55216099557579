import { Field, Form, Formik, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { useStore } from '../../../contexts/StoreProvider';
import { observer } from 'mobx-react';
import Label from '../../../shared-components/Label/Label';
import { Button } from 'react-bootstrap';
import { initialState as initialRegistrationState } from '../../../core/initial-state/registration-state';
import nationData from '.././nation.json';
import ILogin from '../../../models/ILogin';
import RoutesConstants from '../../../shared-components/Routes/routes.constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoaderButton from '../../../shared-components/Button/LoaderButton';
import TopNavBar from '../../../shared-components/NavBar/TopNavBar';
import Footer from "../../../shared-components/Footer/Footer";
import { registrationFormValidationSchema } from '../../../shared-components/FormikFormHandlers/FormikValidationSchema';
import VerificationPopupHOC from '.././hoc/VerificationPopupHOC';
import VerificationModal, { OTPModalType } from '.././VerificationPopup/VerificationModal';
import { IAddUser } from '../../../models/Form/IAddUser';
import FormikFormInput from '../../../shared-components/FormikFormHandlers/FormikFormInput';
import FormikDateFieldMUI from '../../../shared-components/FormikFormHandlers/FormikDateFieldMUI';
import FormikFormSelect from '../../../shared-components/FormikFormHandlers/FormikFormSelect';
import { educationOptions, genderOptions, jobSectorOptions, nationOptions, workPostionOptions } from '../Registration';
import FormikFormCheckBox from '../../../shared-components/FormikFormHandlers/FormikFormCheckBox';

interface IProps {
    initialValues: IAddUser;
    submitHandler: (data: IAddUser) => void;
    isLoading: boolean;
    refererProgress: boolean;
    refererSuccess: boolean;
    refererError: string;
    handleRefererValidation: (refererEmail: string) => void;
    handleRefererEdit: () => void;
    handleResetRefererState: () => void;
}

const RegistrationForm = (props: IProps) => {
    const { preferencesStore, authStore } = useStore();
    const lang = preferencesStore.getLanguage;
    const [showHidePassword, setShowHidePassword] = useState(false);

    return (
        <>
        <Formik initialValues={props.initialValues} 
                validationSchema={registrationFormValidationSchema} 
                onSubmit={async (values) => {
                    props.submitHandler(values);
                }}>
            {({ values, errors, handleChange, handleBlur }) => (
            <Form className="registration-form">
                <div className='row'>
                    <div className='col-md-6' >
                        <div className="form-group">
                            <Label
                                required={true}
                                label="email"
                                htmlFor="Email"
                            />
                            <Field
                                data-testid="Username"
                                className="form-control"
                                name="Email"
                                type="text"
                                invalidcharacter={[" "]}
                                placeholder="user_label_login_id"
                                validcharacter={[
                                "-",
                                "_",
                                "[",
                                "]",
                                "(",
                                ")",
                                ":",
                                "#",
                                "@",
                                ".",
                                ]}
                                as={FormikFormInput}
                            />
                        </div>
                    </div>
                    <div className='col-md-6' >
                        <div className="form-group showHideOuterDiv">
                            <Label
                                required={true}
                                label="password"
                                htmlFor="password"
                            />
                            <Field
                                data-testid="Password"
                                className="form-control"
                                name="Password"
                                type={showHidePassword ? "text" : "password"}
                                invalidcharacter={[" "]}
                                validcharacter={[
                                "~",
                                "`",
                                "!",
                                "@",
                                "#",
                                "$",
                                "%",
                                "^",
                                "&",
                                "*",
                                "(",
                                ")",
                                "_",
                                "-",
                                "+",
                                "=",
                                "{",
                                "[",
                                "}",
                                "]",
                                "|",
                                "\\",
                                ":",
                                ";",
                                "'",
                                '"',
                                "<",
                                ",",
                                ">",
                                ".",
                                "?",
                                "/",
                                ]}
                                placeholder="user_label_password"
                                as={FormikFormInput}
                            />
                            {showHidePassword ? (
                                <svg
                                    type="button"
                                    onClick={() => {
                                        setShowHidePassword(!showHidePassword);
                                    }}
                                    viewBox="0 0 24 24"
                                    width="20"
                                    height="20"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    className="showHidePass css-i6dzq1"
                                    >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                                ) : (
                                <svg
                                    type="button"
                                    onClick={() => {
                                        setShowHidePassword(!showHidePassword);
                                    }}
                                    viewBox="0 0 24 24"
                                    width="20"
                                    height="20"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="showHidePass css-i6dzq1"
                                    >
                                    <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                                    <line x1="1" y1="1" x2="23" y2="23"></line>
                                </svg>
                                )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6' >
                        <div className="form-group">
                            <Label
                                required={true}
                                label="name"
                                htmlFor="FirstName"
                            />
                            <Field
                                data-testid="FirstName"
                                className="form-control"
                                name='FirstName'
                                type="text"
                                invalidcharacter={[" ", "@"]}
                                placeholder="name"
                                validcharacter={[
                                ]}
                                as={FormikFormInput}
                            />
                        </div>
                    </div>
                    <div className='col-md-6' >
                        <div className="form-group">
                            <Label
                                required={true}
                                label="family_name"
                                htmlFor="LastName"
                            />
                            <Field
                                data-testid="LastName"
                                className="form-control"
                                name='LastName'
                                type="text"
                                invalidcharacter={[" ", "@"]}
                                placeholder="family_name"
                                validcharacter={[
                                ]}
                                as={FormikFormInput}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6' >
                        <div className="form-group">
                            <Label
                                required={true}
                                label="DOB"
                                htmlFor="DateOfBirth"
                            />
                            <Field
                                data-testid="DateOfBirth"
                                className="form-control"
                                name="DateOfBirth"
                                max={Date.now()}
                                placeholder="DOB"
                                as={FormikDateFieldMUI}
                            />
                        </div>
                    </div>
                    <div className='col-md-6' >
                        <div className="form-group">
                            <Label
                                required={true}
                                label="gender"
                                htmlFor="gender"
                            />
                            <Field
                                data-testid="gender"
                                className="form-control"
                                name="Gender"
                                placeholder="gender"
                                as={FormikFormSelect}
                                options={genderOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6' >
                        <div className="form-group">
                            <Label
                                required={true}
                                label="COR"
                                htmlFor="COR"
                            />
                            <Field
                                data-testid="COR"
                                className="form-control"
                                name="CountryOfResidence"
                                placeholder="COR"
                                as={FormikFormInput}
                            />
                        </div>
                    </div>
                    <div className='col-md-6' >
                        <div className="form-group">
                            <Label
                                required={true}
                                label="nation"
                                htmlFor="nation"
                            />
                            <Field
                                data-testid="nation"
                                className="form-control"
                                name="Nation"
                                placeholder="nation"
                                as={FormikFormSelect}
                                options={nationOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6' >
                        <div className="form-group">
                            <Label
                                label="Education"
                                htmlFor="Education"
                            />
                            <Field
                                data-testid="Education"
                                className="form-control"
                                name="Education"
                                as={FormikFormSelect}
                                options={educationOptions}
                            />
                        </div>
                    </div>
                    <div className='col-md-6' >
                        <div className="form-group">
                            <Label
                                label="Work_position"
                                htmlFor="Work_position"
                            />
                            <Field
                                data-testid="WorkPosition"
                                className="form-control"
                                name="WorkPosition"
                                as={FormikFormSelect}
                                options={workPostionOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6' >
                        <div className="form-group">
                            <Label
                                label="job"
                                htmlFor="JobSector"
                            />
                            <Field
                                data-testid="JobSector"
                                className="form-control"
                                name="JobSector"
                                as={FormikFormSelect}
                                options={jobSectorOptions}
                            />
                        </div>
                    </div>
                    <div className='col-md-6' >
                        <div className="form-group">
                            <Label label="Referent" htmlFor="Referent" />
                            <div className="input-group">
                                <Field
                                    data-testid="Referent"
                                    disabled={props.refererSuccess}
                                    className="form-control"
                                    name="Referent"
                                    type="email"
                                    invalidcharacter={[" "]}
                                    placeholder="Referent"
                                    validcharacter={[
                                    "-",
                                    "_",
                                    "[",
                                    "]",
                                    "(",
                                    ")",
                                    ":",
                                    "#",
                                    "@",
                                    ".",
                                    ]}
                                    onChange={(e: any) => {
                                        handleChange(e);
                                        if (props.refererError !== "") {
                                              props.handleResetRefererState();
                                        }
                                    }}
                                    as={FormikFormInput}
                                />
                                <LoaderButton
                                    className="btn btn-primary input-group-text"
                                    type="button"
                                    id="ValidateReferer"
                                    isLoading={props.refererProgress}
                                    onClick={() =>  !props.refererSuccess ? props.handleRefererValidation(values.Referent) : props.handleRefererEdit()}
                                    disabled={!values.Referent}
                                    text={!props.refererSuccess ? "Validate" : "Edit"}
                                    />
                            </div>
                            {props.refererSuccess &&
                                <div className="text-success m-2 errorMessage"><i className="fa fa-check-circle"></i> &nbsp;Verified</div>}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group col-md-12 align-items-center" id="Privacy">
                        <div className="checkbox d-flex flex-row-reverse justify-content-end flex-wrap">
                            <h4 className="remember" style={{ marginTop: '3px'}}>
                                <FormattedMessage id="privacy" />.
                                <a href={lang == "jp" ? "https://humanskills.jp/pp/" : "https://humanskills.jp/engpp/"} target='_blank'>
                                    &nbsp;Read Here
                                </a>
                            </h4>
                            <Field
                                data-testid="Privacy"
                                className="form-check-input mt-1 privacy-checkbox"
                                name="Privacy"
                                type="checkbox"
                                id="inlineFormCheck"
                                as={FormikFormCheckBox}
                                />
                        </div>
                    </div>
                    <div className="form-group col-md-12 text-center">
                        <LoaderButton type="submit"
                            isLoading={props.isLoading}
                            className="btn-user"
                            text="register_with_otp"/>
                    </div>
                </div>
            </Form>
            )}
        </Formik>
        </>
    )
}

export default observer(RegistrationForm);