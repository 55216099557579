import React, { useState } from "react";

const CreateFilterHOC = (Component: any) =>
  function Comp(props: any) {
    const [showCreateFilterPopup, setShowCreateFilterPopup] = useState<boolean>(false);
    
    const createFilterToggleHandler = () => {
      setShowCreateFilterPopup(!showCreateFilterPopup);
    };

    return (
      <Component
        showCreateFilterPopup={showCreateFilterPopup}
        createFilterPopupToggleHandler={createFilterToggleHandler}
        {...props}
      />
    );
  };

export default CreateFilterHOC;