import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormTypeEnum } from "../../../constants/enum";
import AddEditStatForm from "../AddEditStatForm/AddEditStatForm";
import { IStatState } from "../../../models/state/IStatState";


interface IProps {
    modalClosed: () => void;
    editStatId?: any
    setFetchStats: any
    editIndex:any;
    isLoading:boolean;
}

const EditStatPopup = (props: IProps) => {
    const { modalClosed, setFetchStats,editIndex } = props;

    const onSubmitForm = (values: IStatState) => {
        let StoringValue: any = JSON.parse(localStorage.getItem("statFilterList") as string)
        StoringValue[editIndex] = values
        localStorage.setItem("statFilterList", JSON.stringify(StoringValue))
        setFetchStats((old: boolean) => !old)
        modalClosed();
    };

    return (
        <AddEditStatForm modalClosed={props.modalClosed} isLoading={props.isLoading} initialValues={props.editStatId} FormType={FormTypeEnum.Edit} submitHandler={onSubmitForm} EditStatId={props.editStatId} />
    );
};

export default observer(EditStatPopup);
