/*    
<summary>
   This functional loginlayout component redirect the user to landing page of application if he attemps to go to login page
   after loggedin into the application. 
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React from 'react';
import { Navigate } from 'react-router-dom';
import authStore from '../../core/stores/auth-store';
import RoutesConstants from '../Routes/routes.constants';
import './LoginLayout.css';
const LoginLayout: React.FC<any> = (props) => {
    const { getToken, isAuthenticated } = authStore;
    return (
        <React.Fragment>
            {(!isAuthenticated && !getToken) ? props.children : <Navigate to={RoutesConstants.Dashboard} />}
        </React.Fragment>
    );
};
export default LoginLayout;
