import React, { useEffect, useState } from 'react'
import IQuestionInfo, { IReplyInfo, ISubQuestionInfo } from '../../../models/response/ITestQuestion';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { Radio, RadioGroup } from '@mui/material';
import { TestType as TestTypeEnum } from '../../../constants/enum';
import { formatMessage } from '../../../translations/format-message';

export const questionOptions = [
    { id: "1", value: "NEVER", color: "#ec5e4e" },
    { id: "2", value: "SELDOM", color: "#fc95b2" },
    { id: "3", value: "SOMETIMES", color: "#f9ca24" },
    { id: "4", value: "OFTEN", color: "#f0962a"},
    { id: "5", value: "ALWAYS", color: "#4330d7" },
];

interface ISubQuestionAnswerMap {
    Key: string;
    OptionId: number;
}

interface ISurveyQuestionAnswerMap {
    Key: string;
    ReplyKey: string;
}

interface IProps {
    TestType: TestTypeEnum;
    Question: IQuestionInfo;
    SetQuestionScore?: (questionKey: string, subQuestionKey: string, score: number) => void;
    SetQuestionReply?: (questionKey: string, replyKey: string) => void;
}

const TestCard = (props: IProps) => {
    const [testTypeStr, setTestTypeStr] = useState<string>(`${TestTypeEnum[props.TestType].toString().toUpperCase()}`);
    const [quesAnswerMap, setQuesAnswerMap] = useState<Array<ISubQuestionAnswerMap>>([]);
    const [surveyQAMap, setSurveyQAMap] = useState<ISurveyQuestionAnswerMap>();

    const handleChange = (subQuestionKey: string, optionId: number) => {
        if (props.SetQuestionScore) 
            props.SetQuestionScore(props.Question.Key, subQuestionKey, optionId);
        const qa = quesAnswerMap.find(x => x.Key == subQuestionKey);
        if (qa) 
            qa.OptionId = optionId;
        setQuesAnswerMap([...quesAnswerMap]);
    };

    const handleChange_Reply = (questionKey: string, replyKey: string) => {
        if (props.SetQuestionReply)
            props.SetQuestionReply(props.Question.Key, replyKey);
        if (surveyQAMap)
            surveyQAMap.ReplyKey = replyKey;
        setSurveyQAMap(() => surveyQAMap);
    }

    const handleUndoClick = (questionKey: string, subQuestionKey: string) => {
        if (props.SetQuestionScore)
            props.SetQuestionScore(questionKey, subQuestionKey, 0);
        const qa = quesAnswerMap.find(x => x.Key == subQuestionKey);
        if (qa) 
            qa.OptionId = 0;
        setQuesAnswerMap([...quesAnswerMap]);
    }

    const handleUndoReply_Click = (questionKey: string) => {
        if (props.SetQuestionReply)
            props.SetQuestionReply(questionKey, '');
        if (surveyQAMap) 
            surveyQAMap.ReplyKey = '';
        setSurveyQAMap(() => surveyQAMap);
    }
  
    useEffect(() => {
        if (props.Question?.SubQuestions?.length > 0) {
            const tempQuesAnsMap: ISubQuestionAnswerMap[] = []
            props.Question.SubQuestions.forEach(x => {
                tempQuesAnsMap.push({Key: x.Key, OptionId: 0})
            })
            setQuesAnswerMap([...tempQuesAnsMap]);
        }
        if (props.Question.OptionsList && props.Question.OptionsList.length > 1) {
            const tempQuesAnsMap: ISurveyQuestionAnswerMap = { Key: props.Question.Key, ReplyKey: '' };
            setSurveyQAMap(() => tempQuesAnsMap);
        }
    }, []);

    return (
        <>
            {
                <>
                    <div className="card mt-5 borderedCard">
                        <div className='card-header'>
                            <h3><FormattedMessage id={`${testTypeStr}_${props.Question.Key}`} /></h3>
                        </div>
                        <div className="card-body row py-0 pt-lg-3">
                            {
                                props.Question.SubQuestions &&
                                props.Question.SubQuestions.length > 1 &&
                                props.Question.SubQuestions.map((subQuestion: ISubQuestionInfo) => {
                                    const currentQuesAnswerMap = quesAnswerMap.find(x => x.Key == subQuestion.Key);
                                    return(
                                    <>
                                        <div className={`py-3 py-lg-0 border-bottom border-lg-0 ${props.Question.SubQuestions.length == 2 ? "col-lg-6 col-12" : "col-lg-4 col-12"}`}>
                                            <div className={`${currentQuesAnswerMap?.OptionId != 0 ? "optionSelected" : ""}`}>
                                                <h4 >
                                                    <FormattedMessage id={`${testTypeStr}_${subQuestion.Key}`} />                                                   
                                                </h4>
                                                <div className="text-center">
                                                    <div className='d-flex justify-content-start align-items-start radiobtnWrapper questionWithScores'>
                                                        <RadioGroup name="radio-buttons-group" row={true}>
                                                        {
                                                            questionOptions.map((option: any) => {
                                                                return( <>
                                                                    <div className='me-1 optionsBtn'>
                                                                        <Radio
                                                                            name={`${props.Question.Key}_${subQuestion.Key}`}
                                                                            className={`mx-1 rotateSpinner ${currentQuesAnswerMap?.OptionId == Number(option.id) ? "checked" : ""}`} 
                                                                            style={{ backgroundColor: `${option.color}`, borderColor: `${option.color}`}}
                                                                            value={option.id}
                                                                            checked={currentQuesAnswerMap?.OptionId == Number(option.id)}
                                                                            color="default"
                                                                            onChange={(e) => handleChange(subQuestion.Key, option.id)}
                                                                            />    
                                                                        <span className='optionText'><FormattedMessage id={`${testTypeStr}_SCORE_${option.id}`} /></span>
                                                                    </div>                                                                        
                                                                </>
                                                                )})
                                                        }
                                                        </RadioGroup>
                                                        <Button className='undo_btn btn-sm' variant='outline-dark' onClick={() => handleUndoClick(props.Question.Key, subQuestion.Key)} title={formatMessage('undo')}>
                                                            <i className='fa fa-undo'></i> 
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )})
                            }

                            {
                                props.Question.OptionsList &&
                                props.Question.OptionsList.length > 1 &&
                                props.Question.Key !== `SPORT` && 
                                // props.Question.Replies.map((reply: IReplyInfo) => (
                                  <>
                                    {/* <FormattedMessage id={`${testTypeStr}_${reply.Key}`} />  */}
                                    <div className={`${surveyQAMap && surveyQAMap.ReplyKey != '' ? "optionSelected" : ""}`}>
                                        <div className='text-center pt-3 pt-lg-0'>
                                            <div className='d-flex justify-content-start align-items-start radiobtnWrapper'>

                                                <RadioGroup name="radio-buttons-group" row={true}>
                                                {
                                                    props.Question.OptionsList.map((replyKey: string, index: number) => {
                                                    return(
                                                        <>
                                                            <div className='me-1 optionsBtn'>
                                                                <Radio
                                                                    name={`${props.Question.Key}_${replyKey}`}
                                                                    className={`mx-1 rotateSpinner ${surveyQAMap && surveyQAMap?.ReplyKey == replyKey ? "checked" : ""}`}
                                                                    style={{ backgroundColor: `rgba(67, 48, 215, ${(index+2)/10})`, borderColor: `rgba(67, 48, 215, ${(index+2)/10})`}}
                                                                    value={replyKey} 
                                                                    checked={surveyQAMap?.ReplyKey == replyKey}
                                                                    color="default"
                                                                    onChange={() => handleChange_Reply(props.Question.Key, replyKey)}
                                                                    />    
                                                                <span className='optionText'><FormattedMessage id={`${testTypeStr}_${replyKey}`} /></span>
                                                            </div>                                                                        
                                                        </>)
                                                    })
                                                }
                                                </RadioGroup>
                                                <Button className='undo_btn btn-sm' variant='outline-dark' onClick={() => handleUndoReply_Click(props.Question.Key)} title={formatMessage('undo')}>
                                                    <i className='fa fa-undo'></i> 
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                  </>  
                                // ))
                            }

                            {
                                props.Question.OptionsList &&
                                props.Question.OptionsList.length > 1 &&
                                props.Question.Key === `SPORT` &&
                                <>
                                    <div className='text-center py-3 pt-lg-0'>
                                        <div className='d-flex justify-content-start align-items-start radiobtnWrapper'>
                                            <select
                                                className="form-control"
                                                onChange={(e) => props.SetQuestionReply ? props.SetQuestionReply(props.Question.Key, e.target.value) : null}>
                                                <option key={-1} selected disabled>
                                                    <FormattedMessage id='please_select'/>
                                                </option>
                                                {props.Question.OptionsList.map((data: string, i: number) => (
                                                    <option key={i} value={data}>
                                                        <FormattedMessage id={`${testTypeStr}_${data}`}/>
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default TestCard;