import React, { useEffect, useState } from "react";
import IForgotPassword from "../../../../models/IForgotPassword";
import RoutesConstants from "../../../../shared-components/Routes/routes.constants";
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import ResetPasswordForm from "./ResetPasswordForm";
import IResetPassword from "../../../../models/Form/IResetPassword";
import { useStore } from "../../../../contexts/StoreProvider";
import { observer } from "mobx-react";
import { Button } from "react-bootstrap";
import TopNavBar from "../../../../shared-components/NavBar/TopNavBar";
import Footer from "../../../../shared-components/Footer/Footer";

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { authStore } = useStore();
    const {resetPassword, resetPasswordState, verifyResetPasswordToken, verifyResetPasswordTokenState, resetVerifyResetPasswordTokenState} = authStore;

    const initialValues: IResetPassword = {
        token: "",
        newPassword: "",
        confirmPassword: "",
    };

    const handleResetPassword = (values: IResetPassword) => {
        resetPassword(values);
    };

    useEffect(() => {
        if (resetPasswordState.success || resetPasswordState.error != "") {
            navigate(RoutesConstants.Login);
        }
    }, [resetPasswordState.success, resetPasswordState.error]);

    useEffect(() => {
        if (token && !verifyResetPasswordTokenState.success){
            verifyResetPasswordToken(token);
            // console.log(token);
        }
    }, [token]);

    return (
        <>
        <TopNavBar />
            <div className="banner--light breadcrumb-area">
                <div className="container">
                    <h1 className="page-title"> <FormattedMessage id="forgot_reset_password" /></h1>
                </div>
            </div>
            {
                verifyResetPasswordTokenState.success 
                ?
                <ResetPasswordForm
                    resetPasswordHandler={handleResetPassword}
                    initialValues={initialValues}
                    isLoading={resetPasswordState.inProgress}
                    resetPasswordToken={token}
                />
                :
                <>
                    {verifyResetPasswordTokenState.error && verifyResetPasswordTokenState.error != "" &&
                    <div className="login">
                        <div className="card-body p-2" style={{ backgroundColor: "red" }}>
                            <h4 className="text-white ">
                                <i className="fa fa-times-circle"></i>&nbsp;
                                {verifyResetPasswordTokenState.error === "TokenExpired" ? "Invalid or expired link." : verifyResetPasswordTokenState.error}
                            </h4>
                        </div>
                        <Button type="button" className="btn btn-primary" onClick={() => navigate(RoutesConstants.Login)}>
                            <FormattedMessage id='return_to_login'/>
                        </Button>
                    </div>
                    }
                </>

                
            }
            <Footer />
        </>
    );
};

export default observer(ResetPassword);
