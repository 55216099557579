/*    
<summary>
   This File contains all the routes constant. 
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
*/
import config from "../../helpers/config-helper";

const appConfig = config();
export const baseURL = appConfig.REACT_APP_PUBLIC_URL;
const RoutesConstants = {
  Home: baseURL + "/",
  KaleidoCompassIdea: baseURL + "/what-is-kaleidocompass",
  TestAim: baseURL + "/who-is-the-test-aimed-at",
  Instructions: baseURL + "/instructions",
  AboutCompany: baseURL + "/the-company",
  AboutCreators: baseURL + "/the-creators",
  AboutTrainers: baseURL + "/the-trainers",
  Events: baseURL + "/events",
  ContactUs: baseURL + "/contact-us",
  Prices: baseURL + "/pricing",
  Login: baseURL + "/login",
  Logout: baseURL + "/logout",
  User: baseURL + "/user",
  Registration: baseURL + "/registration",
  // Personal + Free
  Dashboard: baseURL + "/dashboard",

  // Admin
  AdminDashboard: baseURL + '/admin-dashboard',

  // Agent
  AgentDashboard: baseURL + "/agent-dashboard",
  EventSummary: baseURL + "/agent-dashboard/summary",

  Device: baseURL + "/device",

  //Trainer
  TrainerDashboard: baseURL + "/trainer-dashboard",
  ManageGroups: baseURL + "/trainer-dashboard/manage-groups",
  OpenTainer: baseURL + "/trainer-dashboard/open-trainer",
  SendEmail: baseURL + "/trainer-dashboard/send-email",
  TrainerRecharge: baseURL + "/trainer-dashboard/buy-invites",

  //Payment
  TestPayment: baseURL + "/payment/test",
  TrainerAccountPayment: baseURL + "/payment/trainer-dashboard-renewal",
  TrainerRechargePayment: baseURL + "/payment/trainer-recharge",

  // Test Pages
  TestBase: baseURL + "/test",
  Life: baseURL + "/test/life",
  Racing: baseURL + "/test/racing",
  Training: baseURL + "/test/training",
  Job: baseURL + "/test/job",
  TrainerContactUs: baseURL + "/contact",
  TestResult: baseURL + "/result",
  TestResultCompare: baseURL + "/result/compare",
  DemoTestResult: baseURL + "/demo-result",
  TestSubmission: baseURL + "/test-submission",
  DummyTestResult: baseURL + "/dummy",

  ForgotPassword: baseURL + "/forgot-password",
  ResetPassword: baseURL + "/reset-password",
  Stats: baseURL + '/stats'
};
export default RoutesConstants;
