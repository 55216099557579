/*    
<summary>
   This functional common component provides us custom DateTime form Field  and has error handler that shows 
   validation error.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { injectIntl } from "react-intl";
import FormikFormErrorHandler from "./FormikFormErrorHandler";
import { useField } from "formik";

interface IProps {
  children: React.ReactNode;
  className: string;
  id?: string;
  intl: any; //IntlConfig;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  placeholder: string;
  type: string;
  value: string;
  disabled: boolean;
  readOnly: boolean;
  spaceAllowed: boolean;
  row?: number;
}

const FormikFormTextArea = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const errorClass = `${meta.error && meta.touched ? "error" : ""}`;
  const { row } = props;
  const _placeholder = props.placeholder
    ? props.intl.formatMessage({ id: props.placeholder })
    : props.placeholder;

  return (
    <>
      <textarea
        {...field}
        {...props}
        data-testid={props.id ? props.id : undefined}
        placeholder={_placeholder}
        className={props.className + " " + errorClass}
        disabled={props.disabled}
        readOnly={props.readOnly}
        onChange={(event) => helpers.setValue(event.target.value)}
      />
      <FormikFormErrorHandler intl={props.intl} meta={meta} />
    </>
  );
};

export default injectIntl(FormikFormTextArea);
