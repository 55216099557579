import React from "react";
import { Field, Form, Formik } from "formik";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import { Button, Modal } from "react-bootstrap";
import { createTrainerAccountValidationSchema, profileValidateSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../../shared-components/Label/Label";
import FormikDateFieldMUI from "../../../../shared-components/FormikFormHandlers/FormikDateFieldMUI";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";
import FormikFormCheckBox from "../../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import { FormattedMessage } from "react-intl";
import { IAddUser } from "../../../../models/Form/IAddUser";
import FormikFormSelect from "../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { genderOptions, nationOptions } from "../../../registration/Registration";

interface IProps {
    modalClosed: () => void;
    isLoading: boolean;
    submitHandler: (data: IAddUser) => void;
    initialValues: IAddUser;
}

const AddUserFromAdminDashboardForm = (props: IProps) => {
    return (
        <>
        <Modal show={true} centered
            onHide={props.modalClosed}
            backdrop="static"
            scrollable={true}
            className="large-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="create_trainer_account" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={props.initialValues}
                    validationSchema={createTrainerAccountValidationSchema}
                    // enableReinitialize
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        props.submitHandler(values);
                    }}>
                    {({ values, errors, handleChange, handleBlur }) => (
                    <>
                    <Form className="mw-100" id="edit-profile-form">
                        <div className="row loginBox">
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <Label
                                    required={true}
                                    label="profile_label_email"
                                    htmlFor="Email"
                                />
                                <Field
                                    data-testid="Email"
                                    className="form-control"
                                    name="Email"
                                    type="text"
                                    invalidcharacter={[" "]}
                                    placeholder="profile_label_email"
                                    validcharacter={[
                                        "-",
                                        "_",
                                        "[",
                                        "]",
                                        "(",
                                        ")",
                                        ":",
                                        "#",
                                        "@",
                                        ".",
                                    ]}
                                    as={FormikFormInput}
                                />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <Label
                                    required={true}
                                    label="profile_label_name"
                                    htmlFor="FirstName"
                                />
                                <Field
                                    data-testid="FirstName"
                                    className="form-control"
                                    name="FirstName"
                                    type="text"
                                    invalidcharacter={[" "]}
                                    placeholder="profile_label_name"
                                    as={FormikFormInput}
                                />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <Label
                                    required={true}
                                    label="profile_label_familyname"
                                    htmlFor="LastName"
                                />
                                <Field
                                    data-testid="LastName"
                                    className="form-control"
                                    name="LastName"
                                    type="text"
                                    invalidcharacter={[" "]}
                                    placeholder="profile_label_familyname"
                                    as={FormikFormInput}
                                />
                            </div>

                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <Label
                                    required={true}
                                    label="DOB"
                                    htmlFor="DateOfBirth"
                                />
                                <Field
                                    data-testid="DateOfBirth"
                                    className="form-control"
                                    name="DateOfBirth"
                                    max={Date.now()}
                                    placeholder="DOB"
                                    as={FormikDateFieldMUI}
                                />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <Label
                                    required={true}
                                    label="gender"
                                    htmlFor="gender"
                                />
                                <Field
                                    data-testid="gender"
                                    className="form-control"
                                    name="Gender"
                                    placeholder="gender"
                                    as={FormikFormSelect}
                                    options={genderOptions}
                                />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <Label
                                    required={true}
                                    label="COR"
                                    htmlFor="COR"
                                />
                                <Field
                                    data-testid="COR"
                                    className="form-control"
                                    name="CountryOfResidence"
                                    placeholder="COR"
                                    as={FormikFormInput}
                                />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <Label
                                    required={true}
                                    label="nation"
                                    htmlFor="nation"
                                />
                                <Field
                                    data-testid="nation"
                                    className="form-control"
                                    name="Nation"
                                    placeholder="nation"
                                    as={FormikFormSelect}
                                    options={nationOptions}
                                />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <Label
                                    required={true}
                                    label="profile_label_subscription_expiry"
                                    htmlFor="SubscriptionExpiryDate"
                                />
                                <Field
                                    data-testid="SubscriptionExpiryDate"
                                    className="form-control"
                                    name="SubscriptionExpiryDate"
                                    min={Date.now()}
                                    placeholder="profile_label_subscription_expiry"
                                    as={FormikDateFieldMUI}
                                />
                            </div>
                            
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <Label
                                    required={true}
                                    label="profile_label_trainer_invitations_available"
                                    htmlFor="TrainerInvitesAvailable"
                                />
                                <Field
                                    data-testid="TrainerInvitesAvailable"
                                    className="form-control"
                                    name="TrainerInvitesAvailable"
                                    type="number"
                                    invalidcharacter={[" "]}
                                    placeholder="profile_label_trainer_invitations_available"
                                    as={FormikFormInput}
                                />
                            </div>

                            <div className="col-lg-6 col-12 form-group d-flex">
                                <Label
                                    required={true}
                                    label="profile_label_agent"
                                    htmlFor="IsAgent"
                                    className="me-3"
                                />
                                <span className="form-switch">
                                    <Field
                                        data-testid="testRememberMe"
                                        className="form-check-input"
                                        role="switch"
                                        name="IsAgent"
                                        type="checkbox"
                                        id="inlineFormCheck"
                                        as={FormikFormCheckBox}
                                    />
                                </span>
                            </div>
                        </div>
                    </Form>
                    </>
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <div>
                </div>
                <div className=" ">
                    <Button
                        data-testid="Cancel"
                        variant="secondary"
                        onClick={props.modalClosed}
                        disabled={props.isLoading}
                        className="btnOkSize me-2">
                        <FormattedMessage id="button_cancel" />
                    </Button>
                    <LoaderButton
                        type="submit"
                        variant="primary"
                        form="edit-profile-form"
                        id="RegisterButton"
                        className="btnOkSize"
                        isLoading={props.isLoading}
                        text="submit"
                    />
                </div>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default AddUserFromAdminDashboardForm;
