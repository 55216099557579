import React, { useState } from "react";

const CreateCouponHOC = (Component: any) =>
  function Comp(props: any) {
    const [showCreateCouponPopup, setShowCreateCouponPopup] = useState<boolean>(false);
    const createCouponToggleHandler = () => {
      setShowCreateCouponPopup(!showCreateCouponPopup);
    };

    return (
      <Component
        showCreateCouponPopup={showCreateCouponPopup}
        createCouponPopupToggleHandler={createCouponToggleHandler}
        {...props}
      />
    );
  };

export default CreateCouponHOC;
