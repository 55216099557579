import { IGetTestInterpretation } from "../../models/response/ITestInterpretation";

export const initialState: IGetTestInterpretation = {
    BarScatterGraph_Result: {
        CategoryList: [],
    },
    SurveyQuestion_Result: {
        SurveyQuestionList: [],
    },
    TwoDiGraph_Result: {
        TestResult_2DGraphVM: []
    },
    UserInfo_Result: {
        TestDataListVM: []
    },
    IsSogileBurnout: false
}