import React from 'react';
import './Footer.css';
import { FormattedMessage } from 'react-intl';

const Footer = () => {
    return (
    <footer className="footer section--blue-bg d-print-none">
        <div className="container">
            <div className="copyright"> © <span id="copy-year">2024</span> - KaleidoCompass International Ltd - <FormattedMessage id='contact_mail'/></div>
        </div>
    </footer>
    );
}

export default Footer;