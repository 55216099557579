import { Field, Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import LoaderButton from "../Button/LoaderButton";
import Label from "../Label/Label";
import FormikFormInput from "../FormikFormHandlers/FormikFormInput";
import { useStore } from "../../contexts/StoreProvider";
import { Button, Modal } from "react-bootstrap";
import './AddEditGroupForm.css';
import { Console } from "console";
import { observer } from "mobx-react";
import toast from "react-hot-toast";
import { TestType } from "../../constants/enum";
import { forIn } from 'lodash';
import { initialState as AddGroupInitialState } from "../../core/initial-state/add-group-state";
import IAddGroup from "../../models/Form/IAddUpdateGroup";

const Validate = Yup.object().shape({
    GroupName: Yup.string()
    .trim()
    .required("field_required")
    .max(50, 'Group name must be less than 50 characters'),
})


export enum FormTypeEnum{
    Create,
    Edit
}

interface IProps{
    FormType: FormTypeEnum,
    GroupId?: any 
    modalClosed: () => void;
    isLoading: boolean;
}

const AddEditGroupForm = (props: IProps) => {
    const { trainerStore } = useStore();
    const { addGroup,getAllGroups,addUpdateGroupState,getGroupDetail,updateGroup,trainerGroupPaginationState } = trainerStore

    useEffect(() => {
        if(addUpdateGroupState.success){
            getAllGroups();
        }
      }, [addUpdateGroupState.success]);

    return (
    <>
        <Modal show={true} centered
          onHide={props.modalClosed}
                backdrop="static"
                scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>
                {(props.FormType===FormTypeEnum.Create) ? <FormattedMessage id="create_new_Group" /> : <FormattedMessage id="edit_Group"/> } 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Formik
                initialValues={getGroupDetail}
                validationSchema={Validate}
                enableReinitialize
                validateOnBlur={false}
                onSubmit={async (values: IAddGroup) => {
                    if (props.FormType === FormTypeEnum.Create){
                        addGroup(values);
                    }
                    if(props.FormType === FormTypeEnum.Edit){
                        updateGroup(values)
                    }
                }}
                    >    
                    <Form id="addEditGroupForm" className="modal-form">
                        <div className="form-group ">
                                <Label
                                    required={true}
                                    className="fw-bold"
                                    label="Group_name"
                                    htmlFor="GroupName" />
                                <Field
                                    data-testid="GroupName"
                                    className="form-control"
                                    name="GroupName"
                                    type="text"
                                    placeholder="Enter the Group name"
                                    as={FormikFormInput} />
                        </div>
                    </Form>
            </Formik>
            </Modal.Body>
            <Modal.Footer>
                    <Button
                        data-testid="Cancel"
                        variant="secondary"
                        onClick={props.modalClosed}
                        disabled={props.isLoading}
                        className="btnOkSize">
                        <FormattedMessage id="button_cancel" />
                    </Button>
                    {(props.FormType == FormTypeEnum.Create)
                            ?
                            <LoaderButton
                                type="submit"
                                id="RegisterButton"
                                form="addEditGroupForm"
                                isLoading={props.isLoading}
                                // className="btnOkSize"
                                text="submit"
                            />
                            :
                            <LoaderButton
                                type="submit"
                                id="UpdateButton"
                                form="addEditGroupForm"
                                isLoading={props.isLoading}
                                // className="btnOkSize"
                                text="update"
                            />
                        }
                </Modal.Footer>
        </Modal>
        </>
    )
}

export default observer(AddEditGroupForm)
