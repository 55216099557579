import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import './ContactForm.css';
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { useStore } from '../../contexts/StoreProvider';
import toast from 'react-hot-toast';
import { formatMessage } from '../../translations/format-message';
import LoaderButton from '../../shared-components/Button/LoaderButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import FormikFormInput from '../../shared-components/FormikFormHandlers/FormikFormInput';
import {validationSchema} from '../../shared-components/FormikFormHandlers/FormikValidationSchema'
import FormikFormTextArea from '../../shared-components/FormikFormHandlers/FormikFormTextArea';
interface IProps {
    invertFormColors: boolean;
}

const ContactForm = (props: IProps) => {
    const { authStore } = useStore();
    const [isMailSent, setIsMailSent] = useState<boolean>(false);
    const{contactUsResponse, contactUsingMailState ,resetContactInfo} = authStore
    useEffect(() => {
        if (contactUsingMailState.error) {
          toast.error(formatMessage(contactUsingMailState.error));
          resetContactInfo();
        }
      }, [contactUsingMailState.error]);
    
      useEffect(() => {
        if (contactUsingMailState.success) {
          // toast.success(formatMessage("Message Sent"));
          setIsMailSent(true);
          resetContactInfo();
        }
      }, [contactUsingMailState.success]);
    
    const intl = useIntl();
    return (
        <>
          {isMailSent 
          ?
            <h2 className="text-bg-success p-3">Thank you for contacting us. <FontAwesomeIcon icon={faCheckCircle} /></h2>
          :
            <Formik
            initialValues={{
                name: '',
                email: '',
                subject: '',
                message: '',
              }}
            validationSchema={validationSchema}
            validateOnBlur={false}
            onSubmit={async (values) => {
                contactUsResponse(values);
              }}>
                 <Form className={props.invertFormColors ? 'invert-colors text-center' : ''}>
                  <div className='row'>
                    <div className='col-12 form-group'>
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="name"
                        as={FormikFormInput}
                      />
                    </div>
                    <div className='col-12 form-group'>
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder= "mail"
                        validcharacter={[
                          "-",
                          "_",
                          "[",
                          "]",
                          "(",
                          ")",
                          ":",
                          "#",
                          "@",
                          ".",
                        ]}
                        invalidcharacter={[" "]}
                        as={FormikFormInput}
                      />
                    </div>
                    <div className='col-12 form-group'>
                      <Field
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder= "subject"
                        as={FormikFormInput}
                      />
                    </div>
                    <div className='col-12 form-group'>
                      <Field
                        // as='textarea'
                        name="message"
                        className="form-control"
                        rows="6"
                        placeholder="Message"
                        as={FormikFormTextArea}
q                      />
                    </div>
                    <div className='col-12 form-group'>
                      <LoaderButton
                          type="submit"
                          id="RegisterButton"
                          className="btn btn-primary"
                          isLoading={contactUsingMailState.inProgress}
                          text="send"
                      />
                    </div>
                  </div>
                </Form>
            </Formik>
          }
        </>
    )
}
export default observer(ContactForm);