import React, { useState } from "react";

const RepeatForwardMailInfoPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showSendMailPopup, setshowSendMailPopup] = useState<boolean>(false);

    const sendMailToggleHandler = () => {
      setshowSendMailPopup(!showSendMailPopup);
    };

    return (
      <Component
        showSendMailPopup={showSendMailPopup}
        sendMailToggleHandler={sendMailToggleHandler}
        {...props}
      />
    );
  };

export default RepeatForwardMailInfoPopupHOC;
