/*    
<summary>
  The value of NODE_ENV is set automatically to development (when using npm start), test (when using npm test) or 
  production (when using npm build). Thus, from the point of view of create-react-app, there are only three environments(development, test, and production).
  This function combines all the project config settings from "windows.config" and "NODE_ENV"
  (windows.config contain dynamic API url that is configurable by user)
  Developer: Aashish Singh, Created Date:08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>configuration Object</returns>
*/

const config = () => {
  const config: any = (window as any).Config;
  if (config === undefined) {
    return {
      ...process.env
    };
  }
  return {
    ...config
  };
};
  
export default config;
  