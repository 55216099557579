import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import { IFigures } from "../../../models/response/IFigures";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { UpdateFiguredValidationSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/Label/Label";



const FigureSettings = (props: any) => {
    const { adminStore } = useStore();
    const { getFigures, getFiguresCount, updateFigures, updateFiguresState, getFiguresCountState } = adminStore;
    
    useEffect(() => {
        getFiguresCount();
    }, []);

    useEffect(() => {
        if (updateFiguresState.success) {
            getFiguresCount();
        }
    }, [updateFiguresState.success]);
    
    return (
        <>
            {getFiguresCountState.success &&
                <div>
                    <Formik
                        initialValues={getFigures}
                        validationSchema={UpdateFiguredValidationSchema}
                        onSubmit={async (values: IFigures) => {
                            updateFigures(values)
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form className='mw-100'>
                                <div className='p-3'>
                                    {/* <h3 className="section-small-title mb-0 fw-bold"><FormattedMessage id="Figures" /></h3> */}
                                    <div className='row align-items-center mb-2'>
                                        <div className='col-xl-4 col-12 col-md-6'>
                                            <Label
                                                required={true}
                                                className="fw-bold"
                                                label="TestTakenCount"
                                                htmlFor={`TestTakenCount`}
                                            />
                                        </div>
                                        <div className='col-xl-4 col-12 col-md-6'>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                name={`TestTakenCount`}
                                                data-testid={`TestTakenCount`}
                                                as={FormikFormInput}
                                            />
                                        </div>
                                    </div>

                                    <div className='row align-items-center mb-2'>
                                        <div className='col-xl-4 col-12 col-md-6'>
                                            <Label
                                                required={true}
                                                className="fw-bold"
                                                label="Kaleidocompass_Trainer"
                                                htmlFor={`TrainersCount`}
                                            />
                                        </div>
                                        <div className='col-xl-4 col-12 col-md-6'>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                name={`TrainersCount`}
                                                data-testid={`TrainersCount`}
                                                as={FormikFormInput}
                                            />
                                        </div>
                                    </div>

                                    <div className='row align-items-center mb-2'>
                                        <div className='col-xl-4 col-12 col-md-6'>
                                            <Label
                                                required={true}
                                                className="fw-bold"
                                                label="country_world"
                                                htmlFor={`CountriesCount`}
                                            />
                                        </div>
                                        <div className='col-xl-4 col-12 col-md-6'>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                name={`CountriesCount`}
                                                data-testid={`CountriesCount`}
                                                as={FormikFormInput}
                                            />
                                        </div>
                                    </div>
                                    <div className='row align-items-center mb-2'>
                                        <div className='col-xl-4 col-12 col-md-6'>
                                            <Label
                                                required={true}
                                                className="fw-bold"
                                                label="Companies"
                                                htmlFor={`CompaniesCount`}
                                            />
                                        </div>
                                        <div className='col-xl-4 col-12 col-md-6'>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                name={`CompaniesCount`}
                                                data-testid={`CompaniesCount`}
                                                as={FormikFormInput}
                                            />
                                        </div>
                                    </div>

                                    <div className='row align-items-center mb-2'>
                                        <div className='col-xl-4 col-12 col-md-6'>
                                            <Label
                                                required={true}
                                                className="fw-bold"
                                                label="Coach_counsellor"
                                                htmlFor={`CoachesCount`}
                                            />
                                        </div>
                                        <div className='col-xl-4 col-12 col-md-6'>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                name={`CoachesCount`}
                                                data-testid={`CoachesCount`}
                                                as={FormikFormInput}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <LoaderButton type='submit' className='btn btn-primary' text="button_save" />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            }

        </>
    );
}
export default observer(FigureSettings);