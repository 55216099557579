import { IGetAllTrainerGroup } from "../../models/response/ITrainerGroupResponse";

export const initialState: IGetAllTrainerGroup = {
    PagingDetails:{
        TotalRecords: 1,
        TotalPages: 1,
        PageNo: 1,
        PageSize: 10,
    },
    TrainerGroupList: [],
};