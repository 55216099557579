import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate, redirect, useLocation, useNavigate } from 'react-router-dom'
import RoutesConstants from '../../shared-components/Routes/routes.constants'
import './TestSubmission.css'

const TestSubmission = (props: any)=>{
    const { state } = useLocation();
    const navigate = useNavigate();
     useEffect(() => {
        if(state === null){
            navigate(RoutesConstants.Login)
        }
     },[])
    return(
        <>
       {state !==null && 
          <div className="banner--light breadcrumb-area">
                <div className="container">
                <FontAwesomeIcon icon={faCircleCheck} name="linkedIn " className='display-1' style={{color:"var(--green)"}}/>
                
                <h1 className='mt-4'><FormattedMessage id='Test_Submitted_message' /></h1>
                <h3 className='my-2 py-2'> <FormattedMessage id ='Create_Account_LoginMessage' values ={{email:state?.email}}></FormattedMessage></h3>
                <div className='btn-group'>
                            <button className='btn btn-primary p-3' onClick={() => window.location.href = `${RoutesConstants.Login}?continue=${encodeURIComponent(window.location.href)}`}>
                                <i className="fa fa-user-check text-white me-3" ></i>
                                <FormattedMessage id='login_message' />
                            </button>
                            <button className='btn btn-secondary p-3' onClick={() => window.location.href = `${RoutesConstants.Registration}?continue=${encodeURIComponent(window.location.href)}`}>
                                <i className="fa fa-user-plus text-white me-3" ></i>
                                <FormattedMessage id='register_message' />
                            </button>
                        </div>
                </div>
           </div>
        }
        </>
    )
}
export default TestSubmission