/*    
<summary>
   This file is used to provide initial values or state to the Edit Profile Form.
</summary>
*/

import { IUpdateUser } from "../../models/Form/IUpdateUser";


export const initialState: IUpdateUser = {
    FirstName: '',
    LastName: '',
    DateOfBirth: '',
    Gender: '',
    Nation: '',
    CountryOfResidence: '',
}
