import React, { useEffect } from "react";
import { useStore } from "../../../../contexts/StoreProvider";
import { formatMessage } from "../../../../translations/format-message";
import toast from "react-hot-toast";
import { observer } from "mobx-react";
import AddEditEventForm from "../../../../shared-components/AddEditEventForm/AddEditEventForm";
import { FormTypeEnum } from "../../../../constants/enum";

interface IProps {
  modalClosed: () => void;
  isLoading: boolean;
}

const CreateEventPopup = (props: IProps) => {
  const { agentStore } = useStore();
  const { addUpdateEventState, resetAddUpdateEvent} = agentStore;

  useEffect(() => {
    if (addUpdateEventState.success) {
      resetAddUpdateEvent();
      props.modalClosed();
    }
  }, [addUpdateEventState.success]);

  return (
    <AddEditEventForm FormType={FormTypeEnum.Create} modalClosed={props.modalClosed} isLoading={props.isLoading} />
  );
};

export default observer(CreateEventPopup);
