import { IGetAllPayments } from "../../models/response/IAdminResponse";

export const initialState: IGetAllPayments = {
  PagingDetails:{
    TotalRecords: 1,
    TotalPages: 1,
    PageNo: 1,
    PageSize: 10,
},
  PaymentList: [],
};