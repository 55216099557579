/*    
<summary>
  This functional component "LogoutPopupHOC" provides props to the child component.
  1. State "showLogoutPopup" that manages the show/hide of pop up
  2. Function "logoutPopupToggleHandler" to toggle the show/hide popup state "showLogoutPopup"

</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const DeleteGroupPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

    const deleteToggleHandler = () => {
        setShowDeletePopup(!showDeletePopup);
    };

    return (
      <Component
        showDeletePopup={showDeletePopup}
        deletePopupToggleHandler={deleteToggleHandler}
        {...props}
      />
    );
  };

export default DeleteGroupPopupHOC;
