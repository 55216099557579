/*    
<summary>
   This functional component is providing a fallback react tree to show when a Suspense child (like React.lazy) suspends
   by using Suspense.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { Suspense } from 'react';
import RoutesComponent from '../Routes/routes';

const Body: React.FC = () => {
    return (
        <React.Fragment>
            <Suspense fallback={<div>Loading...</div>}>
                <RoutesComponent></RoutesComponent>
            </Suspense>
        </React.Fragment>
    );
}

export default Body;