import React, { useState } from 'react'

const VerificationPopupHOC = (Component: any) =>
    function Comp(props: any) {
        const [showVerificationPopup, setShowVerificationPopup] = useState<boolean>(false);

        const verificationPopupToggleHandler = () => {
            setShowVerificationPopup(!showVerificationPopup);
        };
        return (
            <Component
                showVerificationPopup={showVerificationPopup}
                verificationPopupToggleHandler={verificationPopupToggleHandler}
                {...props}
            />
        )
    }

export default VerificationPopupHOC;
