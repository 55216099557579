import React, { useEffect, useState } from 'react'
import { useStore } from '../../../contexts/StoreProvider';
import { observer } from 'mobx-react';
import InviteFriendForm from './InviteFriendForm';
import IAddSendFriendRequest from '../../../models/Form/IAddSendFriendRequest';
import { postSentFriendRequestState } from '../../../core/initial-state/add-sendFriendRequest-state';
import { SendFriendRequestEnum } from '../../../constants/enum';


interface IProps {
    modalClosed: () => void;
}


const InviteFriend = (props: IProps) => {
    const { authStore } = useStore();
    const { postSendFriendRequest, addSendFriendRequestState, getAllFriendRequests, getSendFriendRequestsReceived, getSendFriendRequestsSend } = authStore

    useEffect(() => {
        if (addSendFriendRequestState.success) {
            getAllFriendRequests()
            getSendFriendRequestsReceived(SendFriendRequestEnum.Received)
            getSendFriendRequestsSend(SendFriendRequestEnum.Send)
            addSendFriendRequestState.success = !addSendFriendRequestState.success
            props.modalClosed();

        }
    }, [addSendFriendRequestState.success]);

    const onSubmitForm = (values: IAddSendFriendRequest) => {
        postSendFriendRequest(values)
    };

    return (
        <>
            <InviteFriendForm
                modalClosed={props.modalClosed}
                initialValues={postSentFriendRequestState}
                submitHandler={onSubmitForm}
                isLoading={addSendFriendRequestState.inProgress}
            />
        </>
    )
}
export default observer(InviteFriend);