import React, { useState } from 'react'

const PaymentModalHOC = (Component: any) =>
    function Comp(props: any) {
        const [showPaymentModalPopup, setShowPaymentModalPopup] = useState<boolean>(false);
        const paymentModalPopupToggleHandler = () => {
            setShowPaymentModalPopup(!showPaymentModalPopup);
        };

        return (
            <Component
                showPaymentModalPopup={showPaymentModalPopup}
                paymentModalPopupToggleHandler={paymentModalPopupToggleHandler}
                {...props}
            />)
    }

export default PaymentModalHOC;
