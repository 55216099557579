import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import LoaderButton from '../../../shared-components/Button/LoaderButton';
import { Field, Form, Formik } from 'formik';
import OtpInput from 'react-otp-input';
import { observer } from 'mobx-react';
import { InitialStateType } from '../../../core/stores/auth-store';
import { OTPResendTimeInSeconds } from '../../../constants/options.constant';

export enum OTPModalType {
    Login,
    Register
}

interface IProps {
    modalClosed: () => void;
    otpVerificationHandler: (otp: string) => void;
    otpGenerationHandler: () => void;
    userEmail: string;
    isAddLoginUserLoading: boolean;
    isOtpGenerationLoading: boolean;
    otpVerificationState: InitialStateType;
    otpModalType: OTPModalType;
}

const VerificationModal = (props: IProps) => {
    const [myOtp, setMyOtp] = useState('');
    const [timer, setTimer] = useState<number>(OTPResendTimeInSeconds);
    const [canResend, setCanResend] = useState<boolean>(false);

    let interval: NodeJS.Timeout;
    useEffect(() => {
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else {
            setCanResend(true);
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        if (props.isOtpGenerationLoading) {
            setTimer(OTPResendTimeInSeconds);
            setCanResend(false);
        }
    }, [props.isOtpGenerationLoading]);

    return (
        <>
            <Modal show={true} centered
                onHide={props.modalClosed}
                scrollable={true}
                backdrop="static"
                dialogClassName="otp-popup">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="otp_verification" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <Formik
                            initialValues={{ otp: ""}}
                            validateOnBlur={false}
                            onSubmit={async () => {
                                if (myOtp.length === 6){
                                    props.otpVerificationHandler(myOtp);
                                }
                            }}
                            disabled={props.otpVerificationState.success || props.isAddLoginUserLoading}
                        >
                            <Form className="mw-100" id="verification-otp-form">
                            <div className="loginBox">
                                {
                                    //props.isOtpVerificationSuccess 
                                    // ?
                                    // <div className="card-body p-2 rounded mb-4" style={{ backgroundColor: "green" }}>
                                    //     <h4 className="text-white ">
                                    //         <i className="fa fa-check-circle"></i> &nbsp;Email verified successfully.
                                    //     </h4>
                                    // </div>
                                    // :
                                    !props.otpVerificationState.success && props.otpVerificationState.error && props.otpVerificationState.error !== "" &&
                                    <div className="card-body p-2 rounded mb-4" style={{ backgroundColor: "red" }}>
                                        <h4 className="text-white ">
                                            <i className="fa fa-times-circle"></i> &nbsp;{props.otpVerificationState.error}.
                                        </h4>
                                    </div>
                                }

                                {/* {!props.isOtpVerificationSuccess && */}
                                <>
                                    <p className="text-dark text-center mb-2">
                                        <FormattedMessage id="otp_message" /><br/>
                                        <b>{props.userEmail}</b>
                                    </p>
                                    <div>
                                        <OtpInput
                                            value={myOtp}
                                            onChange={setMyOtp}
                                            numInputs={6}
                                            inputType="number"
                                            containerStyle="otpStyle"
                                            inputStyle="otpInputStyle"
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </div>

                                    <p className="text-end mt-2">
                                        {
                                            !canResend ?
                                                <>
                                                    <span className="btn mr-0 px-0 text-decoration-none" style={{fontSize: "14px", pointerEvents: 'none'}}>
                                                        <FormattedMessage id="resend_otp_available"/>{timer} <FormattedMessage id="seconds"/>
                                                    </span>
                                                </>
                                                :
                                                <LoaderButton 
                                                    disabled={props.otpVerificationState.success}
                                                    type="button"
                                                    text="resend_otp"
                                                    variant="link mr-0 px-0" onClick={() => {setMyOtp(''); props.otpGenerationHandler()}} isLoading={props.isOtpGenerationLoading}>
                                                </LoaderButton>
                                        }
                                    </p>
                                </>
                            {/* } */}
                            </div>
                            </Form>
                        </Formik>
                    </>
                </Modal.Body>
                {/* {!props.isOtpVerificationSuccess && */}
                    <Modal.Footer className="d-flex justify-content-between">
                        <div></div>
                        <div className=" ">
                            <Button
                                disabled={props.otpVerificationState.success || props.isAddLoginUserLoading}
                                data-testid="Cancel"
                                variant="secondary"
                                onClick={props.modalClosed}
                                className="btnOkSize me-2">
                                <FormattedMessage id="button_cancel" />
                            </Button>
                            <LoaderButton
                                disabled={myOtp.length === 6 ? false : true}
                                type="submit"
                                variant="primary"
                                form="verification-otp-form"
                                id="RegisterButton"
                                className="btnOkSize"
                                isLoading={!props.otpVerificationState.success ? props.otpVerificationState.inProgress : props.isAddLoginUserLoading}
                                text={props.otpModalType === OTPModalType.Register ? "Register" : "Login"}
                                // text={props.otpVerificationState.success && props.isAddLoginUserLoading 
                                //     ? 
                                //     props.otpModalType === OTPModalType.Register ? "Registering" : "Logging in" 
                                //     : 
                                //     "submit" }
                            />
                        </div>
                    </Modal.Footer>
                {/* } */}
            </Modal>
        </>
    )
}

export default observer(VerificationModal);