export enum TestType{
    LIFE = 1,
    TRAINING = 2,
    RACING = 3,
    JOB = 4
}

export enum UserType{
    Admin = "Admin",
    Personal = "Personal",
    Trainer = "Trainer"
}

 export enum UserTypeNumber{
    Admin = 1,
    Personal = 2,
    Trainer = 3
}

export enum SendFriendRequestEnum {
    Send = "Send",
    Received = "Received",
}

export enum FormTypeEnum{
    Create,
    Edit
}

export enum ProductType {
    Test = 1,
    TrainerPlanRenew = 2,
    TrainerRecharge10 = 3,
    TrainerRecharge25 = 4,
    TrainerRecharge50 = 5,
    TrainerRecharge100 = 6,
    TrainerRecharge150= 7,
    TrainerRecharge200 = 8,
    TrainerRecharge300 = 9,
    TrainerRecharge500 = 10,
    TrainerRecharge800 = 11,
    TrainerRecharge1000 = 12,
}