/*    
<summary>
   This functional common component provides us custom Date form Field and has error handler that shows 
   validation error. 
</summary>
<returns>Returns JSX</returns>
*/
import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Stack from '@mui/material/Stack';
import { useField } from 'formik';
import { formatMessage } from '../../translations/format-message';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useState } from 'react';
import { BtnUndo, BtnRedo, Separator, BtnBulletList, BtnNumberedList, BtnLink, BtnUnderline, BtnBold, BtnItalic, Editor, EditorProvider, Toolbar, BtnStyles, BtnStrikeThrough, BtnClearFormatting } from 'react-simple-wysiwyg';

export default function FormikEditorInput(props:any) {
  const { disabled, min, max , label} = props;
  const [ field, meta, helpers ] = useField(props);
  const { touched, error } = meta;
  const [dataError, setDataError] = useState("");
  const [value, setValue] = useState<string>('simple text');

  function onChange(e: any) {
    setValue(e.target.value);
  }


  return (
    <>
     <EditorProvider>
        <Editor
            name={props.name}
            value={field.value ? field.value : null}
            onChange={(event:any) => {
                helpers.setValue(event.target.value);
            }}
            onError={(err: any) => {setDataError(err);}}
            disabled={props.disabled}
            {...props}
            >
            <Toolbar>
                <BtnUndo />
                <BtnRedo />
                <Separator />
                <BtnBold />
                <BtnItalic />
                <BtnUnderline />
                <BtnStrikeThrough />
                <Separator />
                <BtnNumberedList />
                <BtnBulletList />
                <Separator />
                <BtnLink />
                <BtnClearFormatting />
                <BtnStyles />
            </Toolbar>
        </Editor>
    { 
        (error && <span data-testid="testError" className=" text-danger errorMessage">{formatMessage(error)}</span>)
        // ||
        // (dateError && <span data-testid="testError" className=" text-danger errorMessage">{formatMessage(dateError)}</span>)
    }
    {/* { 
        (error && (
        error === "end_date_must_be_greater" ?
        <span data-testid="testError" className="errMessageRange text-danger errorMessage">{formatMessage(error)}</span> : 
        <span data-testid="testError" className="errMessageRange text-danger errorMessage">{formatMessage("invalid_datetime_format")}</span>))
    } */}
    </EditorProvider>
    </>
  );
}