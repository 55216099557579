import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { observer } from "mobx-react";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import Label from "../../../../shared-components/Label/Label";
import { CouponValidateSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { ICoupon } from "../../../../models/ICoupon";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";
import { useStore } from "../../../../contexts/StoreProvider";
import FormikDateFieldMUI from "../../../../shared-components/FormikFormHandlers/FormikDateFieldMUI";

interface IProps {
    modalClosed: () => void;
    FormType: FormTypeEnum,
    isLoading: boolean;
}

export enum FormTypeEnum {
    Create,
    Edit
}

const options: Array<string> = ["ON", "OFF"];

const AddEditCouponForm = (props: IProps) => {
    const { adminStore } = useStore();
    const { createCoupon, updateCoupon, addUpdateCouponState, getCouponDetail } = adminStore;
    const minValue = 0;
    const maxValue = 100;
    const maxCouponCountValue = 10000;
    useEffect(() => {
        if (addUpdateCouponState.success) {
            props.modalClosed();
        }
    }, [addUpdateCouponState.success]);

    return (
        <>
            <Modal show={true} centered
                onHide={props.modalClosed}
                backdrop="static"
                scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {(props.FormType === FormTypeEnum.Create) ? <FormattedMessage id="coupon_create_new_event" /> : <FormattedMessage id="coupon_edit_event" />}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={getCouponDetail}
                        validationSchema={CouponValidateSchema}
                        enableReinitialize
                        validateOnBlur={false}
                        onSubmit={async (values: ICoupon) => {
                            if (props.FormType === FormTypeEnum.Create) {
                                createCoupon(values);
                                // props.modalClosed()
                            }
                            else if (props.FormType === FormTypeEnum.Edit) {
                                updateCoupon(values);
                                // props.modalClosed()
                            }
                        }}
                    >
                        {({ values, errors, handleChange, handleBlur }) => (
                            <>
                            <Form id="addEditCouponForm" className="modal-form">
                                <div className="">
                                    <div className="form-group ">
                                        <Label
                                            required={true}
                                            className="fw-bold"
                                            label="coupon_name"
                                            htmlFor="couponName" />
                                        <Field
                                            data-testid="couponName"
                                            className="form-control"
                                            name="couponName"
                                            type="text"
                                            placeholder="coupon_name"
                                            as={FormikFormInput} />
                                    </div>
                                    <div className="form-group ">
                                        <Label
                                            required={true}
                                            className="fw-bold"
                                            label="coupon_code"
                                            htmlFor="couponCode" />
                                        <Field
                                            data-testid="couponCode"
                                            className="form-control"
                                            name="couponCode"
                                            type="text"
                                            invalidcharacter={[" "]}
                                            placeholder="coupon_code"
                                            as={FormikFormInput} />
                                    </div>
                                    <div className="form-group ">
                                        <Label
                                            required={true}
                                            className="fw-bold"
                                            label="coupon_discount"
                                            htmlFor="discount" />
                                        <Field
                                            data-testid="discount"
                                            className="form-control"
                                            name="discount"
                                            type="number"
                                            max={maxValue}
                                            min={minValue}
                                            invalidcharacter={[" "]}
                                            placeholder="coupon_discount"
                                            as={FormikFormInput} />
                                    </div>
                                    <div className="form-group ">
                                    <Label
                                       required={true}
                                       className="fw-bold"
                                       label="coupon_count"
                                       htmlFor="CouponCount"
                                    />
                                    <Field
                                      data-testid="CouponCount"
                                      className="form-control"
                                      name="CouponCount"
                                      type="number"
                                      min={minValue}
                                      max={maxCouponCountValue}
                                      placeholder="coupon_count"
                                      as={FormikFormInput}
                                   />
                                    </div>
                                    <div className="form-group ">
                                    <Label
                                      required={true}
                                      className="fw-bold"
                                      label="Valid_UpTo"
                                      htmlFor="ValidUpto"
                                    />
                                    <Field
                                      data-testid="ValidUpto"
                                      className="form-control"
                                      name="ValidUpto"
                                      min={Date.now()}
                                      placeholder="Valid_UpTo"
                                      as={FormikDateFieldMUI}
                            />
                                    </div>
                                </div>
                                <div className="btn-group" role="group" aria-label="Default button group">
                                    <div className='d-flex  text-center pb-2 '>
                                        {options.map((option: string) => (
                                            <>
                                                <input
                                                    key={option}
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="status"
                                                    value={option}
                                                    id={`test-${option}`}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.status === option} />
                                                <label className="form-check-label me-5 " htmlFor={`test-${option}`}>
                                                    {option}
                                                </label>
                                            </>
                                        ))}
                                    </div>
                                </div>
                                {errors.status && (<>
                                    <span className="text-danger errorMessage mb-3">{errors.status}</span>
                                </>
                                )}
                            </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end ">
                        <Button
                            data-testid="Cancel"
                            variant="secondary"
                            onClick={props.modalClosed}
                            disabled={props.isLoading}
                            className="btnOkSize me-2">
                            <FormattedMessage id="button_cancel" />
                        </Button>

                        {(props.FormType == FormTypeEnum.Create)
                            ?
                            <LoaderButton
                                type="submit"
                                id="RegisterButton"
                                form="addEditCouponForm"
                                isLoading={props.isLoading}
                                // className="btnOkSize"
                                text="submit"
                            />
                            :
                            <LoaderButton
                                type="submit"
                                id="UpdateButton"
                                form="addEditCouponForm"
                                isLoading={props.isLoading}
                                // className="btnOkSize"
                                text="update"
                            />
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default observer(AddEditCouponForm)
