import React, { useEffect, useState } from "react";
import { Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import Label from "../../../shared-components/Label/Label";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikDateFieldMUI from "../../../shared-components/FormikFormHandlers/FormikDateFieldMUI";
import { genderOptions, nationOptions } from "../../registration/Registration";
import { useStore } from "../../../contexts/StoreProvider";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import { observer } from "mobx-react";
import { formatMessage } from "../../../translations/format-message";
import toast from "react-hot-toast";
import { initialState as UpdateUserInitialState} from "../../../core/initial-state/update-user-state"
import { UpdateUserValidationSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";

const Profile = (props: any) => {
    const { preferencesStore, authStore } = useStore();
    const { userDetail, addUpdateUserState, updateUser, resetAddUpdateUser, getUserDetail } = authStore;
    const [ dataFetched, setDataFetched] = useState<boolean>(false);

    useEffect(() => {
        if (addUpdateUserState.success) {
        //   toast.success(formatMessage("updated_success"));
            getUserDetail();
            resetAddUpdateUser();
        }
      }, [addUpdateUserState.success]);

        
    useEffect(() => {
        getUserDetail().then(() => {
            setDataFetched(true);
        });
    }, []);
    
    return (
        <>
            <div className="container">
            {
                dataFetched &&
                <Formik
                    initialValues={userDetail}
                    validationSchema={UpdateUserValidationSchema}
                    // enableReinitialize
                    validateOnBlur={true}
                    onSubmit={async (values) => {
                        updateUser(values);
                    }}>
                    {({ values, errors, handleChange, handleBlur }) => (
                    <>
                    <Form className="mw-100" id="edit-profile-form">
                        <div className="loginBox">
                            <div className='row mb-3'>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <div className="form-group ">
                                        <Label
                                            required={true}
                                            label="profile_label_name"
                                            htmlFor="FirstName"
                                        />
                                        <Field
                                            data-testid="FirstName"
                                            className="form-control"
                                            name="FirstName"
                                            type="text"
                                            invalidcharacter={[" "]}
                                            placeholder="profile_label_name"
                                            as={FormikFormInput}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <div className="form-group">
                                        <Label
                                            required={true}
                                            label="profile_label_familyname"
                                            htmlFor="LastName"
                                        />
                                        <Field
                                            data-testid="LastName"
                                            className="form-control"
                                            name="LastName"
                                            type="text"
                                            invalidcharacter={[" "]}
                                            placeholder="profile_label_familyname"
                                            as={FormikFormInput}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <div className="form-group">
                                        <Label
                                            required={true}
                                            label="DOB"
                                            htmlFor="DateOfBirth"
                                        />
                                        <Field
                                            data-testid="DateOfBirth"
                                            className="form-control"
                                            name="DateOfBirth"
                                            max={Date.now()}
                                            placeholder="DOB"
                                            as={FormikDateFieldMUI}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <div className="form-group">
                                        <Label
                                            required={true}
                                            label="gender"
                                            htmlFor="gender"
                                        />
                                        <Field
                                            data-testid="gender"
                                            className="form-control"
                                            name="Gender"
                                            placeholder="gender"
                                            as={FormikFormSelect}
                                            options={genderOptions}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <div className="form-group">
                                        <Label
                                            required={true}
                                            label="COR"
                                            htmlFor="COR"
                                        />
                                        <Field
                                            data-testid="COR"
                                            className="form-control"
                                            name="CountryOfResidence"
                                            placeholder="COR"
                                            as={FormikFormInput}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <div className="form-group">
                                        <Label
                                            required={true}
                                            label="nation"
                                            htmlFor="nation"
                                        />
                                        <Field
                                            data-testid="nation"
                                            className="form-control"
                                            name="Nation"
                                            placeholder="nation"
                                            as={FormikFormSelect}
                                            options={nationOptions}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <LoaderButton type="submit"
                                    isLoading={addUpdateUserState.inProgress}
                                    className="btn btn-primary"
                                    text="update"/>
                        </div>
                    </Form>
                    </>
                    )}
                </Formik>
            }
            </div>
        </>
    )
}

export default observer(Profile);
