/*    
<summary>
   This rootStore combines all Store into a single one.
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns RootStore Type</returns>
<returns>rootStore</returns>
*/

import authStore, { AuthStore } from './auth-store';
import preferencesStore, { PreferencesStore } from './preferences-store';
import testStore, { TestStore } from './test-store';
import agentStore, { AgentStore } from './agent-store';
import adminStore, { AdminStore } from './admin-store';
import trainerStore, { TainerStore } from './trainer-store';

export type RootStore = {
    authStore: AuthStore;
    preferencesStore: PreferencesStore;
    testStore: TestStore;
    agentStore:AgentStore;
    adminStore:AdminStore;
    trainerStore:TainerStore
}

const rootStore: RootStore = {
    authStore,
    preferencesStore,
    testStore,
    agentStore,
    adminStore,
    trainerStore
};

export default rootStore;