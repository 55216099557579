import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import InputTag from '../../../modules/admin-dashboard/InputTag/InputTag'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal } from "react-bootstrap";
import './manage-groups.css'
import { formatMessage } from '../../../translations/format-message'
import { useStore } from '../../../contexts/StoreProvider';
import { observer } from 'mobx-react';
import FormLoader from '../../../shared-components/FormLoader/FormLoader';
import CreateGroupPopup from '../components/CreateGroup/CreateGroupPopup';
import CreateGroupPopupHOC from '../hoc/CreateGroupPopupHOC';
import EditGroupPopupHOC from '../hoc/EditGroupPopupHOC';
import DeleteGroupPopupHOC from '../hoc/DeleteGroupPopupHOC';
import EditGroupPopup from '../components/EditGroup/EditGroupPopup';
import { ITrainerGroup } from '../../../models/response/ITrainerGroupResponse';
import DeletePopup from '../../agent-dashboard/components/Delete/DeletePopup';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import RoutesConstants from '../../../shared-components/Routes/routes.constants';
import CustomPagination from '../../../shared-components/Pagination/CustomPagination';
const ManageGroups = (props: any) => {
    const { trainerStore } = useStore();
    const navigate = useNavigate();
    const { getAllGroups, allTrainerGroups, getTrainerGroupListState, setSelectedGroupUsingId, resetGroupPagingState,
        resetAddUpdateGroup, updateGroupPageSize, updateGroupPageNumber, addUpdateGroupState, resetDeleteGroup,
        deleteGroup, deleteGroupState, trainerGroupPaginationState } = trainerStore;
    const [editGroupId, setEditGroupId] = useState();
    const [deleteGroupId, setDeleteGroupId] = useState<number>(-1);
    const [deleteGroupName, setDeleteGroupName] = useState<string>("");
    const handleCreateGroupModalOpen = () => {
        props.createGroupPopupToggleHandler();
    }
    const onPageLimitChanged = (evt: any) => {
        updateGroupPageSize(parseInt(evt.target.value));
    };
    const onPageChanged = (pageNumber: number) => {
        updateGroupPageNumber(pageNumber);
    };
    const [disablePagination, setDisablePagination] = useState<boolean>(false);
    
    const handleCreateGroupModalClose = () => {
        props.createGroupPopupToggleHandler();
        resetAddUpdateGroup();
    }

    const handleEditGroupModalClose = () => {
        props.editGroupPopupToggleHandler();
        resetAddUpdateGroup();
        resetDeleteGroup();
    }

    const handleEditGroup = (groupId: any) => {
        setEditGroupId(groupId);
        setSelectedGroupUsingId(groupId)
        props.editGroupPopupToggleHandler();
    }

    const handleDeleteClick = (groupId: number, groupName: string) => {
        setDeleteGroupId(groupId);
        setDeleteGroupName(groupName);
        props.deletePopupToggleHandler();
    };

    const deleteGroupHandler = (groupId: number) => {
        deleteGroup(groupId);
    }

    useEffect(() => {
        if(trainerGroupPaginationState.PagingDetails.isPagerChange){
            getAllGroups(trainerGroupPaginationState.PagingDetails.PageNo,trainerGroupPaginationState.PagingDetails.PageSize);
            resetGroupPagingState();
        }
    }, [trainerGroupPaginationState.PagingDetails.isPagerChange]);

    useEffect(() => {
        if (deleteGroupState.success) {
            resetDeleteGroup();
            getAllGroups(trainerGroupPaginationState.PagingDetails.PageNo,trainerGroupPaginationState.PagingDetails.PageSize);
            props.deletePopupToggleHandler();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteGroupState.success]);

    const handleClickNavigate = () => {
        navigate(RoutesConstants.TrainerDashboard)
    }
    return (
        <>
            {getTrainerGroupListState.inProgress && (
                <FormLoader loading={getTrainerGroupListState.inProgress} />
            )}
            {props.showCreateGroupPopup && (
                <CreateGroupPopup modalClosed={handleCreateGroupModalClose} isLoading={addUpdateGroupState.inProgress} />
            )}
            {props.showEditGroupPopup && (
                <EditGroupPopup modalClosed={handleEditGroupModalClose} isLoading={addUpdateGroupState.inProgress} />
            )}
            {props.showDeletePopup && (
                <DeletePopup modalClosed={props.deletePopupToggleHandler} isLoading={deleteGroupState.inProgress} Id={deleteGroupId} Title={deleteGroupName} deleteHandler={deleteGroupHandler} />
            )}
            <div className="dashboar-container  ">
                <div className="dashboard_heading">
                    <div className="container">
                        <h1>
                            <FormattedMessage id="manage_Groups" />
                        </h1>
                        <button className="btn btn-light  mt-2 mx-2" onClick={() => handleClickNavigate()}>
                            <i className='fas fa-arrow-left'></i>
                        </button>
                        <button className="btn btn-primary mt-2" onClick={handleCreateGroupModalOpen}>
                            <FormattedMessage id="add_Group" />
                        </button>


                    </div>
                </div>
                <div className="container py-5">
                    <div className="table-responsive pt-4">
                        <table className="table tableDesign">
                            <thead>
                                <tr>
                                    <th>
                                        <span><FormattedMessage id='Group_name' /></span>
                                    </th>
                                    <th className='text-center' style={{ width: "100px" }}>
                                        <FormattedMessage id='actions' />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allTrainerGroups?.map((group: ITrainerGroup) => {
                                    return (
                                        <tr>
                                            <td>{group.GroupName}</td>

                                            <td className='text-center'>
                                                <button className="btn btn-outline-info me-2 btn-sm" onClick={() => handleEditGroup(group.GroupId)} title={formatMessage('edit')}>
                                                    <svg height="492pt" viewBox="0 0 492.49284 492" width="492pt" xmlns="http://www.w3.org/2000/svg"><path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" /><path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" /></svg>
                                                </button>
                                                <button className="btn btn-outline-danger btn-sm me-auto" onClick={() => handleDeleteClick(group.GroupId, group.GroupName)} title={formatMessage('delete')}>
                                                    <svg version="1.1" id="Layer_1" viewBox="0 0 512 512" >
                                                        <g>
                                                            <g>
                                                                <path d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
        L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
        c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
        c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
        c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
        c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"/>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <path d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
        c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
        c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                                }

                            </tbody>
                        </table>
                    </div>
                    { 
                        <CustomPagination
                            onPageLimitChanged={onPageLimitChanged}
                            onPageChanged={onPageChanged}
                            pageSize={trainerGroupPaginationState.PagingDetails.PageSize}
                            totalPages={trainerGroupPaginationState.PagingDetails.TotalPages}
                            isRefreshPage={trainerGroupPaginationState.PagingDetails.isRefreshPage}
                            disablePagination={ allTrainerGroups.length > 0 ? disablePagination : true }
                        />
                    }
                </div>
                
            </div>


        </>
    )
}

export default DeleteGroupPopupHOC(EditGroupPopupHOC(CreateGroupPopupHOC(observer(ManageGroups))));
