/*    
<summary>
   This functional common component shows form validation errors.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary> 
<returns>Returns JSX</returns>
*/
import React from 'react';
import { formatMessage } from '../../translations/format-message';
//import { injectIntl } from 'react-intl';

interface IProps {
  meta: any;
  intl: any;
}
const FormikFormErrorHandler: React.FC<IProps> = props => {
  const _error = props.meta.error
    ? props.intl.formatMessage({ id: props.meta.error })
    : props.meta.error;
  return (
    <>
      {props.meta.touched &&
        ((props.meta.error && <span data-testid="testError" className="text-danger errorMessage">{formatMessage(_error)}</span>) ||
          (props.meta.warning && <span data-testid="testWarn" className="text-danger">{props.meta.warning}</span>))}
    </>
  );
};

// export default injectIntl(FormikFormErrorHandler);
export default FormikFormErrorHandler;
