import { observer } from "mobx-react";
import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { IUpdateTrainerInvities } from "../../../models/response/ITrainerResponse";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import { Button, Modal } from "react-bootstrap";
import Label from "../../../shared-components/Label/Label";
import { FormattedMessage } from "react-intl";

interface IProps {
    initialValues: IUpdateTrainerInvities;
    submitHandler: (data: IUpdateTrainerInvities) => void;
    allTrainerGroups: any;
    ResultTypes: Array<any>;
    modalClosed: () => void;
    isLoading: boolean;

}

const EditTrainerInvitedInfoForm = (props: IProps) => {
    const { initialValues, submitHandler, allTrainerGroups } = props;
    return (
        <>
            <Modal show={true} centered
                onHide={props.modalClosed}
                backdrop="static"
                scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h1><FormattedMessage id="Edit_Trainer_Title" /></h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur={false}
                        onSubmit={async (values: IUpdateTrainerInvities) => {
                            let CreateObject: IUpdateTrainerInvities = {
                                InvitesId: values.InvitesId,
                                Note: values.Note,
                                GroupName: values.GroupName,
                                RecipientEmail: values.RecipientEmail,
                                TestType: values.TestType
                            }
                            submitHandler(CreateObject);

                        }}
                        render={({ values }) => {
                            return (
                                <>
                                    <Form id="EditTrainerInvitedInfoForm" className="modal-form-SendInvities">
                                        <div className='row'>
                                            <div className='col-lg-6 col-12 text-lg-left text-center'>
                                                <div className="form-group ">
                                                    <p><FormattedMessage id="email" /></p>
                                                    <span className="text-dark">{initialValues.RecipientEmail}</span>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-12 text-lg-right text-center'>
                                                <label className='badge bg-primary '>{initialValues.TestType}</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-12'>
                                                <div className="form-group ">
                                                    <Label
                                                        className="fw-bold"
                                                        label="Group"
                                                        htmlFor="GroupName" />
                                                    <Field
                                                        data-testid="GroupName"
                                                        className="form-control text-dark"
                                                        name="GroupName"
                                                        // getData={true}
                                                        options={allTrainerGroups}
                                                        as={FormikFormSelect}
                                                        invalidcharacter={[" "]} />
                                                </div>
                                            </div>
                                        
                                            <div className='col-12'>
                                                <div className="form-group ">
                                                    <Label
                                                        className="fw-bold"
                                                        label="Note"
                                                        htmlFor="Note" />
                                                    <Field
                                                        data-testid="Note"
                                                        className="form-control"
                                                        name="Note"
                                                        placeholder="Note"
                                                        as={FormikFormTextArea} />
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            );
                        }}
                    >
                    </Formik >
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end ">
                        <Button
                            data-testid="Cancel"
                            variant="secondary"
                            onClick={props.modalClosed}
                            disabled={props.isLoading}
                            className="btnOkSize me-2">
                            <FormattedMessage id="button_cancel" />
                        </Button>
                        <LoaderButton
                            type="submit"
                            id="UpdateButton"
                            form="EditTrainerInvitedInfoForm"
                            isLoading={props.isLoading}
                            text="update"
                        />
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    )
}
export default observer(EditTrainerInvitedInfoForm);

