import * as React from 'react';
import { useField } from 'formik';
import { formatMessage } from '../../translations/format-message';
import { useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

export default function FormikMultiEmailField(props:any) {
  const { disabled, min, max , label} = props;
  const [ field, meta, helpers ] = useField(props);
  const { touched, error } = meta;
  const [dataError, setDataError] = useState("");
  const [emails, setEmails] = React.useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>();
  const [focused, setFocused] = React.useState(false);

  return (
    <>
        <ReactMultiEmail
            className="form-control"
            // initialInputValue={field.value ? field.value : null}
            placeholder='jondoe@example.com'
            inputValue={inputValue}
            emails={emails}
            onChange={(_emails: string[]) => {
                setEmails(_emails);
                helpers.setValue(_emails);
            }}
            onChangeInput={(_inputVal: string) => {
                setInputValue(_inputVal);
            }}
            autoFocus={true}
            onFocus={() => setFocused(true)}
            onBlur={() => {
                setFocused(false);  
                if (inputValue?.trim() !== '') 
                    helpers.setValue([...emails, inputValue]); 
                else 
                    helpers.setValue([...emails]) 
                }
            }
            getLabel={(email, index, removeEmail) => {
                return (
                    <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                    </span>
                    </div>
                );
            }}
            />

    { 
        (error && <span data-testid="testError" className=" text-danger errorMessage">{formatMessage(error)}</span>)
        // ||
        // (dateError && <span data-testid="testError" className=" text-danger errorMessage">{formatMessage(dateError)}</span>)
    }
    </>
  );
}