import React, { useEffect } from "react";
import { observer } from "mobx-react";
import AddEditCouponForm, { FormTypeEnum } from "../AddEditCouponForm/AddEditCouponForm";
import { useStore } from "../../../../contexts/StoreProvider";
import toast from "react-hot-toast";
import { formatMessage } from "../../../../translations/format-message";

interface IProps {
    modalClosed: () => void;
    isLoading: boolean;
}

const CreateCouponPopup = (props: IProps) => {
    const { adminStore } = useStore();
    const { addUpdateCouponState, resetAddUpdateCoupon} = adminStore;
  
    useEffect(() => {
        if (addUpdateCouponState.success) {
          resetAddUpdateCoupon();
          props.modalClosed();
        }
      }, [addUpdateCouponState.success]);
    

    return (
        <AddEditCouponForm modalClosed={props.modalClosed} FormType={FormTypeEnum.Create} isLoading={props.isLoading} />
    );
};

export default observer(CreateCouponPopup);
