import { IGetAllCoupons } from "../../models/response/IAdminResponse";

export const initialState: IGetAllCoupons = {
  PagingDetails:{
    TotalRecords: 1,
    TotalPages: 1,
    PageNo: 1,
    PageSize: 10,
},
  CouponList: [],
  };