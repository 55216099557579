import React, { useEffect, useState } from 'react'
import { useStore } from '../../../contexts/StoreProvider';
import { observer } from 'mobx-react';
import { ITrainerGroup } from '../../../models/response/ITrainerGroupResponse';
import {  IUpdateTrainerInvities } from '../../../models/response/ITrainerResponse';
import EditTrainerInvitedInfoForm from './EditTrainerInvitedInfoForm';
import toast from 'react-hot-toast';
import { formatMessage } from '../../../translations/format-message';

interface IProps {
    modalClosed: () => void;
    openTrainerId: any
}

export interface IOptions {
    id: number;
    value: string;
}

export const ResultTypes = [
    { id: "1", value: "Result_visible " },
    { id: "0", value: "Result_invisible" },
];

const EditTrainerInvitedInfo = (props: IProps) => {
    const { trainerStore } = useStore();
    const { getTrainerInvitiesInfo, updateTrainerInviteState, resetUpdateTrainerInvitesInfo, getAllGroups, 
            getTrainerInvities, allTrainerGroups, TrainerInvities, updateTrainerInvitesInfo, getAllTrainerInvities,
            trainerPaginationState, trainerGroupPaginationState, resetSentTrainerInvites} = trainerStore;
    const [TrainerGroups, setTrainerGroups] = useState<IOptions[]>([])

    useEffect(() => {
        getTrainerInvitiesInfo()
        getTrainerInvities(Number(props.openTrainerId));
        getAllGroups();
    }, [props.openTrainerId]);

    useEffect(() => {
        if (allTrainerGroups.length > 0) {
            const changingKey: any[] = [{id : "0", value: 'please_select'}]
            changingKey.push(...allTrainerGroups.map((key: ITrainerGroup) => {
                return { id: key.GroupName, value: key.GroupName }
            }))
            setTrainerGroups(changingKey)
        }
    }, [allTrainerGroups])

    useEffect(() => {
        if (TrainerInvities.ResultVisible) {
            TrainerInvities.ResultVisible = ResultTypes[0].id
        } else {
            TrainerInvities.ResultVisible = ResultTypes[1].id
        }
    }, [TrainerInvities])
    
    useEffect(() => {
        if (updateTrainerInviteState.success) {
            props.modalClosed()
            resetUpdateTrainerInvitesInfo();
            getAllTrainerInvities(trainerPaginationState.PagingDetails.PageNo,trainerPaginationState.PagingDetails.PageSize,)
        }
    }, [updateTrainerInviteState.success]);

    const onSubmitForm = (values: IUpdateTrainerInvities) => {
        updateTrainerInvitesInfo(values);
        resetSentTrainerInvites();
        resetUpdateTrainerInvitesInfo();
    };
   
    const handleModalClose = () => {
        props.modalClosed();
        resetSentTrainerInvites();
        resetUpdateTrainerInvitesInfo();
    }

    return (
        <>
            <EditTrainerInvitedInfoForm
                initialValues={TrainerInvities}
                submitHandler={onSubmitForm}
                allTrainerGroups={TrainerGroups}
                ResultTypes={ResultTypes}
                modalClosed={handleModalClose}
                isLoading={updateTrainerInviteState.inProgress}
            />
        </>
    )
}
export default observer(EditTrainerInvitedInfo);