import { IPagingDetails } from "../models/response/IPagingDetails"

/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined constant.
   This constant is used in displaying error messages according to the error code recieved from the backend API.
      Developer: Mohammad Saquib Khan, Created Date: 29 March 2023
</summary>
*/
export const Group = [
   { id: -1, value: "please_select" },
   { id: 1, value: "Group 1" },
   { id: 2, value: "Group 2" },
   { id: 3, value: "Group 3" },
   { id: 4, value: "Group 4" },
   { id: 5, value: "Group 5" },
]

export const Protocol = [
   { id: -1, value: "please_select" },
   { id: 0, value: "HTTP" },
   { id: 1, value: "HTTPS" },
   { id: 2, value: "MQTTS" },
]

export const SourceProtocol = [
   { id: 0, value: "HTTP" },
   { id: 1, value: "MQTT" },
]

export const Configs = [
   { id: -1, value: "please_select" },
   { id: 0, value: "MonoZ Link Basic Configuration" },
   { id: 1, value: "Monoz Default Config 1" },
   { id: 2, value: "Monoz Default Config 2" },
]

export const Groups = [
   { id: -1, value: "please_select" },
   { id: 0, value: "MonoZ Link Basic Group" },
]

export const Authorization = [
   {id: 0, value: "No Authorization"},
   {id: 1, value: "Basic Authorization (Default)"},
   {id: 2, value: "API Key"},
   {id: 3, value: "Bearer Token"},
   {id: 4, value: "AWS Signature"},
]
export const pageLimitOptions: Array<number> = [10, 20, 50, 100, 200];

export const initialPaginationStateValue: IPagingDetails = {
   PagingDetails: {
     PageNo: 1,
     PageSize: 10,
     TotalPages: 1,
     TotalRecords: 1,
   }
 }

 export const OTPResendTimeInSeconds: number = 30