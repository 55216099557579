import logo from '../../assets/Images/logo_original.png';
import ChihiroTanaka from '../../../assets/Images/chihiro-tanaka.jpg';
import Roberto from '../../../assets/Images/roberto.jpg';
import React, { useEffect, useState } from 'react';
import './AboutCreators.css';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '../../../contexts/StoreProvider';
import { ICreator } from '../../../models/ICreatorInfo';
import DOMPurify from 'dompurify';

const AboutCreators = (props: any) => {
    const { preferencesStore, adminStore } = useStore();
    const { getCreatorsInfo, getCreatorInfoState, allCreators
     } = adminStore;
    const lang = preferencesStore.getLanguage;

    useEffect(() => {
        getCreatorsInfo();
    }, []);
    return (
        <>
            <div className="banner--light breadcrumb-area">
                <div className="container">
                    <h1 className="page-title"><FormattedMessage id="The_creators" /></h1>
                </div>
            </div>

            <div className="container">
                <div className='row py-5'>
                    { allCreators && allCreators.length > 0 
                        ?
                        allCreators.map((creator: ICreator) => {
                        return (
                            <div className='col-md-12 col-12'>
                                <section className="creator">
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12 col-12'>
                                            <div className='sticky_img'>
                                                <img src={`data:image/png;base64, ${creator.CreatorImageInfo}`} className='img-fluid'/>
                                                <h3 className="my-0 py-0 team-details-title">{creator.CreatorName}</h3>
                                                <div className="d-flex justify-content-center mt-2 mb-4">
                                                    <Button
                                                        target='_blank'
                                                        href={creator.LinkedInURL}
                                                        className='me-3'>
                                                        <FontAwesomeIcon icon={faLinkedin} name="linkedIn" />
                                                    </Button>
                                                    <Button
                                                        target='_blank'
                                                        href={`mailto:${creator.MailId}`}
                                                        className='mx-3'>
                                                        <FontAwesomeIcon icon={faEnvelope} name="linkedIn" />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-8 col-md-12 col-12'>
                                            <div dangerouslySetInnerHTML={{__html:
                                                lang == "en" 
                                                    ?
                                                    DOMPurify.sanitize(creator.CreatorDesc_EN)
                                                    :
                                                    lang == "jp"
                                                    ?
                                                    DOMPurify.sanitize(creator.CreatorDesc_JP)
                                                    :
                                                    DOMPurify.sanitize(creator.CreatorDesc_IT)
                                            }}></div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )
                        })
                        : 
                        <></>
                    }
                </div>
            </div>
        </>
)
}
export default observer(AboutCreators);