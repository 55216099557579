import React, { useEffect, useState } from 'react'
import ThresholdCard from './ThresholdCard'
import { Button, Col, Row } from 'react-bootstrap';
import { useStore } from '../../../contexts/StoreProvider';
import { IThreshold } from '../../../models/state/IThreshold';
import { observer } from 'mobx-react';
import DeletePopupHOC from '../../agent-dashboard/hoc/DeletePopupHOC';
import DeletePopup from '../../agent-dashboard/components/Delete/DeletePopup';
import { FormattedMessage } from 'react-intl';
import { Box, CircularProgress } from '@mui/material';


export interface CardItem {
    id: number;
    Label: string;
    Slug: string;
    Value: number;
    Edit?: boolean;
    isNew?: boolean;
}

const Threshold = (props: any) => {

    const { adminStore } = useStore();
    const { allThreshold, getAllThreshold, getAllThresholdState, addupdateThresholdState, deleteThresholdState, resetDeleteThresholdState, resetAddUpdateThresholdState, deleteThreshold } = adminStore

    const [newThresholdData, setNewThresholdData] = useState<Array<IThreshold>>([

    ]);
    const [deleteThresholdId, setDeleteThresholdId] = useState<number>(-1);
    const [deleteThresholdName, setDeleteThresholdName] = useState<string>("");
    const [editingIndex, setEditingIndex] = useState<number | null>(null);

    useEffect(() => {
        getAllThreshold();
    }, []);

    useEffect(() => {

        if (addupdateThresholdState.success) {
            getAllThreshold();
            resetAddUpdateThresholdState();
        }
    }, [addupdateThresholdState.success]);

    useEffect(() => {

        if (deleteThresholdState.success) {
            getAllThreshold();
            resetDeleteThresholdState();
            props.deletePopupToggleHandler();
        }
    }, [deleteThresholdState.success]);

    const AddNewJson = () => {
        setNewThresholdData((prevData) => [
            ...prevData,
            {
                ThresholdId: -1,
                Label: "",
                Slug: "",
                Value: "",
                IsOriginal: false
            }
        ]);
        //set the new card into editing state
        setEditingIndex(allThreshold.length);
        // console.log(newThresholdData)
    };

    const DeleteJson = (index: number | null) => {
        if (index != null) {
            newThresholdData.splice(index, 1);
        }
    }

    const handleEdit = (index: number) => {
        //if one card is already in edit state then another can not be in edit state
        if (editingIndex === -1 || editingIndex == null) {
            setEditingIndex(index);
        }
    }

    const handleSave = () => {
        //saving will set the the index to -1 means now no one is in edit state
        DeleteJson(editingIndex);
        setEditingIndex(-1);
    };

    const deleteThresholdCard = (ThresholdId: number) => {
        deleteThreshold(ThresholdId);
    }

    const handleDeleteClick = (thresholdId: number, thresholdName: string) => {
        setDeleteThresholdId(thresholdId);
        setDeleteThresholdName(thresholdName);
        props.deletePopupToggleHandler();
    };

    return (
        <>
            {props.showDeletePopup && (
                <DeletePopup modalClosed={props.deletePopupToggleHandler} Id={deleteThresholdId} isLoading={deleteThresholdState.inProgress} Title={deleteThresholdName} deleteHandler={deleteThresholdCard} />
            )}
            {
                !(getAllThresholdState.inProgress || (!getAllThresholdState.success && getAllThresholdState.error === '' ))
                && 
                <div className="dashboardTopbar">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-7">
                                <p className="text-dark"><FormattedMessage id="total_threshold" />: {allThreshold.length}</p>
                            </div>
                            <div className="col-5 text-md-end text-center">
                                <Button onClick={() => AddNewJson()}><i className="fa fa-plus"></i> <FormattedMessage id="add_new_card" /></Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        
            <div className='container'>
                <Row>
                    {
                        getAllThresholdState.inProgress || (!getAllThresholdState.success && getAllThresholdState.error === '' ) 
                        ?
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px'}}>
                                <CircularProgress disableShrink={true}/>
                            </Box>
                        </>
                        :
                        <>
                        {allThreshold.map((data, index:number) => (
                            <Col key={index} xs={12} md={4} className="mb-4">
                                <ThresholdCard JsonData={data} DeleteJson={DeleteJson} isEdit={editingIndex === index}
                                    onEdit={() => handleEdit(index)} onSave={handleSave} DeleteThreshold={handleDeleteClick} />
                            </Col>
                        ))}
                        {newThresholdData.map((data, index) => (
                            <Col key={index} xs={12} md={4} className="mb-3">
                                <ThresholdCard JsonData={data} DeleteJson={DeleteJson} isNew={true} index={index} isEdit={true} onEdit={() => handleEdit(index)} onSave={handleSave} DeleteThreshold={handleDeleteClick} />
                            </Col>
                        ))}
                        </>
                    }
                    
                </Row>
            </div>
        </>
    )
}

export default DeletePopupHOC(observer(Threshold))
