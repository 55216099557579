import { Formik, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { useStore } from '../../contexts/StoreProvider';
import { observer } from 'mobx-react';
import Label from '../../shared-components/Label/Label';
import { Button } from 'react-bootstrap';
import { initialState as initialRegistrationState } from '../../core/initial-state/registration-state';
import nationData from './nation.json';
import ILogin from '../../models/ILogin';
import RoutesConstants from '../../shared-components/Routes/routes.constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoaderButton from '../../shared-components/Button/LoaderButton';
import TopNavBar from '../../shared-components/NavBar/TopNavBar';
import Footer from "../../shared-components/Footer/Footer";
import { registrationFormValidationSchema } from '../../shared-components/FormikFormHandlers/FormikValidationSchema';
import VerificationPopupHOC from './hoc/VerificationPopupHOC';
import VerificationModal, { OTPModalType } from './VerificationPopup/VerificationModal';
import RegistrationForm from './form/RegistrationForm';
import { IAddUser } from '../../models/Form/IAddUser';

export const genderOptions = [
    { id: "", value: "please_select", disabled: true },
    { id: "1", value: "gender_m" },
    { id: "2", value: "gender_f" },
    { id: "3", value: "gender_prefer_no_say" },
];

export const nationOptions = [
    { "id": "", "value": "please_select"},
    ...nationData
];

export const educationOptions = [
    { "id": "0", "value": "please_select"},
    { "id": "1", "value": 'education_junior_high'},
    { "id": "2", "value": "education_high"},
    { "id": "3", "value": "education_university"},
    { "id": "4", "value": "education_master"},
];

export const workPostionOptions = [
    { "id": "0", "value": "please_select"},
    { "id": "1", "value": "work_pos_worker"},
    { "id": "2", "value": "work_pos_office_worker"},
    { "id": "3", "value": "work_pos_freelance"},
    { "id": "4", "value": "work_pos_exec_officer"},
    { "id": "5", "value": "work_pos_self_emp"},
    { "id": "6", "value": "work_pos_manager"},
    { "id": "7", "value": "work_pos_sales"},
    { "id": "8", "value": "work_pos_customer_service"},
    { "id": "9", "value": "work_pos_emp_tech"},
    { "id": "10", "value": "work_pos_emp_gen"},
    { "id": "11", "value": "work_pos_temp_emp"},
    { "id": "12", "value": "work_pos_part_time"},
    { "id": "13", "value": "work_pos_telecom"},
    { "id": "14", "value": "work_pos_student"},
    { "id": "15", "value": "work_pos_housewife"},
    { "id": "16", "value": "work_pos_unemp"},
    { "id": "17", "value": "work_pos_other"},
];

export const jobSectorOptions = [
    { "id": "0", "value": "please_select"},
    { "id": "1", "value": "job_manufacture"},
    { "id": "2", "value": "job_commerce"},
    { "id": "3", "value": "job_fin"},
    { "id": "4", "value": "job_edu"},
    { "id": "5", "value": "job_med"},
    { "id": "6", "value": "job_it"},
    { "id": "7", "value": "job_prof"},
    { "id": "8", "value": "job_public"},
    { "id": "9", "value": "job_agri"},
    { "id": "10", "value": "job_tours"},
    { "id": "11", "value": "job_law"},
    { "id": "12", "value": "job_food"},
    { "id": "13", "value": "job_beauty"},
    { "id": "14", "value": "job_media"},
    { "id": "15", "value": "job_construct"},
    { "id": "16", "value": "job_transport"},
    { "id": "17", "value": "job_security"},
    { "id": "18", "value": "job_art"},
    { "id": "19", "value": "job_other"},
];


const Registration = (props: any) => {
    const { preferencesStore, authStore } = useStore();
    const [searchParams] = useSearchParams();
    const [redirectUrl, setRedirectUrl] = useState<string|null>(searchParams.get('continue'));
    const { addUser, addUpdateUserState, login, isAuthenticated, getToken, otpGenerationOnRegister, 
        otpGenerationState, resetOtpGenerationState, otpVerification, otpVerificationState, resetOtpVerificationState,resetValidateRefererState, validateRefererState, validateRefererCode } = authStore;
    const lang = preferencesStore.getLanguage;
    const [registrationFormValues, setRegistrationFormValues] = useState<IAddUser>(initialRegistrationState);

    const navigate = useNavigate();

    // useEffect (() => {
    //     if (addUpdateUserState.success)
    //     {
    //         // debugger
    //         const userData : ILogin = {
    //             email : formik.values.Email,
    //             password : formik.values.Password,
    //             rememberMe : false
    //         }
    //         login(userData);
    //     }
    // }, [addUpdateUserState.success])
    
    useEffect(() => {
        if (isAuthenticated || getToken) {
            if (redirectUrl != null && redirectUrl != "") {
                window.location.href = `${decodeURIComponent(redirectUrl)}`
            }
            else {
                navigate(RoutesConstants.Dashboard);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isAuthenticated]);

    const registrationFormSubmitHandler = (values: IAddUser) => {
        otpGenerationOnRegister(values.Email, values.Referent, lang);
        setRegistrationFormValues((prev) => values);
        resetOtpVerificationState();
    }

    const otpGenerationHandler = () => {
        otpGenerationOnRegister(registrationFormValues.Email, registrationFormValues.Referent, lang);
        resetOtpVerificationState();
    }

    const otpVerificationHandler = (otp: string) => {
        resetOtpVerificationState();
        otpVerification(registrationFormValues.Email, otp);
    }

    const otpModalCloseHandler = () => {
        resetOtpGenerationState();
        resetOtpVerificationState();
        props.verificationPopupToggleHandler();
    }

    const handleEdit = () => {
        resetValidateRefererState();
    };

    const handleValidation = (referer:string)=>{
        validateRefererCode(referer);
    }
    useEffect(() => {
        if (otpVerificationState.success && props.showVerificationPopup) {
            // props.verificationPopupToggleHandler();
            // Do actual registration
            addUser(registrationFormValues, lang);
            //resetOtpVerificationState();
        }
    }, [otpVerificationState.success]);

    useEffect(() => {
        if (otpGenerationState.success && !props.showVerificationPopup) {
            props.verificationPopupToggleHandler();
            resetOtpGenerationState();
        }
    }, [otpGenerationState.success])

    return (
        <>
            <TopNavBar/>
            {props.showVerificationPopup && (
                <VerificationModal modalClosed={otpModalCloseHandler} isAddLoginUserLoading={addUpdateUserState.inProgress}
                    otpVerificationHandler={otpVerificationHandler} otpGenerationHandler={otpGenerationHandler} isOtpGenerationLoading={otpGenerationState.inProgress} 
                    userEmail={registrationFormValues.Email} otpVerificationState={otpVerificationState} otpModalType={OTPModalType.Register}/>
            )}

            <div className="banner--light breadcrumb-area">
                <div className="container">
                    <h1 className="page-title"><FormattedMessage id="email_registration" /></h1>
                    <h3><FormattedMessage id="register_account" /></h3>
                </div>
            </div>            
            <RegistrationForm 
                initialValues={initialRegistrationState} 
                submitHandler={registrationFormSubmitHandler} 
                isLoading={otpGenerationState.inProgress && !props.showVerificationPopup} 
                refererProgress={validateRefererState.inProgress}
                refererSuccess={validateRefererState.success}
                refererError={validateRefererState.error}
                handleRefererValidation={handleValidation}
                handleRefererEdit={handleEdit}
                handleResetRefererState={resetValidateRefererState}/>
        </>
    )
}

export default VerificationPopupHOC(observer(Registration));
