import React, { useState } from "react";

const EditStatHOC = (Component: any) =>
  function Comp(props: any) {
    const [showEditStatPopup, setShowEditStatPopup] = useState<boolean>(false);
    const EditStatToggleHandler = () => {
      setShowEditStatPopup(!showEditStatPopup);
    };

    return (
      <Component
        showEditStatPopup={showEditStatPopup}
        EditStatToggleHandler={EditStatToggleHandler}
        {...props}
      />
    );
  };

export default EditStatHOC;