import { Field, Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import LoaderButton from "../Button/LoaderButton";
import Label from "../Label/Label";
import FormikFormInput from "../FormikFormHandlers/FormikFormInput";
import { useStore } from "../../contexts/StoreProvider";
import { Button, Modal } from "react-bootstrap";
import './AddEditEventForm.css';
import IAddEvent, { IUpdateEvent } from "../../models/Form/IAddUpdateEvent";
import { Console } from "console";
import { observer } from "mobx-react";
import toast from "react-hot-toast";
import { FormTypeEnum, TestType } from "../../constants/enum";
import { forIn } from 'lodash';
import { ValidateEvent } from "../FormikFormHandlers/FormikValidationSchema";



const options:Array<string>  = [];
forIn(TestType, (value, key) => {
    if ((Number(value) && Number(value) != 4)) 
        options.push(key)
});

interface IProps{
    FormType: FormTypeEnum,
    EventId?: any 
    modalClosed: () => void;
    isLoading: boolean;
}

const AddEditEventForm = (props: IProps) => {
    const { agentStore } = useStore();
    const { addEvent, getEventDetail, updateEvent, validateCouponCodeState,couponDiscount,resetValidateCouponState,validateCouponCode} = agentStore

    const formik = useFormik({
        initialValues: getEventDetail,
        validationSchema: ValidateEvent,
        onSubmit: async(values: IAddEvent | IUpdateEvent) => { 
            // console.log(props.EventId);
            if (props.FormType === FormTypeEnum.Create){
                addEvent(values);
            }
            else if (props.FormType === FormTypeEnum.Edit){
               updateEvent(values as IUpdateEvent, props.EventId);
            }
         }
    })

    const handleEdit = () => {
        resetValidateCouponState();
      };
    const handleValidation = (couponCode:string)=>{
        validateCouponCode(couponCode);
    }
    
    return (
    <>
        <Modal show={true} centered
                onHide={props.modalClosed}
                backdrop="static"
                scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>
                {(props.FormType===FormTypeEnum.Create) ? <FormattedMessage id="create_new_event" /> : <FormattedMessage id="edit_event"/> }    

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="addEditEventForm" className="modal-form" onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                    <Label
                        required={true}
                        className="fw-bold"
                        label="event_name"
                        htmlFor="EventName" />
                        <input type="text" className="form-control" id="EventName" value={formik.values.EventName} onBlur={formik.handleBlur} onChange={formik.handleChange} name='EventName' placeholder="Enter the event name" />
                        {formik.errors.EventName && formik.touched.EventName && (<>
                            <span className="text-danger errorMessage mb-3"><FormattedMessage id ={`${formik.errors.EventName}`}/></span>
                        </>
                        )}
                    </div>
                    <div className='form-group'>
                    <Label
                        required={true}
                        className="fw-bold"
                        label="test_version"
                        htmlFor="TestType" />
                        <div className='d-flex flex-wrap'>
                            {options.map((option: string) => (
                                <div key={option}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="TestType"
                                            value={option}
                                            id={`test-${option}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            checked={formik.values.TestType === option}
                                        />
                                        <label className="form-check-label me-5" htmlFor={`test-${option}`}>
                                            {option}
                                        </label>
                                </div>
                            ))}
                        </div>
                        {formik.touched.TestType && formik.errors.TestType ? (
                            <span className="text-danger errorMessage mb-3"><FormattedMessage id ={`${formik.errors.TestType}`}/></span>
                        ) : null}
                        
                    </div>
                   <div className="form-group">
    {!validateCouponCodeState.success ? (
        <div>
            <Label className="fw-bold" label="coupon" htmlFor="CouponCode" />
            <div className="input-group">
                <input
                    type="text"
                    className="form-control"
                    id="CouponCode"
                    value={formik.values.CouponCode}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                        formik.handleChange(e);
                        if (validateCouponCodeState.error !== "") {
                            resetValidateCouponState();
                        }
                    }}
                    name='CouponCode'
                    placeholder="Enter the coupon"
                />
               
            <LoaderButton
                className="btn btn-primary input-group-text"
                type="button"
                isLoading={validateCouponCodeState.inProgress}
                onClick={() => handleValidation(formik.values.CouponCode)}
                disabled={!formik.values.CouponCode}
                text="Validate"
            />
            </div>
            {(validateCouponCodeState.error !== "") && (
                <div className="error text-danger"><FormattedMessage id="validation_coupon" /></div>
            )}
        </div>
    ) : (
        <div>
            <Label className="fw-bold" label="coupon" htmlFor="CouponCode" />
            <div onClick={() => handleEdit()}>
                <span style={{ cursor: 'pointer' }}>{formik.values.CouponCode}</span>
                <div className="text-success">{couponDiscount}% discount is applied</div>
            </div>
        </div>
    )}
</div>


                    <div className="form-group">
                    <Label
                        className="fw-bold"
                        label="note"
                        htmlFor="Note" />
                        <textarea className="form-control" rows={3} id="EventName" value={formik.values.Note} onBlur={formik.handleBlur} onChange={formik.handleChange} name='Note' placeholder="Enter the Note" />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                    <Button
                        data-testid="Cancel"
                        variant="secondary"
                        onClick={props.modalClosed}
                        disabled={props.isLoading}
                        className="btnOkSize">
                        <FormattedMessage id="button_cancel" />
                    </Button>

                    {(props.FormType==FormTypeEnum.Create) 
                     ?
                    <LoaderButton
                        type="submit"
                        id="RegisterButton"
                        className="btnOkSize"
                        form="addEditEventForm"
                        isLoading={props.isLoading}
                        text="register"
                    />
                    :
                    <LoaderButton
                        type="submit"
                        id="UpdateButton"
                        className="btnOkSize"
                        form="addEditEventForm"
                        isLoading={props.isLoading}
                        text="update"
                    />
                    }
                </Modal.Footer>
        </Modal>
        </>
    )
}

export default observer(AddEditEventForm)
