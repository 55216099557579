import RoutesConstants from '../../shared-components/Routes/routes.constants';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
// import PaymentCard from './PaymentCard';
import { observer } from 'mobx-react';
import { useStore } from '../../contexts/StoreProvider';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Spinner } from 'react-bootstrap';
import PaymentModalHOC from './hoc/PaymentModalHOC';
import PaymentModal from './components/PaymentModal';
import IPaymentCard from '../../models/Form/IPaymentCard';
import './PaymentPage.css';
import { Formik } from 'formik';
import { ProductType } from '../../constants/enum';
import LoaderButton from '../../shared-components/Button/LoaderButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { formatMessage } from '../../translations/format-message';

const PaymentPage = (props: any) => {
    const navigate = useNavigate();
    const { authStore, preferencesStore } = useStore();
    const lang = preferencesStore.getLanguage;
  
    const {couponCodeDiscount, getCouponCodeDiscount, resetDiscountState, resetPaymentState, personalPlanPayment, couponCodeDiscountState, finalPaymentState, resetFinalPaymentState } = authStore;
    const [couponCode, setCouponCode] = useState<string>();
    // const TaxCalculationFn = (couponCodeDiscount: number) => {
    //     const discountAmount = couponCodeDiscount != -1 ? ((data.planAmount * couponCodeDiscount) / 100.0) : 0;
    //     const afterCouponAmount = data.planAmount - discountAmount;
        
    //     return {
    //         AfterCoupon: afterCouponAmount,
    //         ServiceTax: ((afterCouponAmount * data.serivceTaxPercentage) / 100.0),
    //         TotalAmount: afterCouponAmount + ((afterCouponAmount * data.serivceTaxPercentage) / 100.0)
    //     }
    // }

    const handlePaymentModalOpen = () => {
        props.paymentModalPopupToggleHandler();
    }

    const handlePaymentModalClose = () => {
        props.paymentModalPopupToggleHandler();
        resetFinalPaymentState();
    };

    const handlePaymentCardSubmit = (values: IPaymentCard) => {
        // console.log(values);
        personalPlanPayment(values, props.productType, getCouponCodeIfAny(), lang, props.testId);
    }
    // return <PaymentCard commonState={commonState} setcommonState={setcommonState} data={data} TaxCalculationFn={TaxCalculationFn} PersonalPlanPaymentFunc={personalPlanPayment} />

    const getCouponCodeIfAny = () => {
        return couponCodeDiscountState.success && couponCode ? couponCode : "";
    }

    const getCalculatedTax = ():number => {
        return ((props.amount - getCalculatedDiscount()) * 10)/100.0;
    }

    const getCalculatedDiscount = ():number => {
        return couponCodeDiscount != -1 ? (props.amount * couponCodeDiscount)/100.0 : 0;
    }

    const getTotalAmount = ():number => {
        return couponCodeDiscount != -1 ? (props.amount - getCalculatedDiscount()) + getCalculatedTax() : props.amount + getCalculatedTax();
    }

    useEffect(() => {
        if (finalPaymentState.success){
            handlePaymentModalClose();
            if (props.testId) {
                navigate(`${RoutesConstants.TestResult}/${props.testId}`);;
            }
            else if (props.productType == ProductType.Test) {
                navigate(RoutesConstants.Dashboard);
            }
            else {
                navigate(RoutesConstants.TrainerDashboard);
            }
        }
    }, [finalPaymentState.success])

    return(
        <>
            {props.showPaymentModalPopup && (
                <PaymentModal 
                    modalClosed={handlePaymentModalClose}
                    isLoading={finalPaymentState.inProgress} 
                    submitHandler={handlePaymentCardSubmit}
                    error={finalPaymentState.error}
                    // isLoading={addUpdateEventState.inProgress} 
                    />
            )}

            {finalPaymentState.inProgress && (
                <div className="modalOverlay">
                    <div className="processingContent">
                        <Spinner animation="border" role="status" />
                        <p>{finalPaymentState.status}</p>
                    </div>
                </div>
            )}
            <div className="dashboard-container">
                <div className="dashboard_heading">
                    <div className="container">
                    <h1 className="mb-0">
                        <FormattedMessage id="checkout" />
                    </h1>
                    </div>
                </div>
                <div className="dashboardTopbar">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-8">
                                <p className="text-dark">
                                    <b><FormattedMessage id="product"/></b>:&nbsp;
                                    {props.productType == ProductType.Test && <FormattedMessage id="test_payment" /> }
                                    {props.productType == ProductType.TrainerPlanRenew && <FormattedMessage id="trainer_dashboard_renew" /> }
                                    {props.productType == ProductType.TrainerRecharge10 && <><FormattedMessage id="trainer_invitation_recharge" />&nbsp;&nbsp;|&nbsp;&nbsp;<b><FormattedMessage id="test"/></b>:&nbsp;10</>}
                                    {props.productType == ProductType.TrainerRecharge25 && <><FormattedMessage id="trainer_invitation_recharge" />&nbsp;&nbsp;|&nbsp;&nbsp;<b><FormattedMessage id="test"/></b>:&nbsp;25</>}
                                    {props.productType == ProductType.TrainerRecharge50 && <><FormattedMessage id="trainer_invitation_recharge" />&nbsp;&nbsp;|&nbsp;&nbsp;<b><FormattedMessage id="test"/></b>:&nbsp;50</>}
                                    {props.productType == ProductType.TrainerRecharge100 && <><FormattedMessage id="trainer_invitation_recharge" />&nbsp;&nbsp;|&nbsp;&nbsp;<b><FormattedMessage id="test"/></b>:&nbsp;100</>}
                                    {props.productType == ProductType.TrainerRecharge150 && <><FormattedMessage id="trainer_invitation_recharge" />&nbsp;&nbsp;|&nbsp;&nbsp;<b><FormattedMessage id="test"/></b>:&nbsp;150</>}
                                    {props.productType == ProductType.TrainerRecharge200 && <><FormattedMessage id="trainer_invitation_recharge" />&nbsp;&nbsp;|&nbsp;&nbsp;<b><FormattedMessage id="test"/></b>:&nbsp;200</>}
                                    {props.productType == ProductType.TrainerRecharge300 && <><FormattedMessage id="trainer_invitation_recharge" />&nbsp;&nbsp;|&nbsp;&nbsp;<b><FormattedMessage id="test"/></b>:&nbsp;300</>}
                                    {props.productType == ProductType.TrainerRecharge500 && <><FormattedMessage id="trainer_invitation_recharge" />&nbsp;&nbsp;|&nbsp;&nbsp;<b><FormattedMessage id="test"/></b>:&nbsp;500</>}
                                    {props.productType == ProductType.TrainerRecharge800 && <><FormattedMessage id="trainer_invitation_recharge" />&nbsp;&nbsp;|&nbsp;&nbsp;<b><FormattedMessage id="test"/></b>:&nbsp;800</>}
                                    {props.productType == ProductType.TrainerRecharge1000 && <><FormattedMessage id="trainer_invitation_recharge" />&nbsp;&nbsp;|&nbsp;&nbsp;<b><FormattedMessage id="test"/></b>:&nbsp;1000</>}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container row my-4 py-4 m-auto">
                    <div className="justify-content-center col-md-8">
                        <div className="d-flex justify-content-between">
                            <p><FormattedMessage id="amount"/></p>
                            <p className="text-end text-dark">
                                <i className='fa fa-yen-sign'></i>&nbsp;{props.amount}
                            </p>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between"> 
                            <p><FormattedMessage id="service_tax" /></p>
                            <p className="col-md-6 text-end text-dark">
                                <span className="text-muted">(10%)</span>&nbsp;&nbsp;
                                <i className='fa fa-yen-sign'></i>&nbsp;{getCalculatedTax()}
                            </p>
                        </div>

                        <div className="py-3 row">
                            <div className="col-md-6 py-3">
                                <div className="input-group">
                                    {
                                      couponCodeDiscountState.success 
                                      ?
                                        <div style={{ position: 'relative' }}>
                                        <Button
                                          variant="outline-primary"
                                          style={{
                                            borderRadius: '50px',
                                            position: 'relative',
                                            paddingRight: '2.5rem',
                                          }}
                                          onClick={() => {
                                            setCouponCode("");
                                            resetDiscountState();
                                            resetPaymentState();
                                          }}
                                        >
                                          {couponCode}
                                          <FontAwesomeIcon
                                            icon={faTimesCircle}
                                            style={{
                                              position: 'absolute',
                                              top: '50%',
                                              transform: 'translateY(-50%)',
                                              right: '10px',
                                            }}
                                          />
                                        </Button>
                                      </div>
                                    :
                                        <>
                                            <Form.Control
                                            min={0}
                                            type="text"
                                            id='couponCode'
                                            value={couponCode}
                                            placeholder={formatMessage('coupon_code_placeholder')}
                                            onChange={(e: any) => setCouponCode(e.target.value)}
                                            />
                                            <LoaderButton 
                                                type="button" 
                                                onClick={() => {couponCode && getCouponCodeDiscount(couponCode)}}
                                                className="btn btn-primary input-group-text btn btn-primary"
                                                isLoading={couponCodeDiscountState.inProgress}
                                                text="apply" />
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 py-3">
                                <div className="bg-light p-3 mb-3">
                                    <div className="d-flex justify-content-between">
                                        <p><FormattedMessage id="coupon_discount_text" /></p>
                                        <p className="text-end text-dark">
                                            <strong>{couponCodeDiscount != -1 
                                                ? 
                                                <>
                                                    <span className="fw-normal text-muted">({couponCodeDiscount}% OFF)</span>&nbsp;
                                                    <i className='fa fa-yen-sign'></i>&nbsp;
                                                    {getCalculatedDiscount()}
                                                </> 
                                                : 
                                                    "N/A"
                                                }
                                            </strong>
                                        </p>
                                    </div>
                                    <hr />
                                    <div className="d-flex justify-content-between">
                                        <p>
                                            <strong className="text-dark">
                                                <FormattedMessage id="total_amount" />
                                            </strong>
                                        </p>
                                        <p className="text-end text-dark">
                                            <strong className="text-dark">
                                                <i className='fa fa-yen-sign'></i>&nbsp;{getTotalAmount()}
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                                <div className="d-grid g-1">
                                    <Button
                                        size="lg"
                                        className="p-3 text-uppercase fw-bold"
                                        onClick={handlePaymentModalOpen}>
                                        <FormattedMessage id="proceedAndPay"/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 pr-0">
                        {/* <h3 className="section-small-title">
                            <FormattedMessage id="Summary" />
                        </h3> */}
                        <div className="paymentCardSummary">
                            <p>
                                <FormattedMessage id="bank_detail_1"/>
                            </p>
                            <p className='text-dark my-3' style={{"whiteSpace": "pre-line"}}>
                                <FormattedMessage id="bank_detail_2"/>
                            </p>
                            <p>
                                <FormattedMessage id="bank_detail_3"/>
                            </p>
                            <Button size="lg" variant='secondary' className='mt-3' onClick={() => navigate(RoutesConstants.ContactUs)}>
                                <FormattedMessage id="send_message"/>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PaymentModalHOC(observer(PaymentPage));