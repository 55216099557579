/*    
<summary>
   This file defines all axios servies that we are using throughout the application. 
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
*/
import * as axios from '../interceptor/interceptor';

let config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

const uploadConfig = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

export const getRequest = (url: string) => {
    return axios.baseAPI.get(url, config)
};

export const postRequest = (url: string, data: any) => {
    return axios.baseAPI.post(url, data, config)
};

export const putRequest = (url: string, data: any) => {
    return axios.baseAPI.put(url, data, config)
};

export const deleteRequest = (url: string) => {
    return axios.baseAPI.delete(url, config)
};

export const patchRequest = (url: string) => {
    return axios.baseAPI.patch(url, config)
};

export const patchRequestWithData = (url: string, data: any) => {
    return axios.baseAPI.patch(url, data, config)
};

export const postUpload = (url: string, data: any) => {
    return axios.baseAPI.post(url, data, uploadConfig);
};
