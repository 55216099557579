import React, { useState } from "react";
const OpenTrainerPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showOpenTrainerPopup, setShowOpenTrainerPopup] = useState<boolean>(false);

    const openTrainerToggleHandler = () => {
        setShowOpenTrainerPopup(!showOpenTrainerPopup);
    };

    return (
      <Component
      showOpenTrainerPopup={showOpenTrainerPopup}
      openTrainerToggleHandler={openTrainerToggleHandler}
        {...props}
      />
    );
  };

export default OpenTrainerPopupHOC;
