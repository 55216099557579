import { IGetAllTrainerInfo, IGetTrainerInvitiesCount, ISentTrainerInvities, ITrainerInfo } from "../../models/response/ITrainerResponse";
import { formatMessage } from "../../translations/format-message";


export const initialState: IGetAllTrainerInfo = {
  PagingDetails:{
    TotalRecords: 1,
    TotalPages: 1,
    PageNo: 1,
    PageSize: 10,
},
  TrainerList: [],
};

export const getTrainerInvitiesIdState: ITrainerInfo = {
  InvitesId: 0,
  UserId: 0,
  GroupId: 0,
  GroupName: '',
  RecipientEmail: '',
  TestId: 0,
  TestDate: '',
  CreatedDate: '',
  UpdatedDate: '',
  EmailText: '',
  Note: '',
  TestType: '',
  ResultVisible: true,
  TestLink: '',
  UserName: '',
  RepeatTestCount: 0,
};

export const postSentTrainerInvitiesState =(): ISentTrainerInvities=> ({

  InvitiesId: 0,
  RecipientUserEmail: [],
  Message: "",
  GroupName: "",
  TestType: "",
  IsResultVisible: true,
  Title: formatMessage('trainer_title')

});

export const  getTrainerInvitiesCount: IGetTrainerInvitiesCount = {
  TrainerInvites: 0,
  SubscriptionExpiryDate: "",
  IsValidSubscriptionDate: true
}