import React, { useState } from "react";

const CreateStatHOC = (Component: any) =>
  function Comp(props: any) {
    const [showCreateStatPopup, setShowCreateStatPopup] = useState<boolean>(false);
    const createStatToggleHandler = () => {
      setShowCreateStatPopup(!showCreateStatPopup);
    };

    return (
      <Component
        showCreateStatPopup={showCreateStatPopup}
        createStatToggleHandler={createStatToggleHandler}
        {...props}
      />
    );
  };

export default CreateStatHOC;
