/*    
<summary>
   This file is used to provide initial values or state to the SendEventMail Form.
</summary>
*/

import { ISendEventMail } from "../../models/Form/ISendEventMail";
import { initialState } from "./add-event-state";

export const initialSendEventMailState: ISendEventMail = {
    ...initialState,
    EventId : -1,
    RecipientUserId:[],
    RecipientUserEmail:[],
    MessageTitle:'',
    MessageBody:'',
 }