import logo from '../../assets/Images/logo_original.png';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { injectIntl } from 'react-intl';

const AboutTrainers = (props: any) => {
    return (
        <div className="banner--light breadcrumb-area">
            <div className="container">
                <h1 className="page-title">The Trainers</h1>
            </div>
        </div>
)
}
export default AboutTrainers;