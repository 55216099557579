import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import RoutesConstants from "../../../../shared-components/Routes/routes.constants";
import { useStore } from "../../../../contexts/StoreProvider";
import { formatMessage } from "../../../../translations/format-message";
import toast, { Toaster } from "react-hot-toast";
import { observer } from "mobx-react";
import AddEditGroupForm from "../../../../shared-components/AddEditGroupForm/AddEditGroupForm";
import trainerStore from "../../../../core/stores/trainer-store";

interface IProps {
  modalClosed: () => void;
  isLoading: boolean;
}
export enum FormTypeEnum{
  Create,
  Edit
}
const EditGroupPopup = (props: IProps) => {
  const { trainerStore } = useStore();
  const { addUpdateGroupState, resetAddUpdateGroup} = trainerStore;

  useEffect(() => {
    if (addUpdateGroupState.success) {
      resetAddUpdateGroup();
      props.modalClosed();
    }
  }, [addUpdateGroupState.success]);


  return (
    <AddEditGroupForm FormType={FormTypeEnum.Edit} modalClosed={props.modalClosed} isLoading={props.isLoading} />
  );
};

export default observer(EditGroupPopup);
