import React, { useEffect } from "react";
import { observer } from "mobx-react";
import AddEditCouponForm, { FormTypeEnum } from "../AddEditCouponForm/AddEditCouponForm";
import { ICoupon } from "../../../../models/ICoupon";
import { useStore } from "../../../../contexts/StoreProvider";
import toast from "react-hot-toast";
import { formatMessage } from "../../../../translations/format-message";

interface IProps {
    modalClosed: () => void;
    isLoading: boolean;
}

const EditCouponPopup = (props: IProps) => {
    const { adminStore } = useStore();
    const {addUpdateCouponState, deleteCouponState, resetAddUpdateCoupon, resetDeleteCoupon}  = adminStore;

    useEffect(() => {
        if (addUpdateCouponState.success) {
          resetAddUpdateCoupon();
          props.modalClosed();
        }
      }, [addUpdateCouponState.success]);
    
    useEffect(() => {
        if(deleteCouponState.success){
            resetDeleteCoupon();
            props.modalClosed();
        }
    }, [deleteCouponState.success]);

    return (
        <AddEditCouponForm modalClosed={props.modalClosed} FormType={FormTypeEnum.Edit} isLoading={props.isLoading}/>
    );
};

export default observer(EditCouponPopup);
