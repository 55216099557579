import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite"
import React, { useEffect, useState  } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import { IFigures } from "../../../models/response/IFigures";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { CreatorValidateSchema, UpdateFiguredValidationSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/Label/Label";
import Editor from 'react-simple-wysiwyg';
import FormikEditorInput from "../../../shared-components/FormikFormHandlers/FormikEditorInput";
import { ICreator } from "../../../models/ICreatorInfo";
import { Box, CircularProgress } from "@mui/material";


interface ICreatorToFileMap {
    id: number;
    file: string;
    actualFile: File | undefined;
}

const CreatorSettings = (props: any) => {
    const { adminStore } = useStore();
    const { getCreatorsInfo, getCreatorInfoState, allCreators, updateCreatorInfo, updateCreatorInfoState, uploadCreatorImage, uploadCreatorImageState
     } = adminStore;

    const [files, setFiles] = useState<ICreatorToFileMap[]>([]);
  
    
    useEffect(() => {
        getCreatorsInfo();
    }, []);

    useEffect(() => {
        if (updateCreatorInfoState.success) {
            getCreatorsInfo();
        }
    }, [updateCreatorInfoState.success]);

    useEffect(() => {
        if (allCreators && allCreators.length > 0) {
            const filesMap: ICreatorToFileMap[] = [];
            allCreators.map((creator: ICreator) => {
                const temp:ICreatorToFileMap = { id: creator.Id, file: '', actualFile: undefined };
                filesMap.push(temp);
            })
            setFiles([...filesMap]);
        }
    }, [allCreators]);

    useEffect(() => {
        if (uploadCreatorImageState.success) {
            getCreatorsInfo();
            const filesMap: ICreatorToFileMap[] = [];
            files.map((x) => {
                x.file = '';
                x.actualFile = undefined;
            })
            setFiles([...filesMap]);
            uploadCreatorImageState.success = false;
        }
    }, [uploadCreatorImageState.success]);


    const  handleFileSelection = async(e: any, id: number) => {
        if (e.currentTarget.files && e.currentTarget.files.length > 0) {
            const creatorMap = files?.find(x => x.id == id);
            if (creatorMap) {
                creatorMap.file = URL.createObjectURL(e.currentTarget.files[0]);
                creatorMap.actualFile = e.currentTarget.files[0];
            }
            setFiles([...files])
        }
    }

    const handleUpload = (id: number) => {
        uploadCreatorImage(id, files.find(x => x.id == id)?.actualFile)
    }
    
    return (
        <>
        {
            getCreatorInfoState.inProgress || (!getCreatorInfoState.success && getCreatorInfoState.error === '' ) 
            ?
            <>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px'}}>
                    <CircularProgress disableShrink={true}/>
                </Box>
            </>
            :
        
        allCreators && allCreators.length > 0 ?
            allCreators.map((creator: ICreator) => {
                return (
                    <>
                        <Formik
                            initialValues={creator}
                            validationSchema={CreatorValidateSchema}
                            validateOnBlur={false}
                            onSubmit={async (values: ICreator) => {
                                updateCreatorInfo(values);
                            }}
                        >
                            <Form className="">
                                <span className="section-small-title">{creator.CreatorName}</span>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 col-md-5 col-12 position-relative">
                                        <div className="position-sticky top-0">
                                            <div className="edit_profile_wrapper">
                                                <img src={files?.find(x => x.id == creator.Id)?.file == '' ? `data:image/png;base64, ${creator.CreatorImageInfo}` : files?.find(x => x.id == creator.Id)?.file} className=''/>                                            
                                                <input type="file" className="edit_profile_btn" accept="image/*" onChange={(e) => handleFileSelection(e, creator.Id)}/>
                                            </div>
                                            {
                                                <LoaderButton type="button" variant="primary" className={` ${files?.find(x => x.id == creator.Id)?.file == '' ? 'd-none' : '' }`} isLoading={uploadCreatorImageState.inProgress} text="Upload" disabled={files?.find(x => x.id == creator.Id)?.file == ''} onClick={() => handleUpload(creator.Id)} />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-xl-9 col-lg-8 col-md-7 col-12"> 
                                        <div className="row">
                                            <div className="col-md-4 col-12 form-group">
                                                {/* <label><FormattedMessage id="name" /></label>
                                                <input className="form-control" type="text" /> */}
                                                <Label
                                                    required={true}
                                                    label="name"
                                                    htmlFor="FirstName"
                                                />
                                                <Field
                                                    data-testid="name"
                                                    className="form-control"
                                                    name='CreatorName'
                                                    type="text"
                                                    placeholder="name"
                                                    validcharacter={[
                                                    ]}
                                                    as={FormikFormInput}
                                                />
                                            </div>
                                            <div className="col-md-4 col-12 form-group">
                                                <Label
                                                    required={true}
                                                    label="mail_link"
                                                    htmlFor="Email_LinK"
                                                />
                                                <Field
                                                    data-testid=""
                                                    className="form-control"
                                                    name='MailId'
                                                    type="text"
                                                    invalidcharacter={[" ", "@"]}
                                                    placeholder="mail_link"
                                                    validcharacter={[
                                                    ]}
                                                    as={FormikFormInput}
                                                />
                                            </div>
                                            <div className="col-md-4 col-12 form-group">
                                                <Label
                                                    required={true}
                                                    label="linkedin_link"
                                                    htmlFor="Email_LinK"
                                                />
                                                <Field
                                                    data-testid=""
                                                    className="form-control"
                                                    name='LinkedInURL'
                                                    type="text"
                                                    invalidcharacter={[" ", "@"]}
                                                    placeholder="linkedin_link"
                                                    validcharacter={[
                                                    ]}
                                                    as={FormikFormInput}
                                                />

                                            </div>
                                            
                                            <div className="col-12 form-group">
                                                <Label
                                                    required={true}
                                                    label="detail"
                                                    htmlFor="detail"
                                                />
                                                <Field
                                                    id="Id" 
                                                    name="CreatorDesc_EN"
                                                    className="form-control"
                                                    as={FormikEditorInput} 
                                                />
                                            </div>
                                            <div className="col-12 form-group">
                                                <Label
                                                    required={true}
                                                    label="detail_jp"
                                                    htmlFor="detail"
                                                />
                                                <Field
                                                    id="Id"
                                                    name="CreatorDesc_JP"
                                                    as={FormikEditorInput} 
                                                />
                                            </div>
                                            <div className="col-12 form-group">
                                                <Label
                                                    required={true}
                                                    label="detail_it"
                                                    htmlFor="detail"
                                                />
                                                <Field
                                                    id="Id"
                                                    name="CreatorDesc_IT"
                                                    as={FormikEditorInput} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <LoaderButton
                                                type="submit"
                                                className="btn btn-primary btn-user"
                                                isLoading={updateCreatorInfoState.inProgress}
                                                text="submit"
                                            />
                                            {/* <button type="submit" className="btn btn-primary"><FormattedMessage id="submit" /></button> */}
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                        <hr/>
                    </>
                )
            })
            :
            <>
            </>
        }
        </>
    );
}
export default observer(CreatorSettings);