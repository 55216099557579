import { ICoupon } from "../../models/ICoupon";

export const CouponState: ICoupon = {
    id:0,
    couponName: "",
    couponCode: "",
    discount: 0,
    status: "ON",
    CouponCount: 0,
    ValidUpto: new Date().toISOString(), 
};