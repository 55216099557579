import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import IAddSendFriendRequest from "../../../models/Form/IAddSendFriendRequest";
import { SentFriendRequestValidationSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { Field, Form, Formik } from "formik";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import { FormattedMessage } from "react-intl";
import FormikMultiEmailField from "../../../shared-components/FormikFormHandlers/FormikMultiEmailField";

interface IProps {
    initialValues: IAddSendFriendRequest;
    submitHandler: (data: IAddSendFriendRequest) => void;
    modalClosed: () => void;
    isLoading: boolean;
}

const InviteFriendForm = (props: IProps) => {
    const { initialValues, submitHandler} = props;
    return (
        <>
            <Modal show={true} centered
                onHide={props.modalClosed}
                backdrop="static"
                scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h1><FormattedMessage id="Friend_Request_Invite" /></h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={SentFriendRequestValidationSchema}
                        enableReinitialize
                        validateOnBlur={false}
                        onSubmit={async (values: IAddSendFriendRequest) => {
                            // console.log("values", values);
                            submitHandler(values);
                        }}
                        render={({ values, handleBlur, handleChange, errors, touched }) => {
                            return (
                                <Form id="sentInvitiesForm" className="modal-form-SendInvities">
                                    <div className='form-group' >
                                        <label className='fw-bold'><FormattedMessage id="Friend_Request_Email" /></label>
                                        <Field
                                            data-testid="RequestEmailIds"
                                            name="RequestEmailIds"
                                            as={FormikMultiEmailField}
                                        />
                                    </div>
                                    <div className="alert alert-info p-2 text-center">
                                        <span className=" fs-6">
                                            <i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;<FormattedMessage id="Friend_Request_Text" />
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-end ">
                                        <div className="row">
                                            <div>
                                                <LoaderButton
                                                    type="submit"
                                                    id="SentInvities"
                                                    className="btnOkSize"
                                                    form="sentInvitiesForm"
                                                    isLoading={props.isLoading}
                                                    text={"Invite now"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    >
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default observer(InviteFriendForm);