import { IGetAllTest } from "../../models/response/IAdminResponse";

export const initialState: IGetAllTest = {
    PagingDetails:{
        TotalRecords: 1,
        TotalPages: 1,
        PageNo: 1,
        PageSize: 10,
    },
    TestList: [],
    PaidTestLeft: 0
};