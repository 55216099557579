import { Field, Form, Formik } from "formik";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { observer } from "mobx-react";
import Label from "../../../shared-components/Label/Label";
import { FormattedMessage } from "react-intl";
import { IStatState } from "../../../models/state/IStatState";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import { genderOptions, jobSectorOptions, workPostionOptions,nationOptions } from "../../registration/Registration";

interface IProps {
    modalClosed: () => void;
    FormType: FormTypeEnum,
    submitHandler: (data: IStatState) => void;
    initialValues: any
    EditStatId: any
    isLoading:boolean;
}

export enum FormTypeEnum {
    Create,
    Edit
}
// export const genderOptions = [
//     { id: "", value: "--Select--", disabled: true },
//     { id: "Male", value: "Male" },
//     { id: "Female", value: "Female" },
//     { id: "Non Specificato", value: "Non Specificato" },
// ];
export const AgeOptions=[
    { id: "", value: "please_select", disabled: true },
    { id: "NOT_AVAILABLE", value: "N/A" },
    { id: "0-5", value: "0-5" },
    { id: "5-10", value: "5-10" },
    { id: "11-15", value: "11-15" },
    { id: "16-20", value: "16-20" },
    { id: "21-25", value: "21-25" },
    { id: "26-30", value: "26-30" },
    { id: "31-35", value: "31-35" },
    { id: "36-40", value: "36-40" }, 
    { id: "41-45", value: "41-45" },
    { id: "46-50", value: "46-50" },
    { id: "51-55", value: "51-55" },
    { id: "56-60", value: "56-60" },
    { id: "61-65", value: "61-65" },
    { id: "66-70", value: "66-70" },
    { id: "71-75", value: "71-75" },
    { id: "76-80", value: "76-80" }, 
    { id: "81-85", value: "81-85" },
    { id: "86-90", value: "86-90" }, 
    { id: "91-95", value: "91-95" },
    { id: "96-100", value: "96-100" }, 
]

export const test_types = [
    { id: "", value: "please_select", disabled: true },
    { id: "Life", value: "Life" },
    { id: "Job", value: "Job" },
    { id: "Training", value: "Training" },
    { id: "Racing", value: "Racing" },
];

export const invities = [
    { id: "", value: "please_select", disabled: true },
    { id: "Personal", value: "Personal" },
    { id: "Trainer", value: "Trainer" }
];

const AddEditStatForm = (props: IProps) => {
    const allCharactersExceptNumbers = Array.from("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+-=~`[]{}|;:.<>?/1234567890")
    .filter(char => !/\d/.test(char));
  
    // Function to convert a comma-separated string to an array of integers
    const convertToIntArray = (csvString: string) => {
        if (!csvString) {
        // Return an empty array if the string is null, undefined, or empty
        return [];
        }
    
        // Split the string by commas, trim spaces, convert to numbers, and filter out NaN values
        return csvString.toString()
        .split(',')
        .map(item => item.trim())           // Trim any extra spaces
        .filter(item => item.length > 0)   // Filter out empty strings
        .map(Number)                        // Convert to numbers
        .filter(num => !isNaN(num));       // Remove any NaN values resulting from invalid conversion
    }
    return (
        <>
            <Modal show={true} centered
                onHide={props.modalClosed}
                backdrop="static"
                className="large-modal"
                scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {(props.FormType === FormTypeEnum.Create) ? <FormattedMessage id="stat_Add" /> : <FormattedMessage id="Edit_Stat" />}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={props.initialValues}
                        enableReinitialize
                        validateOnBlur={false}
                        onSubmit={async (values: IStatState) => {
                            const testIds = convertToIntArray(values.TestIds);
                            if (testIds.length == 0)
                                values.TestIds = "";
                            if (props.FormType === FormTypeEnum.Create) {
                                props.submitHandler(values)
                            }
                            else if (props.FormType === FormTypeEnum.Edit) {
                                props.submitHandler(values)
                            }
                        }}
                    >
                        {({ values, errors, handleChange, handleBlur, touched }) => (
                            <>
                                <Form id="addEditStatForm" className="mw-100">
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-6 col-12 form-group">
                                            <Label
                                                label="nation"
                                                htmlFor="Nation" />
                                            <Field
                                                data-testid="Nation"
                                                className="form-control"
                                                name="Nation"
                                                type="text"
                                                invalidcharacter={[" "]}
                                                placeholder="Nation"
                                                options={nationOptions}
                                                as={FormikFormSelect} />
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-12 form-group">
                                            <Label
                                                label="gender"
                                                htmlFor="Gender" />
                                            <Field
                                                data-testid="Gender"
                                                className="form-control"
                                                name="Gender"
                                                type="text"
                                                invalidcharacter={[" "]}
                                                placeholder="Gender"
                                                options={genderOptions}
                                                as={FormikFormSelect} />
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-12 form-group">
                                            <Label
                                                label="test_type"
                                                htmlFor="type" />
                                            <Field
                                                data-testid="type"
                                                className="form-control"
                                                name="Type"
                                                type="text"
                                                placeholder="type"
                                                options={test_types}
                                                as={FormikFormSelect} />
                                        </div>
                                        {/* <div className="col-xl-4 col-lg-6 col-12 form-group">
                                            <Label
                                                label="Invited"
                                                htmlFor="Invited" />
                                            <Field
                                                data-testid="Invited"
                                                className="form-control"
                                                name="Invited"
                                                type="text"
                                                invalidcharacter={[" "]}
                                                placeholder="Invited"
                                                options={invities}
                                                as={FormikFormSelect} />
                                        </div> */}
                                        <div className="col-xl-4 col-lg-6 col-12 form-group">
                                            <Label
                                                label="stat_TestIds"
                                                htmlFor="TestIds" />
                                            <Field
                                                data-testid="TestIds"
                                                className="form-control"
                                                name="TestIds"
                                                type="text"
                                                placeholder="stat_TestIds"
                                                invalidcharacter={allCharactersExceptNumbers}
                                                validcharacter={[
                                                    ',',
                                                    '+',
                                                ]}
                                                as={FormikFormInput} />
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-12 form-group">
                                            <Label
                                                label="Workposition"
                                                htmlFor="WorkPositionType" />
                                            <Field
                                                data-testid="WorkPositionType"
                                                className="form-control"
                                                name="WorkPositionType"
                                                type="text"
                                                invalidcharacter={[" "]}
                                                placeholder="WorkPositionType"
                                                options={workPostionOptions}
                                                as={FormikFormSelect}
                                            />
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-12 form-group">
                                            <Label
                                                label="JobSector"
                                                htmlFor="JobSectorTpye" />
                                            <Field
                                                data-testid="JobSectorTpye"
                                                className="form-control"
                                                name="JobSectorTpye"
                                                type="text"
                                                invalidcharacter={[" "]}
                                                placeholder="JobSectorTpye"
                                                options={jobSectorOptions}
                                                as={FormikFormSelect} />
                                        </div>

                                        <div className="col-xl-4 col-lg-6 col-12 form-group">
                                            <Label
                                                label="stat_AgeRange"
                                                htmlFor="AgeRange" />
                                            <Field
                                                data-testid="AgeRange"
                                                className="form-control"
                                                name="AgeRange"
                                                type="text"
                                                invalidcharacter={[" "]}
                                                placeholder="AgeRange"
                                                options={AgeOptions}
                                                as={FormikFormSelect} />
                                        </div>
                                        {/* <div className="col-xl-4 col-lg-6 col-12 form-group">
                                            <Label
                                                label="DOB1"
                                                htmlFor="DOB1"
                                            />
                                            <Field
                                                data-testid="DOB1"
                                                className="form-control"
                                                name="DOB1"
                                                type="number"
                                                max={2023}
                                                min={1940}
                                                placeholder="DOB1"
                                                as={FormikFormInput} />
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-12 form-group">
                                            <Label
                                                label="DOB2"
                                                htmlFor="DOB1"
                                            />
                                            <Field
                                                data-testid="DOB2"
                                                className="form-control"
                                                name="DOB2"
                                                max={2023}
                                                min={1940}
                                                type="number"
                                                placeholder="DOB2"
                                                as={FormikFormInput} />
                                        </div> */}
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end ">
                        <Button
                            data-testid="Cancel"
                            variant="secondary"
                            disabled={props.isLoading}
                            onClick={props.modalClosed}
                            className="btnOkSize me-2">
                            <FormattedMessage id="button_cancel" />
                        </Button>
                        {(props.FormType == FormTypeEnum.Create)
                            ?
                            <LoaderButton
                                type="submit"
                                id="RegisterButton"
                                form="addEditStatForm"
                                isLoading={props.isLoading}
                                className="btnOkSize"
                                text="submit"
                            />
                            :
                            <LoaderButton
                                type="submit"
                                id="UpdateButton"
                                form="addEditStatForm"
                                className="btnOkSize"
                                text="update"
                            />
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default observer(AddEditStatForm)
