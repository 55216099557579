/*    
<summary>
  This functional component logouts the uder from user application & redirects the user to login component.
  Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import { useStore } from "../../contexts/StoreProvider";
import { IAuthState } from "../../models/state/IAuthState";

const Logout = () => {
  const { logout }: IAuthState = useStore().authStore;

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Navigate to={RoutesConstants.Login} />;
};

export default Logout;
