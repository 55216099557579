/*    
<summary>
   This functional component StoreProvider is used to provide Store to all its children component and useStore hook for accessing all
   stores functions and observables.
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns StoreProvider</returns>
<returns>Returns useStore (hook for accessing the store)</returns>
*/
import React from 'react';
import { useLocalObservable } from 'mobx-react';
import rootStore, { RootStore } from '../core/stores';

const storeContext = React.createContext<RootStore | null>(null);

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
    const store = useLocalObservable(() => rootStore);
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
}

export const useStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('useStore must be used within a StoreProvider.');
    }
    return store;
}