import React, { useEffect, useState } from 'react'
import { useStore } from '../../../contexts/StoreProvider';
import { observer } from 'mobx-react';
import { formatMessage } from '../../../translations/format-message';
import toast from 'react-hot-toast';
import RepeatForwardMailInfoForm from './RepeatForwardMailInfoForm';
import ConfirmPopupHOC from '../../../shared-components/confirmationPopup/hoc/confirmation-popup-hoc';
import ConfirmPopup from '../../../shared-components/confirmationPopup/confirm-popup';

interface IProps {
    confirmToggleHandler: () => void;
    showConfirmPopup: React.JSX.Element;
    modalClosed: () => void;
    openTrainerId: number;
}

export const ResultTypes = [
    { id: "1", value: "Result_visible" },
    { id: "0", value: "Result_invisible" },
];
const RepeatForwardMailInfo = (props: IProps) => {

    const { trainerStore } = useStore();
    const { getTrainerInvitiesInfo, getAllGroups, getAllTrainerInvities,
        forwardEmailState,getTrainerInvities,resetForwardEmail,
        TrainerInvities, ForwardEmail,trainerPaginationState,trainerGroupPaginationState } = trainerStore;

    const [storingValue, setstoringValue] = useState('')
    const [isRepeatTest, setIsRepeatTest] = useState(false)
    useEffect(() => {
        getTrainerInvitiesInfo();
        getTrainerInvities(Number(props.openTrainerId));
        getAllGroups();
    }, [props.openTrainerId]);

    useEffect(() => {
        if (TrainerInvities.ResultVisible) {
            TrainerInvities.ResultVisible = ResultTypes[0].id
        } else {
            TrainerInvities.ResultVisible = ResultTypes[1].id
        }
    }, [TrainerInvities])

    const handleModalClose = () => {
        props.modalClosed();
    }

    useEffect(() => {
        if (forwardEmailState.success) {
            props.modalClosed()
            // toast.success(formatMessage("Trainer_ForwardEmail"));
            // sentTrainerInviteState.success = !sentTrainerInviteState.success
            resetForwardEmail();
            getAllTrainerInvities(trainerPaginationState.PagingDetails.PageNo,trainerPaginationState.PagingDetails.PageSize,)
        }
    }, [forwardEmailState.success]);

    const onSubmitForm = (values: any, isRepeatTest: boolean) => {
        props.confirmToggleHandler()
        setstoringValue(values)
        setIsRepeatTest(isRepeatTest)
    };

    const confirmFormSubmitHandler = () => {
        ForwardEmail(storingValue,isRepeatTest)
    };


    return (
        <>
            {props.showConfirmPopup && (
                <ConfirmPopup
                    title={isRepeatTest ? "Repeat Test" : "Forward Email"}
                    modalSubmit={confirmFormSubmitHandler}
                    modalClosed={props.confirmToggleHandler}
                    message="Are you sure ?"
                    dialogClassName="confirm_popup"
                    isLoading={forwardEmailState.inProgress}
                />
            )}
            <RepeatForwardMailInfoForm
                initialValues={TrainerInvities}
                submitHandler={onSubmitForm}
                modalClosed={handleModalClose}
                ResultTypes={ResultTypes}
            />
        </>
    )
}
export default ConfirmPopupHOC(observer(RepeatForwardMailInfo));