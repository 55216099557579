import { IGetAllLabels } from "../../models/response/IAdminResponse";

export const initialState: IGetAllLabels = {
  PagingDetails:{
    TotalRecords: 1,
    TotalPages: 1,
    PageNo: 1,
    PageSize: 10,
},
  LabelsList: [],
  };