import React, { useState } from "react";
const EditGroupPopupHOC = (Component: any) =>

  function Comp(props: any) {
    const [showEditGroupPopup, setShowEditGroupPopup] = useState<boolean>(false);

    const editGroupToggleHandler = () => {
      setShowEditGroupPopup(!showEditGroupPopup);
    };

    return (
      <Component
        showEditGroupPopup={showEditGroupPopup}
        editGroupPopupToggleHandler={editGroupToggleHandler}
        {...props}
      />
    );
  };

export default EditGroupPopupHOC;
