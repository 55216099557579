import React from 'react';
import { FormattedMessage } from 'react-intl';
import Pagination from '../Grid/Pagination';
import { pageLimitOptions } from '../../constants/options.constant';

export interface ICustomPaginationProps {
    onPageLimitChanged: (evt: any) => void;
    pageSize: number;
    isRefreshPage?: boolean;
    totalPages: number;
    onPageChanged: (pageNumber: number) => void;
    disablePagination: boolean
}

const CustomPagination = ({onPageLimitChanged, pageSize, isRefreshPage, totalPages, onPageChanged, disablePagination} : ICustomPaginationProps) => {
    return (
        <>
            <ul className="d-flex flex-column flex-md-row justify-content-between mt-4">
                <li className="page-count-no mb-2">
                    <FormattedMessage id="showing_result_for" />
                        <select
                            data-testid="pageSelect"
                            className="pageLimit ms-2"
                            onChange={onPageLimitChanged}
                            value={pageSize}
                        >
                            {pageLimitOptions.map((op: any) => (
                                <option key={op} value={op}>
                                    {op}
                                </option>
                            ))}
                        </select>
                </li>
                <li className="page-number">
                    <Pagination
                        isRefreshPage={isRefreshPage}
                        totalPages={totalPages}
                        pageLimit={pageSize}
                        onPageChanged={onPageChanged}
                        disablePagination={disablePagination}
                    />
                </li>
            </ul>
        </>
    )
};

export default CustomPagination;