import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import SendEmail_Form from "./SendEmail_Form";
import { useStore } from "../../../contexts/StoreProvider";
import { ISentTrainerInvities } from "../../../models/response/ITrainerResponse";
import { postSentTrainerInvitiesState } from "../../../core/initial-state/get-all-trainer-state";
import { ITrainerGroup } from "../../../models/response/ITrainerGroupResponse";

interface IProps {
    modalClosed: () => void;
    isLoading: boolean;
}

const SendEmail = (props: IProps) => {
    const { trainerStore } = useStore();
    const { sentTrainerInvites, getAllTrainerInvities, sentTrainerInviteState, resetSentTrainerInvites,  getAllGroups, allTrainerGroups, getTrainerInvitiesInfo, allTrainerInvitiesCount,trainerPaginationState,trainerGroupPaginationState } = trainerStore;
    const [TrainerGroups, setTrainerGroups] = useState([{ id: "", "value": "please_select" }])

    useEffect(() => {
        getAllGroups()
        getTrainerInvitiesInfo()
    }, []);

    useEffect(() => {
        if (sentTrainerInviteState.success) {
            props.modalClosed();
            resetSentTrainerInvites();
            getAllTrainerInvities(trainerPaginationState.PagingDetails.PageNo,trainerPaginationState.PagingDetails.PageSize,)
            getTrainerInvitiesInfo();
        }
    }, [sentTrainerInviteState.success]);


    useEffect(() => {
        let changingKey: any = allTrainerGroups.map((key: ITrainerGroup) => {
            return { id: key.GroupId, value: key.GroupName }
        })
        if (TrainerGroups.length == 1) {
            setTrainerGroups((oldValues) => {
                return [...oldValues, ...changingKey];
            })
        }
    }, [allTrainerGroups])

    const handleSubmit = (values: ISentTrainerInvities) => {
        sentTrainerInvites(values);
    };

    return (
        <>
            <SendEmail_Form
                initialValues={postSentTrainerInvitiesState()}
                submitHandler={handleSubmit}
                allTrainerGroups={TrainerGroups}
                modalClosed={props.modalClosed}
                addSentTrainerInvitiesState={sentTrainerInviteState}
                allTrainerInvitiesCount={allTrainerInvitiesCount}
                isLoading={props.isLoading}
            />
        </>
    )
}

export default observer(SendEmail);