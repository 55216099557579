import React, { useEffect, useState } from 'react'
import { useStore } from '../../../contexts/StoreProvider';
import { observer } from 'mobx-react';
import IAddSendFriendRequest from '../../../models/Form/IAddSendFriendRequest';
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import LoaderButton from '../../../shared-components/Button/LoaderButton';
import { formatMessage } from '../../../translations/format-message';
import RoutesConstants from '../../../shared-components/Routes/routes.constants';


interface IProps {
    modalClosed: () => void;
    isLoading: boolean;
}


const LoginRegisterPopup = (props: IProps) => {
    return (
        <>
            <Modal show={true} centered onHide={()=>{}}>
                <Modal.Header>
                        <h2><FormattedMessage id={'see_result_popup_title'} /></h2>
                        <button onClick={props.modalClosed} disabled={props.isLoading} className="Crossicons" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                        </button>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <div className='btn-group'>
                        <button className='btn btn-primary p-3' onClick={() => window.location.href = `${RoutesConstants.Login}?continue=${encodeURIComponent(window.location.href)}`}>
                            <i className="fa fa-user-check text-white me-3" ></i>
                            <FormattedMessage id='login_message' />
                        </button>
                        <button className='btn btn-secondary p-3' onClick={() => window.location.href = `${RoutesConstants.Registration}?continue=${encodeURIComponent(window.location.href)}`}>
                            <i className="fa fa-user-plus text-white me-3" ></i>
                            <FormattedMessage id='register_message' />
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default observer(LoginRegisterPopup);