import React, { useState } from 'react'
import RechargeTable from './RechargeTable';
import { Col, Card, Button, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';
import RoutesConstants from '../../../shared-components/Routes/routes.constants';
import { ProductType } from '../../../constants/enum';
const RechargePlan = () => {
    const navigate = useNavigate();
    const [Total, setTotal] = useState<number>()

    const data = [
        { mail: 10, price: "18,000", productType: ProductType.TrainerRecharge10 },
        { mail: 25, price: "42,500", productType: ProductType.TrainerRecharge25 },
        { mail: 50, price: "80,000", productType: ProductType.TrainerRecharge50 },
        { mail: 100, price: "150,000", productType: ProductType.TrainerRecharge100 },
        { mail: 150, price: "210,000", productType: ProductType.TrainerRecharge150 },
        { mail: 200, price: "260,000", productType: ProductType.TrainerRecharge200 },
        { mail: 300, price: "360,000", productType: ProductType.TrainerRecharge300 },
        { mail: 500, price: "550,000", productType: ProductType.TrainerRecharge500 },
        { mail: 800, price: "800,000", productType: ProductType.TrainerRecharge800 },
        { mail: 1000, price: "900,000", productType: ProductType.TrainerRecharge1000 }
    ];

    return (
        <>
            <div className="dashboar-container  ">
                <div className="dashboard_heading">
                    <div className="container">
                        <h1>
                            <FormattedMessage id="trainer_invitation_recharge" />
                        </h1>
                    </div>
                </div>
                <div className="dashboardTopbar">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-8">
                                <p className="text-dark"><FormattedMessage id="Choose_Your_Recharge" /></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <Row>
                        <Col md="8">
                            <RechargeTable data={data} setTotal={setTotal} />
                            {/* <div className=' bg-light p-3'>
                            <Row className='align-items-center'>
                                <Col md="8">
                                    <strong>Total</strong></Col>
                                <Col md="4" className='text-end'>
                                    <strong className='text-dark'>{Total}</strong>
                                    <button className="btn btn-primary ms-3 btn-lg" onClick={() => window.alert("payment successfull")}>Pay Now</button>
                                </Col>
                            </Row>
                            </div> */}
                        </Col>

                        <Col md="4">
                            {/* <h3 className="section-small-title"><FormattedMessage id="Summary" /></h3> */}
                            <div className="paymentCardSummary">
                                <p>
                                    <FormattedMessage id="bank_detail_1"/>
                                </p>
                                <p className='text-dark my-3' style={{"whiteSpace": "pre-line"}}>
                                    <FormattedMessage id="bank_detail_2"/>
                                </p>
                                <p>
                                    <FormattedMessage id="bank_detail_3"/>
                                </p>
                                <Button size="lg" variant='secondary' className='mt-3' onClick={() => navigate(RoutesConstants.ContactUs)}>
                                    <FormattedMessage id="send_message"/>
                                </Button>
                            </div>

                        </Col>
                    </Row>
                    
                </div>

            </div>


        </>
    )
}

export default RechargePlan
