import React, { useState } from "react";

const CreateEventPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showCreateEventPopup, setShowCreateEventPopup] = useState<boolean>(false);

    const createEventToggleHandler = () => {
      setShowCreateEventPopup(!showCreateEventPopup);
    };

    return (
      <Component
        showCreateEventPopup={showCreateEventPopup}
        createEventPopupToggleHandler={createEventToggleHandler}
        {...props}
      />
    );
  };

export default CreateEventPopupHOC;
