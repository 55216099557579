import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Label from './Label/Label'
import Users from './Users/Users'
import './AdminDashboard.css'
import { Tab, Tabs, Nav, TabContainer, TabContent } from "react-bootstrap";
import { useStore } from '../../contexts/StoreProvider'
import { observer } from 'mobx-react'
import CouponList from './Coupon/CouponList/CouponList'
import Test from './Test/Test'
import Threshold from './Threshold/Threshold'
import Transactions from './Transactions/Transactions'
import CurrencySettings from './settings/setting'
import FigureSettings from './settings/figureSettings';
import CreatorSettings from './settings/creatorSettings';
import Accordion from 'react-bootstrap/Accordion';

const AdminDashboard = (props: any) => {
    const { adminStore } = useStore();
    const { updateFilteredUserText } = adminStore;
    const [key, setKey] = useState<string>('Users');

    return (
        <>
            <div className='dashboar-container'>
                <div className="dashboard_heading">
                    <div className="container">
                        <h1>
                            <FormattedMessage id="admin_dashboard" />
                        </h1>
                    </div>
                </div>
                <div className="">
                    <Tab.Container
                        defaultActiveKey="Users"
                        activeKey={key}
                        onSelect={(k) => (k) ? setKey(k) : null}
                        id="uncontrolled-tab-example"
                    >
                        <div className="tabWrapper">
                            <div className="container">
                                <Nav variant="underline">
                                    <Nav.Item>
                                        <Nav.Link eventKey="Users"><FormattedMessage id="users" /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Test"><FormattedMessage id="test" /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Labels"><FormattedMessage id="labels" /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Transactions"><FormattedMessage id="transactions" /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Thresholds"><FormattedMessage id="threshold" /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Coupon"><FormattedMessage id="coupons" /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Settings"><FormattedMessage id="settings" /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="cms"><FormattedMessage id="content_setting" /></Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                        <Tab.Content>
                            <Tab.Pane eventKey="Users">
                                {key == "Users" &&
                                    <Users />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="Test" >
                                {key == "Test" &&
                                    <Test />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="Labels" >
                                {key == "Labels" &&
                                    <Label setUsersTab={updateFilteredUserText} />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="Transactions" >
                                {key == "Transactions" &&
                                    <Transactions />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="Thresholds" >
                                {key == "Thresholds" &&
                                    <Threshold />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="Coupon" >
                                {key == "Coupon" &&
                                    <CouponList />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="Settings" >
                                {key == "Settings" &&
                                    <CurrencySettings />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="cms" >
                                {key == "cms" &&
                                    <div className='container'>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header><FormattedMessage id="The_creators" /></Accordion.Header>
                                                <Accordion.Body>
                                                    <CreatorSettings />
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header><FormattedMessage id="Figures" /></Accordion.Header>
                                                <Accordion.Body>
                                                    <FigureSettings/>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>                                        
                                    </div>
                                }
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                </div>
            </div >
        </>
    )
}

export default observer(AdminDashboard);
