/*    
<summary>
   This file is used to provide initial values or state to the AddEditEventForm.
</summary>
*/

import { TestType } from "../../constants/enum";
import IAddEvent from "../../models/Form/IAddUpdateEvent";

export const initialState: IAddEvent = {
   UserId:-1,
   EventName:'',
   CreatedDate:'',
   UpdatedDate:'',
   TestType: TestType[TestType.LIFE],
   CouponCode:'',
   Note:'',
   DiscountPercentage:0
}
