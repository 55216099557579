import React, { useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import { Formik, Form, Field } from "formik";
import Label from "../../../../shared-components/Label/Label";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { ResetValidateSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import IForgotPassword from "../../../../models/IForgotPassword";
import IResetPassword from "../../../../models/Form/IResetPassword";
import { useParams, useSearchParams } from "react-router-dom";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";

interface IProps {
    resetPasswordHandler: (data: IResetPassword) => void;
    initialValues: IResetPassword;
    isLoading: boolean;
    resetPasswordToken: string | undefined;
}

const ResetPasswordForm = (props: IProps) => {
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    return (
        <>
        {props.resetPasswordToken &&
            <Formik
                initialValues={props.initialValues}
                validationSchema={ResetValidateSchema}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    if (props.resetPasswordToken) {
                        values.token = props.resetPasswordToken;
                        props.resetPasswordHandler(values);
                    }
                }}
            >
                {({ values, handleChange, handleBlur, errors }) => (
                    <Form className="login">
                        <div className="loginBox"  >
                            <div className="showHideOuterDiv form-group">
                                <Label
                                    required={true}
                                    label="new_password"
                                    htmlFor="newPassword"
                                />
                                <Field
                                    className="form-control"
                                    type={showNewPassword ? "text" : "password"}
                                    name="newPassword"
                                    invalidcharacter={[" "]}
                                    validcharacter={[
                                        "~",
                                        "`",
                                        "!",
                                        "@",
                                        "#",
                                        "$",
                                        "%",
                                        "^",
                                        "&",
                                        "*",
                                        "(",
                                        ")",
                                        "_",
                                        "-",
                                        "+",
                                        "=",
                                        "{",
                                        "[",
                                        "}",
                                        "]",
                                        "|",
                                        "\\",
                                        ":",
                                        ";",
                                        "'",
                                        '"',
                                        "<",
                                        ",",
                                        ">",
                                        ".",
                                        "?",
                                        "/",
                                    ]}
                                    validateOnChange={true}
                                    validateOnBlur={true}
                                    placeholder="new_password"
                                    as={FormikFormInput}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {showNewPassword ? (
                                    <svg
                                        type="button"
                                        onClick={() => {
                                            setShowNewPassword(!showNewPassword);
                                        }}
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className="showHidePass css-i6dzq1"
                                    >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                ) : (
                                    <svg
                                        type="button"
                                        onClick={() => {
                                            setShowNewPassword(!showNewPassword);
                                        }}
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="showHidePass css-i6dzq1"
                                    >
                                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                                        <line x1="1" y1="1" x2="23" y2="23"></line>
                                    </svg>
                                )}
                            </div>
                            <div className="showHideOuterDiv form-group">
                                <Label
                                    required={true}
                                    label="confirm_password"
                                    htmlFor="confirmpassword"
                                />
                                <Field
                                    data-testid="confirmpassword"
                                    className="form-control"
                                    name="confirmPassword"
                                    type={showConfirmPassword ? "text" : "password"}
                                    invalidcharacter={[" "]}
                                    validcharacter={[
                                        "~",
                                        "`",
                                        "!",
                                        "@",
                                        "#",
                                        "$",
                                        "%",
                                        "^",
                                        "&",
                                        "*",
                                        "(",
                                        ")",
                                        "_",
                                        "-",
                                        "+",
                                        "=",
                                        "{",
                                        "[",
                                        "}",
                                        "]",
                                        "|",
                                        "\\",
                                        ":",
                                        ";",
                                        "'",
                                        '"',
                                        "<",
                                        ",",
                                        ">",
                                        ".",
                                        "?",
                                        "/",
                                    ]}
                                    placeholder="confirm_password"
                                    as={FormikFormInput}
                                />
                                {showConfirmPassword ? (
                                    <svg
                                        type="button"
                                        onClick={() => {
                                            setShowConfirmPassword(!showConfirmPassword);
                                        }}
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className="showHidePass css-i6dzq1"
                                    >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                ) : (
                                    <svg
                                        type="button"
                                        onClick={() => {
                                            setShowConfirmPassword(!showConfirmPassword);
                                        }}
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="showHidePass css-i6dzq1"
                                    >
                                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                                        <line x1="1" y1="1" x2="23" y2="23"></line>
                                    </svg>
                                )}
                            </div>
                        </div>
                        <LoaderButton
                                type="submit"
                                id="ResetPasswordButton"
                                className="btn btn-primary btn-user"
                                isLoading={props.isLoading}
                                text="submit"
                            />
                    </Form>
                )}
            </Formik>
        }
        </>
    );
};

export default ResetPasswordForm;
