/*    
<summary>
   This functional common component provides us custom label tag that show the field is required or not. 
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary> 
<returns>Returns JSX</returns>
*/
import React from 'react';
import { FormattedMessage } from "react-intl";

interface IProps{
    required?:boolean;
    label:string;
    className?:string;
    htmlFor?:string;
}

const Label:React.FC<IProps> = (props)=>{
    return(
        <label className={props.className} htmlFor={props.htmlFor}>
            <FormattedMessage id={props.label} />
            { props.required && 
                <span className="text-danger"> *</span>
            }
          </label>
    )
}
export default Label;