import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import Layout from '../Layout/Layout';
import AuthProvider from '../../contexts/AuthProvider';

const StaticLayoutRoute: FC<any> = ({ component: Component, ...rest }) => {
  return (
    <Layout>
        <Component {...rest}/>
    </Layout>
  )
};

export default StaticLayoutRoute;  