import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import RoutesConstants from '../shared-components/Routes/routes.constants';
import { useStore } from './StoreProvider';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

interface IProps {
  children: React.ReactNode
}

const AgentProvider = (props: IProps) => {
  const navigate = useNavigate();
  const { authStore } = useStore();
  const { isAgent, getIsAgent } = authStore;

  useEffect(() => {
    
    // else if (!getToken || !isAuthenticated) {
    //   navigate(RoutesConstants.Login);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {(getIsAgent || isAgent) ? props.children : <Navigate to={RoutesConstants.Dashboard} />}
    </React.Fragment>
  )
}

export default observer(AgentProvider);