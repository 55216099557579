import RoutesConstants from '../../shared-components/Routes/routes.constants';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
// import PaymentCard from './PaymentCard';
import { observer } from 'mobx-react';
import PaymentPage from './PaymentPage';
import { ProductType } from '../../constants/enum';

const TrainerRechargePayment = () => {
    const { rechargeType } = useParams();
    const navigate = useNavigate();
    const [rechargeAmount, setRechargeAmount] = useState<number>(0);
    useEffect(() => {
        if (rechargeType && (parseInt(rechargeType) != ProductType.TrainerRecharge10 
            && parseInt(rechargeType) != ProductType.TrainerRecharge25
            && parseInt(rechargeType) != ProductType.TrainerRecharge50
            && parseInt(rechargeType) != ProductType.TrainerRecharge100
            && parseInt(rechargeType) != ProductType.TrainerRecharge150
            && parseInt(rechargeType) != ProductType.TrainerRecharge200
            && parseInt(rechargeType) != ProductType.TrainerRecharge300
            && parseInt(rechargeType) != ProductType.TrainerRecharge500
            && parseInt(rechargeType) != ProductType.TrainerRecharge800
            && parseInt(rechargeType) != ProductType.TrainerRecharge1000))
        {
            navigate(RoutesConstants.Dashboard);
        }
    }, [rechargeType])

    const getRechargeAmount = () => {
        if (rechargeType) {
            switch (parseInt(rechargeType)){
                case ProductType.TrainerRecharge10:
                    return 18000;
                case ProductType.TrainerRecharge25:
                    return 42500;
                case ProductType.TrainerRecharge50:
                    return 80000;
                case ProductType.TrainerRecharge100:
                    return 150000;
                case ProductType.TrainerRecharge150:
                    return 210000;
                case ProductType.TrainerRecharge200:
                    return 260000;
                case ProductType.TrainerRecharge300:
                    return 360000;
                case ProductType.TrainerRecharge500:
                    return 550000;
                case ProductType.TrainerRecharge800:
                    return 800000;
                case ProductType.TrainerRecharge1000:
                    return 900000;
            }
        }
    }
    return(
        <>
            <PaymentPage productType={rechargeType} amount={getRechargeAmount()}/>
        </>
    ) 
}
export default observer(TrainerRechargePayment);