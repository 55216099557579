import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import "./Login.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import { formatMessage } from "../../translations/format-message";
import { observer } from "mobx-react";
import { useStore } from "../../contexts/StoreProvider";
import Footer from "../../shared-components/Footer/Footer";
import ILogin from "../../models/ILogin";
import { ICommonState } from "../../models/state/ICommonState";
import { useCookies } from "react-cookie";
import { errorMessage } from "../../constants/error.constants";
import LoginForm from "./forms/LoginForm";
import Lottie from "react-lottie";
import monoZlogo from "../../assets/Images/Lottie/Simple APIs-2.json";
import TopNavBar from "../../shared-components/NavBar/TopNavBar";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import VerificationPopupHOC from "../registration/hoc/VerificationPopupHOC";
import VerificationModal, { OTPModalType } from "../registration/VerificationPopup/VerificationModal";

const css = `
body, html {
  overflow: visible;
  height: auto;
}
`;
const Login = (props: any) => {
  const { authStore, preferencesStore } = useStore();
  const lang = preferencesStore.getLanguage;
  const [searchParams] = useSearchParams();
  const [redirectUrl, setRedirectUrl] = useState<string|null>(searchParams.get('continue'));
  const { error, inProgress }: ICommonState = authStore;
  const { initialStateValue, isAuthenticated, token, getToken, login, resetPasswordState, resetResetPasswordState,
    otpGenerationOnLogin, otpGenerationState, resetOtpGenerationState, authenticateUser, otpVerificationState, resetOtpVerificationState
   } = authStore;
  const [cookies, setCookie, removeCookie] = useCookies(["user","token"]);
  const [resetPasswordStateValue, setResetPasswordStateValue] = useState(initialStateValue);
  const emailFromCookies = cookies.user;
  const tokenFromCookies = cookies.token;
  const initialValues: ILogin = {
    email: emailFromCookies !== undefined ? emailFromCookies : "",
    password: "",
    rememberMe: emailFromCookies !== undefined ? true : false,
  };
  const [loginValue, setLoginValue] = useState<ILogin>(initialValues);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: monoZlogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleLogin = (values: ILogin) => {
    if (values.rememberMe && values.email === emailFromCookies && tokenFromCookies) {
      authenticateUser(values.email, "", tokenFromCookies, values.password);
    }
    else {
      if (values.rememberMe === true) {
        var days = 1;
        var newDate = new Date(Date.now()+days*24*60*60*1000);
        setCookie("user", values.email, {expires: newDate});
        removeCookie("token");
      } 
      else {
        if (cookies.user != "") {
          removeCookie("user");
        }
        if (cookies.token != "") {
          removeCookie("token");
        }
      }
      setLoginValue({...values});
      otpGenerationHandler(values);
    }
  }


  const resetStateIfAny = () => {
    setResetPasswordStateValue(resetPasswordState);
    resetResetPasswordState();
    resetOtpVerificationState();
  }

  const otpModalCloseHandler = () => {
    resetOtpGenerationState();
    resetOtpVerificationState();
    props.verificationPopupToggleHandler();
  }

  const otpGenerationHandler = (values: ILogin | null = null) => {
    if (values?.email && values?.password) {
      otpGenerationOnLogin(values, lang)
    }
    else{
      otpGenerationOnLogin(loginValue, lang);
    }
    resetOtpVerificationState();
  }

  const otpVerificationHandler = (otp: string) => {
    resetOtpVerificationState();
    authenticateUser(loginValue.email, otp, "", "");
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated || getToken) {
      if (loginValue.rememberMe) {
        var days = 1;
        // var newDate = new Date(Date.now()+10000);
        var newDate = new Date(Date.now()+days*24*60*60*1000);
        // console.log(new Date(Date.now()))
        // console.log(newDate)
        setCookie("user", loginValue.email, {expires: newDate});
        setCookie("token", token, {expires: newDate});
      }
      if (redirectUrl != null && redirectUrl != "") {
        window.location.href = `${decodeURIComponent(redirectUrl)}`
      }
      else {
        navigate(RoutesConstants.Dashboard);
      }
    }
    resetStateIfAny();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (error) {
      error === errorMessage.UserAlreadyLogin
        ? props.confirmLoginPopupToggleHandler()
        : toast.error(formatMessage(error));
    }
  }, [error]);

  useEffect(() => {
    if (otpGenerationState.success && !props.showVerificationPopup) {
        props.verificationPopupToggleHandler();
        resetOtpGenerationState();
    }
  }, [otpGenerationState.success])

  /**
   * This useEffect for detect if User Authentication succcessfull then redirect to land page
   * (Developer:Deepak Paliwal,Date:10-March-2023)
   */
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate(RoutesConstants.Dashboard);
  //   }
  // }, [isAuthenticated]);

  return (
    <>
      <TopNavBar/>
      {props.showVerificationPopup && (
        <VerificationModal modalClosed={otpModalCloseHandler} isAddLoginUserLoading={inProgress}
            otpVerificationHandler={otpVerificationHandler} otpGenerationHandler={otpGenerationHandler} isOtpGenerationLoading={otpGenerationState.inProgress} 
            userEmail={loginValue.email} otpVerificationState={otpVerificationState} otpModalType={OTPModalType.Login}/>
      )}
        <div className="banner--light breadcrumb-area">
            <div className="container">
                <h1 className="page-title"><FormattedMessage id="login" /></h1>
            </div>
        </div>
      {/* <div className="bg-img1 text-left background"> */}
      <div className="bg-img1 text-left background" style={{ background: "white"}}>
        {/* <div className="login_wrapper">
          <img src={monozlogo} alt="mono_z_Logo" className="LightSmallLogo" />

          <Lottie options={defaultOptions} height={400} width={400} />
        </div> */}
        <LoginForm
          isLoading={otpGenerationState.inProgress && !props.showVerificationPopup}
          loginHandler={handleLogin}
          initialValues={initialValues}
          isResetPassword={resetPasswordStateValue.success}
          resetPasswordError={resetPasswordStateValue.error}
          redirectURL={redirectUrl}
        />
      </div>
      <Footer />
    </>
  );
};

export default VerificationPopupHOC(observer(Login));
