/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined constant.
   This constant is used in providing paths for API Route URL for every modules.
</summary>
*/
const URLConstants = {
    Authenticate: '/Account/Login',
    Logout: '/Account/Logout',
    LoginReset: '/Account/Login/Reset',
    RefreshToken: '/Account/Token',
    User: '/User',
    AddUser: '/Account/AddUser',
    GenerateOtpOnLogin: '/Account/GenerateOtpOnLogin',
    VerifyOTPOnLogin: '/Account/VerifyOTPAndLogin',
    GenerateOtpOnRegister: '/Account/GenerateOtpOnRegister',
    VerifyOTPOnRegister: '/Account/VerifyOTPOnRegister',
    GetUserDetail: '/Account/GetUserDetail',
    UpdateUserInfo: '/Account/UpdateUser',
    ForgotPassword: '/Account/EmailVerificationAndTokenGeneration',
    VerifyResetPasswordToken: '/Account/VerifyResetPasswordToken',
    ResetPassword: '/Account/ResetPassword',
    ChangePassword: '/Account/ChangePassword',
    SendFriendRequest: '/Account/SendFriendRequest',
    GetAllFriendRequest: '/Account/GetAllFriendRequest',
    UpdateStatus:'/Account/UpdateStatus',
    DeleteRequest:'/Account/DeleteRequest',
    DeleteFriends:'/Account/DeleteFriends',
    CancelRequest:'/Account/CancelRequest',
    ValidateReferer: 'Account/ValidateReferer',

    // Region : Code to be removed later.
    AddConfiguration: '/ConfigurationProtocol',
    UpdateConfiguration: '/ConfigurationProtocol',
    GetConfigurationById: '/ConfigurationProtocol',
    DeleteConfiguration: '/ConfigurationProtocol',
    AddBulkDevice: '/Device/Bulk',
    Device: '/Device',
    GetAllDevices: '/Device/List',
    GetAllDevicesByFilter: '/Device/List/Tags/Filter',
    GetAllDevicesCount: '/Device/Count',
    GetGroupById: '/Group',
    GetGroupInfo: '/Group/GroupInfo',
    AddGroup: '/Group',
    UpdateGroup: '/Group',
    GetAllGroups: '/Group/List',
    DeleteGroup: '/Group',
    GetConfigurations: '/ConfigurationProtocol/List',
    // End Region : Code to be removed later.

    // EVENTS
    GetAllEvents: '/Event/GetAllEvents',
    GetEventSummary: '/Event/GetEventSummary',
    ContactUsingEmail: '/Account/AddContactUsingMail',
    TrainerContactUs: '/Account/Contacts',
    CreateEvent: '/Event/CreateEvent',
    UpdateEvent: '/Event/UpdateEvent',
    SendMail: '/Event/SendMail',
    DeleteEvent: '/Event/DeleteEvent',
    Event: 'Event',
    ValidateCoupon: 'Event/ValidateCoupon',


    // ADMIN - Users
    GetAllUsers: '/Admin/AllUsers',
    CreateTrainerAccount: '/Admin/CreateTrainerAccount',
    UpdateUser: '/Admin/UpdateUser',
    DeleteUser: '/Admin/DeleteUser',
    LoginUser: '/Admin/LoginUser',
    GetAllTrainerList: 'Admin/GetAllTrainerData',
    // ADMIN - Lables
    GetAllLabels: '/Admin/GetAllLabelData',
    UpdateLabel: '/Admin/UpdateLabels',

    // ADMIN - Coupons
    GetAllCoupons: '/Admin/GetAllCouponData',
    UpdateCoupon: '/Admin/UpdateCouponData',
    createCoupon: '/Admin/CreateCoupon',
    DeleteCoupon: '/Admin/DeleteCoupon',

    //ADMIN - Threshold
    GetAllThreshold: '/Admin/GetThreshold',
    UpdateThreshold: '/Admin/UpdateThreshold',
    createThreshold: '/Admin/CreateThreshold',
    DeleteThreshold: '/Admin/DeleteThreshold',

    // Admin - Payment
    GetAllPaymentList: '/Admin/GetAllPaymentList',
    GetPaymentSettingsValue: '/Admin/GetPaymentSettingInfo',
    UpdatePaymentSettingsValue:'/Admin/UpdatePaymentSettingInfo',
    GetTestPrice: '/Admin/GetTestPrice',

    // TEST Layout
    TestLayout: '/TestLayout',

    //Test Info
    GetAllTest: '/TestLayout/GetAllTest',
    DeleteTest: '/TestLayout/DeleteTest',
    GetTestScore: '/TestLayout/GetTestScore',
    GetUserSpecificTests: '/TestLayout/GetUserSpecificTests',
    GetTestInterpretation: '/TestLayout/GetTestInterpretation',
    GetFreeTestInterpretation: '/TestLayout/GetFreeTestInterpretation',
    ValidateTestToken:'TestLayout/ValidateTestToken',

    //Trainer Info
    GetAllTrainerGroups: '/Trainer/GetAllTrainerGroups',
    CreateGroup: '/Trainer/CreateGroup',
    UpdateTrainerGroup: '/Trainer/UpdateTrainerGroup',
    DeleteTrainerGroup: '/Trainer/DeleteTrainerGroup',
    GetAllTrainerInvities: '/Trainer/GetAllTrainerInvities',
    GetTrainerInvitiesInfo: '/Trainer/GetTrainerInvitiesInfo',
    GetTrainerInvities: '/Trainer/GetTrainerInvities',
    createSentTrainerInvities: '/Trainer/SentTrainerInvities',
    DeleteTrainerInvities: '/Trainer/DeleteTrainerInvities',
    ForwardEmail: '/Trainer/ForwardEmail',
    UpdateTrainerInvitesInfo:'/Trainer/UpdateTrainerInvitesInfo',

    //Stats Data
    GetStatsData:'/TestLayout/GetStatsData',

    //Payment 
    PersonalPlanPayment: '/Payment/PersonalPlan',
    GetCouponCodeDiscount: '/Payment/GetCouponCodeDiscount',
    CaptureTransaction: (transactionId:string) => `/v1/transactions/${transactionId}:capture`,
    
    // Content Setting
    GetFiguresCount: 'Admin/GetFigureData',
    UpdateFigures: 'Admin/UpdateFigures',

    GetCreatorInfo: 'Admin/GetCreatorInfo',
    UpdateCreatorInfo: 'Admin/UpdateCreatorInfo', 
    UploadCreatorImageData: 'Admin/UploadCreatorImageData', 
}

export default URLConstants;