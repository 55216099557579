import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import Layout from '../Layout/Layout';
import AuthProvider from '../../contexts/AuthProvider';
import AgentProvider from '../../contexts/AgentProvider';

const AgentRoute: FC<any> = ({ component: Component, ...rest }) => {
  return (
      <Layout>
        <AuthProvider>
            <AgentProvider>
                <Component/>
            </AgentProvider>
        </AuthProvider>
      </Layout>
  )
};

export default AgentRoute;  