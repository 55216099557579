/*    
<summary>
  This functional component "DeletePopup" provides modal popup that shows Delete confirmation message (i.e. Are you sure 
  you want Delete?).
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./DeletePopup.css";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../../contexts/StoreProvider";
import { formatMessage } from "../../../../translations/format-message";
import { observer } from "mobx-react";
import toast from "react-hot-toast";

interface IProps {
  modalClosed: () => void;
  Id:number;
  Title:string;
  isLoading:boolean;
  deleteHandler:(id:number)=>void;
}

const DeletePopup = (props: IProps) => {

  const deleteCaller = () => {
    props.deleteHandler(props.Id);
  };
  return (
    <Modal
      show={true}
      centered
      onHide={props.modalClosed}
      dialogClassName="delete_popup "
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-break mt-4">
          {props.Title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <FormattedMessage id="delete_message" />
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 pb-3 pt-1">
        <Button
          data-testid="Cancel"
          variant="secondary"
          disabled={props.isLoading}
          onClick={props.modalClosed}
          className="btnOkSize"
        >
          <FormattedMessage id="button_cancel" />
        </Button>
        <Button
          variant="primary"
          data-testid="DeleteOk"
          onClick={deleteCaller}
          className="btnOkSize"
        >
          <FormattedMessage id="delete" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(DeletePopup);
