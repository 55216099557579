import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";
import { IFilterTest } from "../../../../models/ITestInfo";
import { useStore } from "../../../../contexts/StoreProvider";
import { ITestFilter, ITestFilterState } from "../../../../core/stores/admin-store";
import { observer } from "mobx-react";
import { cloneDeep, filter } from "lodash";

interface IProps {
    modalClosed: () => void;
    applyFilter: (testFilter: ITestFilter[]) => void;
}

const FilterTest = (props: IProps) => {
    const { adminStore } = useStore();
    const { testFilterState, testFilterStateValue, allTestFilter, updateFilterStateValue } = adminStore

    const [testFilter, setTestFilter] = useState<ITestFilter[]>([]);
    const handleApplyFilter = () => {
        props.applyFilter(testFilter);
    }

    const handleCancelFilter = () => {
        setTestFilter(cloneDeep(allTestFilter));
        props.modalClosed();
    }

    useEffect(() => {
        if (allTestFilter.length > 0) {
            setTestFilter(cloneDeep(allTestFilter));
        }
    },[]);

    const handleChecked = (heading: string, key: IFilterTest, e: any) => {
        const newTestFilter = testFilter.map((item: ITestFilter) => {
            if (item.heading == heading) {
                item.value.map((data: IFilterTest) => {
                    if (data === key) {
                      // console.log(data);
                      // console.log(key);
                      data.checked = !data.checked;
                      return;
                    }
                })
            }
        });
        setTestFilter(prev => [...testFilter]);
    }

    return (
        <>
            <Modal show={true} centered
                onHide={props.modalClosed}
                size="xl"
                backdrop="static"
                scrollable={true} className="modal-form " >
                <Modal.Header closeButton >
                    <Modal.Title style={{ marginLeft: "-10px" }}>
                        <FormattedMessage id="test_filter_titile" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    <div className="form-group">
                        <div className="row">
                            {
                                testFilter.map((item: ITestFilter) => {
                                    return <>
                                        <div className="col-4">
                                            <div className="row ">
                                                <div className="col-12 mb-3">
                                                    {
                                                        // hideAndShow ? <></> :
                                                            <><hr className="lines text-dark"></hr><h4 className="text-dark ">
                                                                {item.heading}</h4><hr className="lines"></hr></>
                                                    }
                                                </div>
                                                {/* {
                                                item.heading == "Trainer" ?
                                                    hideAndShow ?
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" placeholder="Search..." value={searchTerm}
                                                                onChange={(e) => searchText(e.target.value)} />
                                                            <button type="button" className="btn bg-transparent" style={{ marginLeft: "-40px", zIndex: "100" }} >
                                                                <i className="fa fa-times"></i>
                                                            </button>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="col-3">
                                                                <i className="fa fa-search fs-6" onClick={() => handleClick()}></i>
                                                            </div>
                                                        </>
                                                    : <></>
                                            } */}
                                            </div>
                                            <div className="multi_filter_wrap">
                                            {
                                                item.value.map((key: IFilterTest) => {
                                                    return <>
                                                        <div className="form-check ">
                                                            <input className="form-check-input" type="checkbox" id={`${item.heading}_${key.id}`} onChange={(e) => handleChecked(item.heading, key, e.target.checked)} checked={key.checked} />
                                                            <label className="form-check-label" htmlFor={`${item.heading}_${key.id}`} title={key.name}>{key.name}
                                                                {/* {
                                                                    (key.email != '') && (item.heading =='Trainer') &&(<span>({key.email})</span> ) 
                                                                } */}
                                                            </label>
                                                        </div>
                                                    </>
                                                })}
                                            </div>
                                        </div>
                                    </>
                                })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <LoaderButton
                        type="submit"
                        id="RegisterButton"
                        className="btnOkSize"
                        text="Transactions_Apply"
                        onClick={handleApplyFilter}
                    />
                    <LoaderButton
                        type="submit"
                        id="UpdateButton"
                        className="btnOkSize"
                        text="Cancel"
                        variant="secondary"
                        onClick={handleCancelFilter}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default observer(FilterTest);