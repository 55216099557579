/*    
<summary>
   This typescript file has very basic react component defined which can be replaced by our own root component
   Developer:Aashish Singh, Created Date:08-Mar-2023
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { useEffect } from "react";
import "./App.css";
import "./assets/styles/style.css";
import Body from "./shared-components/Body/Body";
// import "react-widgets/dist/css/react-widgets.css";
import "react-widgets/styles.css";
import "axios-progress-bar/dist/nprogress.css";
import * as axios from "./core/interceptor/interceptor";
import { observer } from "mobx-react";
import { useStore } from "./contexts/StoreProvider";
import toast, { Toaster } from "react-hot-toast";
const axiosProgressBar = require("axios-progress-bar");

//axiosProgressBar.loadProgressBar({ showSpinner: false }, axios.baseAPI);
const light = `:root {
  --white: #fff;
  --body-background: #eff1f5; 
  --secondary: #1C1C25;
  --light-secondary: #414141;
  --lightest-secondary: #666666;
  --black: #000000;
  --text-color: #7B7E86;
  --light-text: #868686;
  --dark-text: #141312;
  --label-color: #212121;
  --light-label: #424242;
  --border-color: #e6e6e6;
  --input-border: #E3E8F4;
  --card-border: #E3E8F4;
  --shadow-color: rgba(8, 20, 44, 0.09);
  --darker-gray: #121212;
  --gray: #a6a6a6;
  --light-gray: #dfdfdf;
  --lightest-gray: #f9f9f9;
  --accordian-bg: #ffffff;
  --accordian-border: #e1e1e1;  
}
`;

const dark = `:root {
  --white: #212121;
  --body-background: #1c1c1c; 
  --secondary: #2b2b2b;
  --light-secondary: #303030;
  --lightest-secondary: #3b3b3b;
  --black: #ffffff;
  --text-color: #efefef;
  --light-text: #a6a6a6;
  --dark-text: #828282;
  --label-color: #a6a6a6;
  --light-label: #707070;
  --border-color: #303030;
  --input-border: #3c3c3c;
  --card-border: #303030;
  --shadow-color: rgba(0,0,0,.03);
  --darker-gray: #121212;
  --gray: #7b7a7a;
  --light-gray: #434343;
  --lightest-gray: #252525;
  --accordian-bg: #343434;
  --accordian-border: #444444; 
}
.tableFooter .pageLimit,
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn.btn-primary,
.btn-secondary,
button#nav-toogle,
#left-sidebarNav nav ul li a {
	color: var(--black);
}
.aside-footer svg,
#left-sidebarNav .nav-inner svg {
	fill: var(--black);
}
.btn-outline-secondary {
	color: #dadadb;
	border-color: #a4a8ac;
}
.bg-light {
	background-color: #28292b !important;
}
.accordion-button::after {
	filter: invert(1);
}
`;
const App = () => {
  const { preferencesStore } = useStore();

  const { getTheme, theme, setUpdateTheme } = preferencesStore;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  return (
    <div className="App">
      <style>{getTheme == "true" ? dark : light}</style>
      <Body />
      <Toaster position="top-right" reverseOrder={true} />
    </div>
  );
};

export default observer(App);
