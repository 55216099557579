/*    
<summary>
   This file is used to provide initial values or state to the Registration Form.
</summary>
*/

import { IAddUser } from "../../models/Form/IAddUser";

export const initialState: IAddUser = {
    Id:-1,
    Email: '',
    FirstName: '',
    LastName: '',
    DateOfBirth: '',
    Gender: '',
    Nation: '',
    CountryOfResidence: '',
    Password: '',
    Education: '',
    WorkPosition: '',
    JobSector: '',
    Referent: '',
    SubscriptionExpiryDate: '',
    TrainerInvitesAvailable: 0,
    CouponCode: '',
    Privacy: false,
    CreatedDate: '',
    IsAgent: false
}
