import yourselfImage from '../../../assets/Images/yourself.jpg';
import relationImage from '../../../assets/Images/relationship.jpg';
import companiesImage from '../../../assets/Images/companies.jpg';
import coachesImage from '../../../assets/Images/coaches.jpg';
import athleteImage from '../../../assets/Images/athlete.jpg';
import React, { useEffect, useState } from 'react';
import './TestAim.css';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useStore } from '../../../contexts/StoreProvider';
import ReadMoreReadLess from './ReadMoreReadLess'; // Adjust the path as necessary
interface CardProps {
    imageSrc: any;
    subTitleId: string;
    titleId: string;
    textId: string;
  }
const Card: React.FC<CardProps> = ({ imageSrc, subTitleId, titleId, textId }) => (
    <div className="col-md-6 col-12">
      <div className="card--main">
        <div className="card--img">
          <img src={imageSrc} alt="" />
        </div>
        <div className="card--content">
          <div className="card--subTitle about-small-stitle">
            <FormattedMessage id={subTitleId} />
          </div>
          <div className="card--title">
            <FormattedMessage id={titleId} />
          </div>
          <ReadMoreReadLess messageId={textId} />
        </div>
      </div>
    </div>
  );
  

const TestAim = (props: any) => {
    const { adminStore } = useStore();
    const { getFiguresCount, allFigures} = adminStore;
    const cardsData = [
        {
          imageSrc: yourselfImage,
          subTitleId: 'Get_yourself_better',
          titleId: 'get_Yourself',
          textId: 'GYB_text',
        },
        {
          imageSrc: companiesImage,
          subTitleId: 'Create_synergy',
          titleId: 'Company_and_organisation',
          textId: 'CAO_text',
        },
        {
          imageSrc: relationImage,
          subTitleId: 'Live_better_relationship',
          titleId: 'For_relationships',
          textId: 'LBR_text',
        },
        {
          imageSrc: athleteImage,
          subTitleId: 'Improve_performance',
          titleId: 'Athletes',
          textId: 'P_text',
        },
        {
          imageSrc: coachesImage,
          subTitleId: 'Select_guide',
          titleId: 'Coaches_counsellors',
          textId: 'CC_text',
        },
        // Add more cards as needed
      ];
    useEffect(() => {
        getFiguresCount();
    }, []);
    return (
        <>
            <div className="banner--light breadcrumb-area">
                <div className="container">
                    <h1 className="page-title"><FormattedMessage id="What_test_aimed" /></h1>
                </div>
            </div>
            <section className='features_wrap'>
                <div className="container">
                    <div className="row cards--wrapper">
                    {cardsData.map((card, index) => (
                        <Card
                        key={index}
                        imageSrc={card.imageSrc}
                        subTitleId={card.subTitleId}
                        titleId={card.titleId}
                        textId={card.textId}
                        />
                    ))}
                    </div>
                </div>
            </section>
            <div className="figures_section">
                <div className="container">
                    <h1 className="section-title text-center text-white"><FormattedMessage id="Figures" /></h1>

                    <div className="row g-0 figures--wrapper">
                        <div className="col figure--main">
                            <strong className="figure--title">{allFigures.TestTakenCount}+</strong>
                            <p className="figure--content"><FormattedMessage id="people_Taken_test" /></p>
                        </div>

                        <div className="col figure--main">
                            <strong className="figure--title">{allFigures.CountriesCount}</strong>
                            <p className="figure--content"><FormattedMessage id="country_world" /></p>
                        </div>

                        <div className="col figure--main">
                            <strong className="figure--title">{allFigures.CompaniesCount}</strong>
                            <p className="figure--content"><FormattedMessage id="Companies" /></p>
                        </div>

                        <div className="col figure--main">
                            <strong className="figure--title">{allFigures.CoachesCount}</strong>
                            <p className="figure--content"><FormattedMessage id="Coach_counsellor" /></p>
                        </div>

                        <div className="col figure--main">
                            <strong className="figure--title">{allFigures.TrainersCount}</strong>
                            <p className="figure--content"><FormattedMessage id="Kaleidocompass_Trainer" /></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default observer(TestAim);