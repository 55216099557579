import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import checkIcon from "../../assets/Images/svg/check.svg";
import crossIcon from "../../assets/Images/svg/remove.svg";
import "./pricing.css"
import { Navigate, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fa1, fa3 } from "@fortawesome/free-solid-svg-icons";
import { useStore } from "../../contexts/StoreProvider";
import { observer } from "mobx-react";

// checkIcon =true
// crossIcon =false

const jsonData = [

    {
        'TITLE': "option_1",
        'FREE': 3,
        'PERSONAL': 13,
        'TRAINER': 13
    }, {
        'TITLE': "option_2",
        'FREE': true,
        'PERSONAL': true,
        'TRAINER': true
    },
    {
        'TITLE': "option_3",
        'FREE': true,
        'PERSONAL': true,
        'TRAINER': true
    },
    {
    //     'TITLE': "option_4",
    //     'FREE': false,
    //     'PERSONAL': true,
    //     'TRAINER': true
    // }, {
        'TITLE': "option_5",
        'FREE': false,
        'PERSONAL': true,
        'TRAINER': true
    }, {
    //     'TITLE': "option_6",
    //     'FREE': false,
    //     'PERSONAL': true,
    //     'TRAINER': true
    // }, {
        'TITLE': "option_7",
        'FREE': false,
        'PERSONAL': false,
        'TRAINER': true
    }, {
    //     'TITLE': "option_8",
    //     'FREE': false,
    //     'PERSONAL': false,
    //     'TRAINER': true
    // }, {
        'TITLE': "option_9",
        'FREE': false,
        'PERSONAL': false,
        'TRAINER': true
    }, {
        'TITLE': "option_10",
        'FREE': false,
        'PERSONAL': false,
        'TRAINER': true
    }, {
        'TITLE': "option_11",
        'FREE': false,
        'PERSONAL': false,
        'TRAINER': true
    }, {
        'TITLE': "option_12",
        'FREE': false,
        'PERSONAL': false,
        'TRAINER': true
    }, {
        'TITLE': "option_13",
        'FREE': false,
        'PERSONAL': false,
        'TRAINER': true
    }]

const Pricing = () => {
    const navigate = useNavigate();
    const isNumeric = (str:string) => /^[+-]?\d+(\.\d+)?$/.test(str);
    const { authStore, preferencesStore } = useStore();
    const { getTestPrice, testPriceInfo} = authStore;
    const lang = preferencesStore.getLanguage;
    useEffect(() => {
        getTestPrice();
    }, []);
    return (
        <>
            <div className="banner--light breadcrumb-area">
                <div className="container">
                    <h1 className="page-title"><FormattedMessage id="pricing" /></h1>
                </div>
            </div>
            <div className="container pb-5">
                <table className="table pricing-table">
                    <thead>
                        <tr>
                            <th scope="col" className="col-header"></th>
                            <th scope="col" className="col-header text-center"><FormattedMessage id="subscribtion_FREE" /></th>
                            <th scope="col" className="col-header text-center"><FormattedMessage id="subscribtion_PERSONAL" /></th>
                            <th scope="col" className="col-header text-center"><FormattedMessage id="subscribtion_TRAINER" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="subtitle">
                            <td></td>
                            <td className="text-center"><FormattedMessage id="free_subtitle" /></td>
                            <td className="text-center"><FormattedMessage id="personal_subtitle" /></td>
                            <td></td>
                        </tr>
                        <tr className="pricing_column">
                            <td></td>
                            <td className=" text-center"> <FormattedMessage id="free_pricing" /></td>
                            <td className={` text-center ${lang == "jp" ? 'd-flex flex-row-reverse justify-content-center' : ''}`}>
                                <span><FormattedMessage id="currency" /></span>
                                <span>{lang == "jp" ? testPriceInfo.JPY : lang == "en" ? testPriceInfo.USD : testPriceInfo.EUR}</span>
                            </td>
                            {
                                <td className="text-center"> 
                                    <button type="submit" className="btn btn-primary" onClick={() => navigate(RoutesConstants.ContactUs)}>
                                        <FormattedMessage id={"contact_us"} />
                                    </button>
                                </td>
                            }

                        </tr>
                        {
                            jsonData.map((data: any, index: number) => {
                                return <tr className="pricing-features" key={index}>
                                    <td className="pricing-features-title"><FormattedMessage id={data.TITLE} /></td>
                                    <td className=" text-center">{isNumeric(data.FREE)?<FontAwesomeIcon icon={fa3}/>:<img src={data.FREE ? checkIcon : crossIcon} alt={data.TRAINER ? "Yes" : "No"} className="pricecheck-icon" />}</td>
                                    <td className=" text-center">{isNumeric(data.PERSONAL)?<><FontAwesomeIcon icon={fa1}/><FontAwesomeIcon icon={fa3}/></>:<img src={data.PERSONAL ? checkIcon : crossIcon} alt={data.TRAINER ? "Yes" : "No"} className="pricecheck-icon" />}</td>
                                    <td className=" text-center">{isNumeric(data.TRAINER)?<><FontAwesomeIcon icon={fa1}/><FontAwesomeIcon icon={fa3}/></>:<img src={data.TRAINER ? checkIcon : crossIcon} alt={data.TRAINER ? "Yes" : "No"} className="pricecheck-icon" />}</td>
                                </tr>
                            })
                        }
                        {
                            localStorage.getItem("token") ?
                                <tr>
                                    <td></td>
                                    <td className=" text-center"> <button type="submit" className="btn btn-primary" onClick={() => navigate(RoutesConstants.Life)}><FormattedMessage id="Take_test" /></button></td>
                                    <td className=" text-center"><button type="submit" className="btn btn-primary" onClick={() => navigate(RoutesConstants.TestPayment)}>
                                        <FormattedMessage id="Buy_Now" /> <FormattedMessage id="currency" />{lang == "jp" ? testPriceInfo.JPY : lang == "en" ? testPriceInfo.USD : testPriceInfo.EUR}<span data-toggle="tooltip" data-placement="left" title="10% Extra consumption tax">*</span>
                                    </button> </td>
                                    <td className=" text-center"> <button type="submit" className="btn btn-primary" onClick={() => navigate(RoutesConstants.ContactUs)}><FormattedMessage id={"contact_us"} /></button></td>

                                </tr> :
                                <tr>
                                    <td></td>
                                    <td className=" text-center"> <button type="submit" className="btn btn-primary" onClick={() => navigate(RoutesConstants.Login)}><FormattedMessage id={"get_started"} /></button></td>
                                    <td className=" text-center"> <button type="submit" className="btn btn-primary" onClick={() => navigate(`${RoutesConstants.Login}?continue=${encodeURIComponent(window.location.origin + RoutesConstants.TestPayment)}`)}><FormattedMessage id={"get_started"} /></button></td>
                                    <td className=" text-center"> <button type="submit" className="btn btn-primary" onClick={() => navigate(RoutesConstants.Login)}><FormattedMessage id={"get_started"} /></button></td>
                                </tr>
                        }

                    </tbody>

                </table>
                {
                    localStorage.getItem("token") ?
                        <div className="mt-4">
                            <p><b>*</b><FormattedMessage id="pricing_disclaimer" /> </p>
                        </div> : null}
            </div >


        </>
    )
}

export default observer(Pricing);
