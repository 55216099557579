import { ProductType } from "../../constants/enum";
import { IPaymentSettingInfo, IPaymentSettingList } from "../../models/response/IPaymentSettingValue";

export const PaymentSettingsInfoState:IPaymentSettingList = {
    PaymentSettingList : [],
    PreferredCurrency: ''
}
export const SettingsInfoState:IPaymentSettingInfo = {
    Id : 0,
    JPY : 0,
    EUR : 0,
    USD : 0,
    ProductType : ProductType.Test
}