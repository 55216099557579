import { IGetAllTrainerInfo, IGetTrainerInvitiesCount, ISentTrainerInvities, ITrainerInfo, IUpdateTrainerInvities } from "../../models/response/ITrainerResponse";
import { ICommonState } from "../../models/state/ICommonState";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import { initialState as GetAllTrainerInvitiesState, getTrainerInvitiesIdState, getTrainerInvitiesCount } from '../../core/initial-state/get-all-trainer-state';
import * as baseService from '../service/base-service';
import { initialState as GetAllTrainerState } from '../../core/initial-state/get-all-trainer-state';
import { ITrainerState } from "../../models/state/ITrainerState";
import { initialState as GetAllTGroupList } from '../../core/initial-state/get-all-trainer-group-state';
import * as XLSX from 'xlsx';
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import toast from "react-hot-toast";
import { IGroupState } from "../../models/state/ITrainerState";
import IAddGroup from "../../models/Form/IAddUpdateGroup";
import { formatMessage } from "../../translations/format-message";
import { IGetAllTrainerGroup, ITrainerGroup } from "../../models/response/ITrainerGroupResponse";
import { initialState as GetAllTrainerGroupsState } from '../initial-state/get-all-trainer-group-state';
import { initialState as addGroupInitialState } from '../initial-state/add-group-state';
import URLConstants from "../../constants/url.constants";
import {initialPaginationStateValue} from "../../constants/options.constant"
import { IPagingDetails } from "../../models/response/IPagingDetails";
export class TainerStore implements ITrainerState, ICommonState {
    inProgress = false;
    error = '';
    initialStateValue = {
        success: false,
        error: '',
        inProgress: false
    }

    initialSearchStateValue = {
        searchText: '',
        filter: 0
    }
    getTrainerInvitiesCount = getTrainerInvitiesCount; 
    allTrainerInvities: IGetAllTrainerInfo = GetAllTrainerInvitiesState
    trainerDataList: IGetAllTrainerInfo = GetAllTrainerState;
    getTrainerInvitiesId = getTrainerInvitiesIdState;
    updateTrainerInviteState = { ...this.initialStateValue }
    sentTrainerInviteState = { ...this.initialStateValue }
    forwardEmailState = { ...this.initialStateValue }
    deleteTrainerInvitiesState = { ...this.initialStateValue }
    searchingTrainerInvitiesState = { ...this.initialSearchStateValue }
    searchingGroupState = { ...this.initialSearchStateValue }
    allGroupList: IGetAllTrainerGroup = GetAllTrainerGroupsState;
    groupList: IAddGroup = addGroupInitialState;
    intialPagingState :IPagingDetails = initialPaginationStateValue;
    getTrainerGroupListState = { ...this.initialStateValue }
    addUpdateGroupState = { ...this.initialStateValue }
    deleteGroupState = { ...this.initialStateValue }
    trainerPaginationState = {...this.intialPagingState}
    trainerGroupPaginationState = {...this.intialPagingState}
    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            getTrainerInvitiesId: observable,
            allTrainerInvities: observable,
            updateTrainerInviteState: observable,
            sentTrainerInviteState: observable,
            forwardEmailState:observable,
            allGroupList: observable,
            getTrainerInvitiesCount: observable,
            deleteTrainerInvitiesState: observable,
            searchingTrainerInvitiesState: observable,
            searchingGroupState: observable,
            addUpdateGroupState: observable,
            deleteGroupState: observable,
            trainerPaginationState:observable,
            trainerGroupPaginationState:observable,
            getAllGroups: action,
            resetAllTrainerGroups: action,
            addGroup: action,
            updateTrainerInvitesInfo: action,
            resetUpdateTrainerInvitesInfo: action,
            resetAddUpdateGroup: action,
            getAllTrainerInvities: action,
            getTrainerInvitiesInfo: action,
            getTrainerInvities: action,
            sentTrainerInvites: action,
            deleteTrainerInvities: action,
            resetSentTrainerInvites: action,
            resetTrainerInvities: action,
            searchingTrainerInvitiesText: action,
            FilteredGroupName: action,
            downloadFile:action,
            ForwardEmail: action,
            allTrainerlist: computed,
            allTrainerGroups: computed,
            allTrainerInvitiesCount: computed,
            TrainerInvities: computed,
        });
    }


    getAllTrainerInvities = (currentPage:number,pagerSize:number,textToBeSearch?: string | null, filter?: string | null | number) => {
        this.inProgress = true;
        var url = URLConstants.GetAllTrainerInvities + "?invitiesId=" + filter + '&PageNo=' + currentPage + '&PageSize=' + pagerSize;
        if (textToBeSearch != null) {
            url += "&textToBeSearched=" + textToBeSearch;
        }
        if (filter != null) {
            url += "&filteredByGroup=" + filter;
        }
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGetAllTrainerInfo>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allTrainerInvities = response.data.Data;
                    this.trainerPaginationState.PagingDetails = response.data.Data.PagingDetails;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

    getTrainerInvities = (InvitesId: number) => {
        const url = URLConstants.GetTrainerInvities + "?invitiesId=" + InvitesId;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITrainerInfo>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.getTrainerInvitiesId = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));

    }


    /*
        Function to get all Groups List.
    */
    getAllGroups = (currentPage:number = -1, pagerSize:number = -1) => {
        const url = URLConstants.GetAllTrainerGroups + '?PageSize=' + pagerSize  + '&PageNo=' + currentPage;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGetAllTrainerGroup>>) => {
                // console.log(response);
                // console.log("success");
                this.getTrainerGroupListState.success = true;
                this.allGroupList = response.data.Data;
                if (currentPage != -1 && pagerSize != -1) {
                    this.trainerGroupPaginationState.PagingDetails = response.data.Data.PagingDetails;
                }// console.log(response.data.Data);
            })
            .catch((err: string) => {
                this.getTrainerGroupListState.success = false;
                this.getTrainerGroupListState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => {
                this.getTrainerGroupListState.inProgress = false;
            }));
    };

    resetAllTrainerGroups = () => {
        this.allGroupList = GetAllTrainerGroupsState;
    }
    
 /* 
  function to update page size
  */
  updateGroupPageSize =(value:number,isRefreshPage:boolean = false)=>{
    this.trainerGroupPaginationState.PagingDetails.PageSize = value;
    this.trainerGroupPaginationState.PagingDetails.isPagerChange = true;
    if(isRefreshPage)
    {
      this.trainerGroupPaginationState.PagingDetails.isRefreshPage = true;
    }
  }

/* 
function to update current page number
*/
  updateGroupPageNumber =(value:number,isRefreshPage:boolean = false)=>{
    this.trainerGroupPaginationState.PagingDetails.PageNo = value;
    this.trainerGroupPaginationState.PagingDetails.isPagerChange = true;
    if(isRefreshPage)
    {
      this.trainerGroupPaginationState.PagingDetails.isRefreshPage = true;
    }
  }
  resetGroupPagingState = ()=>{
      this.trainerGroupPaginationState.PagingDetails.isPagerChange = false;
      this.trainerGroupPaginationState.PagingDetails.isRefreshPage = false;
  }
    
 /* 
  function to update page size
  */
    updatePageSize =(value:number,isRefreshPage:boolean = false)=>{
      this.trainerPaginationState.PagingDetails.PageSize = value;
      this.trainerPaginationState.PagingDetails.isPagerChange = true;
      if(isRefreshPage)
      {
        this.trainerPaginationState.PagingDetails.isRefreshPage = true;
      }
    }

 /* 
 function to update current page number
 */
    updatePageNumber =(value:number,isRefreshPage:boolean = false)=>{
      this.trainerPaginationState.PagingDetails.PageNo = value;
      this.trainerPaginationState.PagingDetails.isPagerChange = true;
      if(isRefreshPage)
      {
        this.trainerPaginationState.PagingDetails.isRefreshPage = true;
      }
    }
    resetPagingState = ()=>{
        this.trainerPaginationState.PagingDetails.isPagerChange = false;
        this.trainerPaginationState.PagingDetails.isRefreshPage = false;
    }


    getTrainerInvitiesInfo = () => {
        var url = URLConstants.GetTrainerInvitiesInfo
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGetTrainerInvitiesCount>>) => {
                this.getTrainerInvitiesCount = response.data.Data
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    };

    sentTrainerInvites = (TrainerInvites: any) => {
        const lang: any = localStorage.getItem("language")
        const data = {
            "InvitiesId": TrainerInvites.InvitiesId,
            "RecipientUserEmail": TrainerInvites.RecipientUserEmail,
            "Message": TrainerInvites.Message,
            "GroupName": TrainerInvites.GroupName,
            "TestType": Number(TrainerInvites.TestType),
            "IsResultVisible": TrainerInvites.IsResultVisible,
            "Language": lang,
            "Title": TrainerInvites.Title
        }
        this.sentTrainerInviteState.inProgress = true;
        return baseService.postRequest(URLConstants.createSentTrainerInvities, data)
            .then((response: IApiResponse<IApiSuccessResponse<ISentTrainerInvities>>) => {
                if (response.data.Error) {
                    this.sentTrainerInviteState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.sentTrainerInviteState.success = true;
                    toast.success(formatMessage("sent_success"));
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                this.sentTrainerInviteState.error = err;
            })
            .finally(action(() => { this.sentTrainerInviteState.inProgress = false; }));
    }


    /*
      Function to update the trainer invites Info
    */
    updateTrainerInvitesInfo = (InvitesInfo: IUpdateTrainerInvities) => {
        this.updateTrainerInviteState.inProgress = true;
        var url = URLConstants.UpdateTrainerInvitesInfo;
        const data = {
            Note: InvitesInfo.Note,
            GroupName: InvitesInfo.GroupName == "0" ? "" : InvitesInfo.GroupName,
            InvitesId: InvitesInfo.InvitesId
        }
        return baseService.putRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.updateTrainerInviteState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.updateTrainerInviteState.success = true;
                    toast.success(formatMessage('updated_success'));
                }
            })
            .catch((err: string) => {
                // console.log(err);
                toast.error(formatMessage(err));
            })
            .finally(
                action(() => {
                    this.updateTrainerInviteState.inProgress = false;
                })
            );
    };

    resetUpdateTrainerInvitesInfo = () => {
        this.updateTrainerInviteState = {...this.initialStateValue};
    }

    deleteTrainerInvities = (InvitesId: number) => {
        this.deleteTrainerInvitiesState.inProgress = true;
        const url = URLConstants.DeleteTrainerInvities + "?invitiesId=" + InvitesId;
        return baseService.deleteRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteTrainerInvitiesState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteTrainerInvitiesState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteTrainerInvitiesState.inProgress = false; }));
    }

    ForwardEmail = (MailInfo: any, IsRepeatTest:boolean) => {
        this.forwardEmailState.inProgress = true;
        var url = URLConstants.ForwardEmail;
        const data = {
            InvitiesId: MailInfo.InvitiesId,
            IsResultVisible: MailInfo.IsResultVisible,
            IsReapeatTest:IsRepeatTest
        }
        return baseService.putRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.forwardEmailState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.forwardEmailState.success = true;
                    toast.success(formatMessage('sent_success'));
                }
            })
            .catch((err: string) => {
                // console.log(err);
                toast.error(formatMessage(err));
            })
            .finally(
                action(() => {
                    this.forwardEmailState.inProgress = false;
                })
            );
    };

    resetForwardEmail=()=>{
        this.forwardEmailState = { ...this.initialStateValue }
    }

    /*
        Function to add/create new Group.
    */
    addGroup = (Groups: IAddGroup) => {
        this.addUpdateGroupState.inProgress = true;
        const url = URLConstants.CreateGroup;
        const data = {
            groupName: Groups.GroupName?.trim()
        };
        return baseService.postRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                //this.allGroupList.TrainerGroupList.push(response.data.Data);
                this.addUpdateGroupState.success = true;
                toast.success(formatMessage("added_success"));
            })
            .catch((err: string) => {
                // console.log(err);
                this.addUpdateGroupState.error = err;
                toast.error(formatMessage(err));
                return null;
            })
            .finally(
                action(() => {
                    this.addUpdateGroupState.inProgress = false;
                })
            );
    }

    searchingTrainerInvitiesText = (value: string) => {
        this.searchingTrainerInvitiesState.searchText = value;
    }

    FilteredGroupName = (value: number) => {
        this.searchingTrainerInvitiesState.filter = value;
    }

    searchingGroupText = (value: string) => {
        this.searchingGroupState.searchText = value;
    }

    resetSentTrainerInvites = () => {
        this.getTrainerInvitiesId = getTrainerInvitiesIdState;
        this.sentTrainerInviteState = { ...this.initialStateValue }
    }

    resetTrainerInvities = () => {
        this.deleteTrainerInvitiesState = { ...this.initialStateValue }
    }

    resetAddUpdateGroup = () => {
        this.addUpdateGroupState = { ...this.initialStateValue }
        this.groupList = addGroupInitialState;
    }

    resetDeleteGroup = () => {
        this.deleteGroupState = { ...this.initialStateValue }
    }

    resetDeleteInvitesState = () => {
        this.deleteTrainerInvitiesState = { ...this.initialStateValue }
    }

    get allTrainerlist(): ITrainerInfo[] {
        if (this.allTrainerInvities?.TrainerList && this.allTrainerInvities.TrainerList.length > 0) {
            // const allEventsSortedData = toJS(this.allTrainerInvities?.TrainerList).sort((a, b) => Number(a.CreatedDate) - Number(b.CreatedDate));
            return this.allTrainerInvities?.TrainerList.map((trainer) => {
                return {

                    InvitesId: trainer?.InvitesId,
                    UserId: trainer.UserId,
                    GroupId: trainer.GroupId,
                    GroupName: trainer.GroupName,
                    RecipientEmail: trainer.RecipientEmail,
                    TestId: trainer.TestId,
                    TestDate: this.getNormalisedDate(trainer.TestDate),
                    CreatedDate: this.getNormalisedDate(trainer.CreatedDate),
                    UpdatedDate: trainer.UpdatedDate,
                    EmailText: trainer.EmailText,
                    Note: trainer.Note,
                    TestType: trainer.TestType,
                    ResultVisible: trainer.ResultVisible,
                    TestLink: trainer.TestLink,
                    UserName: trainer.UserName,
                    RepeatTestCount: trainer.RepeatTestCount
                }

            })
        }
        return [];
    }

    /*
        Function to set the selected coupon using Id, for editing coupon.
    */
    setSelectedGroupUsingId = (groupId: number) => {
        const groupDataListTyped = this.allGroupList;
        const groupsDataListArray = toJS(this.allGroupList);
        const selectedGroupIndex = groupsDataListArray.TrainerGroupList.findIndex((group: ITrainerGroup) => group.GroupId === groupId);
        if (selectedGroupIndex !== -1) {
            this.groupList = groupDataListTyped.TrainerGroupList[selectedGroupIndex];
        }
    }

    updateGroup = (Groups: IAddGroup) => {
        this.addUpdateGroupState.inProgress = true;
        const url = URLConstants.UpdateTrainerGroup;
        const data = {
            GroupId: Groups.GroupId,
            GroupName: Groups.GroupName
        };
        return baseService.putRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                //this.allGroupList.TrainerGroupList.push(response.data.Data);
                this.addUpdateGroupState.success = true;
                toast.success(formatMessage("updated_success"));
            })
            .catch((err: string) => {
                // console.log(err);
                this.addUpdateGroupState.error = err;
                toast.error(formatMessage(err));
                return null;
            })
            .finally(
                action(() => {
                    this.addUpdateGroupState.inProgress = false;
                })
            );
    }
    downloadFile = async (InvitesId: number) => {
        await this.getTrainerInvities(InvitesId);
        const data = [
          {
            InvitesId: this.getTrainerInvitiesId.InvitesId,
                UserId: this.getTrainerInvitiesId?.UserId,
                GroupId: this.getTrainerInvitiesId?.GroupId,
                GroupName: this.getTrainerInvitiesId?.GroupName,
                RecipientEmail: this.getTrainerInvitiesId?.RecipientEmail,
                TestId: this.getTrainerInvitiesId?.TestId,
                TestDate: this.getNormalisedDate(this.getTrainerInvitiesId?.TestDate),
                CreatedDate: this.getTrainerInvitiesId?.CreatedDate,
                UpdatedDate: this.getTrainerInvitiesId?.UpdatedDate,
                EmailText: this.getTrainerInvitiesId?.EmailText,
                Note: this.getTrainerInvitiesId?.Note,
                TestType: this.getTrainerInvitiesId?.TestType,
                ResultVisible: this.getTrainerInvitiesId?.ResultVisible,
                TestLink:this.getTrainerInvitiesId?.TestLink
          }
        ];
        const basicHeaders = ["GroupName", "RecipientEmail", "EmailText", "TestType", "Note", "TestDate"];
        const csvContent = [
          "Trainer",
          basicHeaders.join(','),
          ...data.map(row => basicHeaders.map(header => (row as any)[header]).join(',')),
        ].join('\n');
        this.downloadCSV(csvContent, this.getTrainerInvitiesId.GroupName)
      }
    
      downloadCSV = (content: string, fileName: string) => {
        const blob = new Blob([content], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
    
        document.body.removeChild(link);
      }
      /*
    Function to delte the event.
  */
  deleteGroup = (groupId: number) => {
    this.deleteGroupState.inProgress = true;
    const url = URLConstants.DeleteTrainerGroup + "?groupId="+ groupId;
    return baseService
    .deleteRequest(url)
    .then((responseData: any) => {
      if (responseData.status === 200)
      {
        this.deleteGroupState.success = true;
        toast.success(formatMessage("deleted_success"));
        // console.log(responseData);
      }
    })
    .catch((err: string) => {
    //   console.log(err);
      this.deleteGroupState.error = err;
      toast.error(formatMessage(err));
      return null;
    })
    .finally(
      action(() => {
        this.deleteGroupState.inProgress = false;
      })
    );
  }
  
   exportToExcel = (data:any,fileName = 'export.xlsx') =>{
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  }
   /*
    Computed function to get the Group detail when we open the edit Group dialog.
    */
    get getGroupDetail():IAddGroup{
        if(this.groupList)
          return{
            GroupId: this.groupList.GroupId,
            GroupName: this.groupList.GroupName,
            UserId: this.groupList.UserId,
          };
          return addGroupInitialState
      }

    get allTrainerInvitiesCount(): IGetTrainerInvitiesCount {
        if (this.getTrainerInvitiesCount) {
            return {
                TrainerInvites: this.getTrainerInvitiesCount.TrainerInvites,
                SubscriptionExpiryDate: this.getNormalisedDateSubscriptionExpiryDate(this.getTrainerInvitiesCount.SubscriptionExpiryDate),
                IsValidSubscriptionDate: this.getTrainerInvitiesCount.IsValidSubscriptionDate
            }
        }
        return getTrainerInvitiesCount;
    }


    get TrainerInvities(): ITrainerInfo {
        if (this.getTrainerInvitiesId)
            return {
                InvitesId: this.getTrainerInvitiesId.InvitesId,
                UserId: this.getTrainerInvitiesId.UserId,
                GroupId: this.getTrainerInvitiesId.GroupId,
                GroupName: this.getTrainerInvitiesId.GroupName,
                RecipientEmail: this.getTrainerInvitiesId.RecipientEmail,
                TestId: this.getTrainerInvitiesId.TestId,
                TestDate: this.getNormalisedDate(this.getTrainerInvitiesId.TestDate),
                CreatedDate: this.getTrainerInvitiesId.CreatedDate,
                UpdatedDate: this.getTrainerInvitiesId.UpdatedDate,
                EmailText: this.getTrainerInvitiesId.EmailText,
                Note: this.getTrainerInvitiesId.Note,
                TestType: this.getTrainerInvitiesId.TestType,
                ResultVisible: this.getTrainerInvitiesId.ResultVisible,
                TestLink: this.getTrainerInvitiesId.TestLink,
                UserName: this.getTrainerInvitiesId.UserName,
                RepeatTestCount: this.getTrainerInvitiesId.RepeatTestCount
            };
        return getTrainerInvitiesIdState;
    }

    
    get allTrainerGroups(): ITrainerGroup[] {
        if (this.allGroupList && this.allGroupList.TrainerGroupList?.length > 0) {
            //Sorting according to CreatedDate.
            // const allGroupsSortedData = toJS(this.questionDataList?.Question).sort((a,b) => Number(a.CreatedDate) - Number(b.CreatedDate));
            return this.allGroupList.TrainerGroupList?.map((group: ITrainerGroup) => {
                return {
                    UserId: group.UserId,
                    GroupId: group.GroupId,
                    GroupName: group.GroupName
                };
            });
        }
        return [];
    }

    getNormalisedDate = (unixTimestamp: string) => {
        if (Number.parseInt(unixTimestamp)) {
            var date = new Date(Number(unixTimestamp) * 1000);
            const formattedDate = date.toLocaleDateString("en-GB", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
            });
            return `${formattedDate}`;
        }
        return "";
    }

    getNormalisedDateSubscriptionExpiryDate = (date: string) => {
        if (date) {
            var date1 = new Date(date);
            const formattedDate = date1.toLocaleDateString("en-GB", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
            });
            return `${formattedDate}`;
        }
        return "";
    }
}
export default new TainerStore();