import React, { useState } from "react";

const EditProfileHOC = (Component: any) =>
  function Comp(props: any) {
    const [showEditProfilePopup, setShowEditProfilePopup] = useState<boolean>(false);

    const editProfileToggleHandler = () => {
      setShowEditProfilePopup(!showEditProfilePopup);
    };

    return (
      <Component
        showEditProfilePopup={showEditProfilePopup}
        editProfilePopupToggleHandler={editProfileToggleHandler}
        {...props}
      />
    );
  };

export default EditProfileHOC;
