import React, { useEffect } from 'react'
import { Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useStore } from '../../contexts/StoreProvider';
import toast from 'react-hot-toast';
import { formatMessage } from '../../translations/format-message';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';


function TrainerContactUs() {
    const { authStore } = useStore();
    const{trainerContactUs, trainerContactUsInfoError,trainerContactUsInfoSuccess,resetTrainerContactInfo} = authStore;
    useEffect(() => {
        if (trainerContactUsInfoError) {
          toast.error(formatMessage(trainerContactUsInfoError));
          resetTrainerContactInfo();
        }
  
      }, [trainerContactUsInfoError]);
    
      useEffect(() => {
        if (trainerContactUsInfoSuccess) {
          // toast.success(formatMessage("Message Sent"));
          resetTrainerContactInfo();
        }

      }, [trainerContactUsInfoSuccess]);
    const validationSchema = Yup.object({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        message: Yup.string().required()
    });
    const initialValues = {
        name: '',
        email: '',
        message: '',
      };
    return (
        <>
        <div className="banner--light breadcrumb-area">
            <div className="container">
                <h1 className="page-title"><FormattedMessage id="contact_us" /></h1>
                <p>If you have questions, doubts, or requests for collaboration, do not hesitate to contact us.</p>
            </div>
        </div>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 my-5 '>
              <Formik initialValues={initialValues} 
              validationSchema={validationSchema} 
              onSubmit={async (values) => {
                trainerContactUs(values);
              }}>
                <Form>
                  <div className='container--contact'>
                    <div className='form-group'>
                      <label>
                        <b>Name and Surname</b>
                      </label>
                      <Field type='text' className='form-control' name='name' placeholder='Enter name and surname' />
                    </div>
                    <div className='form-group'>
                      <label>
                        <b>Email</b>
                      </label>
                      <Field type='email' className='form-control' name='email' placeholder='Enter email' />
                    </div>
                    <div className='form-group'>
                      <label>
                        <b>Message</b>
                      </label>
                      <Field as='textarea' rows="6" className='form-control' name='message' placeholder='Enter message' />
                    </div>
                    <button type='submit' className='btn btn-primary'>
                      <FormattedMessage id="send"></FormattedMessage>
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </>
    )
}
export default observer(TrainerContactUs)