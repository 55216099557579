/*    
<summary>
   This functional common component provides us custom Date form Field and has error handler that shows 
   validation error. 
</summary>
<returns>Returns JSX</returns>
*/
import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Stack from '@mui/material/Stack';
import { useField } from 'formik';
import { formatMessage } from '../../translations/format-message';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useState } from 'react';

export default function FormikDateFieldMUI(props:any) {
  const { disabled, min, max , label} = props;
  const [ field, meta, helpers ] = useField(props);
  const { touched, error } = meta;
  const [dateError, setDateError] = useState("");

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
     <Stack spacing={3}>
        <DatePicker
          label={label ? label : ""}
          value={field.value ? dayjs(field.value) : null}
          views={props.cardExpiryformat ? ['year', 'month'] : ['year', 'month', 'day',]}
          format={props.cardExpiryformat ? "YY/MM" : "DD/MM/YYYY"} 
          onChange={(newValue:any) => {
            helpers.setValue(newValue);
          }}
          // onError={(err: any) => {setDateError(err);}}
          minDate={ min ? dayjs(min): undefined}
          maxDate={ max ? dayjs(max): undefined}
          disabled={props.disabled}
        />
      </Stack>
    </LocalizationProvider>
    { 
        (error && <span data-testid="testError" className=" text-danger errorMessage">{formatMessage(error)}</span>)
        // ||
        // (dateError && <span data-testid="testError" className=" text-danger errorMessage">{formatMessage(dateError)}</span>)
    }
    {/* { 
        (error && (
        error === "end_date_must_be_greater" ?
        <span data-testid="testError" className="errMessageRange text-danger errorMessage">{formatMessage(error)}</span> : 
        <span data-testid="testError" className="errMessageRange text-danger errorMessage">{formatMessage("invalid_datetime_format")}</span>))
    } */}
    </>
  );
}