/*    
<summary>
   This file is used to provide initial values or state to the AddEditGroupForm.
</summary>
*/

import IAddGroup from "../../models/Form/IAddUpdateGroup";

export const initialState: IAddGroup = {
   UserId:-1,
   GroupName:'',
   GroupId:-1,
   //CreatedDate:'',
   //UpdatedDate:''
}
