import { IStatState } from "../../models/state/IStatState";

export const postStatState: IStatState = {
    Nation: "",
    Gender: "",
    Type: "",
    //Invited: "",
    TestIds: "",
    WorkPositionType: 0,
    JobSectorTpye: 0,
    AgeRange:"",
    //DOB1: undefined,
    //DOB2: undefined,
};