import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMessage } from '../../../translations/format-message';
import { useStore } from '../../../contexts/StoreProvider';
import { observer } from 'mobx-react';

interface ReadMoreReadLessProps {
  messageId: string;
  maxLength?: number;
}

const ReadMoreReadLess: React.FC<ReadMoreReadLessProps> = ({ messageId, maxLength = 100 }) => {
  const [isReadMore, setIsReadMore] = useState<boolean|null>(null);
  const { preferencesStore } = useStore();
  const lang = preferencesStore.getLanguage;
  const [customText, setCustomText] = useState("");
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  useEffect(() => {
    setCustomText(() => formatMessage(messageId));
    setIsReadMore(() => (formatMessage(messageId)).length > maxLength ? true : null);
  }, [lang]);

  return (
    <div className='read--more-text '>
        {customText && 
          <span>{isReadMore ? `${customText.slice(0, maxLength)}...` : customText}</span>
        }

      <br /> 
      <button onClick={toggleReadMore} className='px-0'>
        {isReadMore == null ? '' : isReadMore == true ? 'Read More' : 'Read Less'} 
      </button>
    </div>
  );
};

export default observer(ReadMoreReadLess);
