import React from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { forgotEmailValidateSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import IForgotPassword from "../../../../models/IForgotPassword";
import Label from "../../../../shared-components/Label/Label";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../../contexts/StoreProvider";
import { observer } from "mobx-react";
import Loader from "../../../../shared-components/Loader/Loader";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";
import RoutesConstants from "../../../../shared-components/Routes/routes.constants";
import { useNavigate } from "react-router-dom";

interface IProps {
    forgotPasswordHandler: (data: IForgotPassword) => void;
    handleReturnToLogin: () => void;
    initialValues: IForgotPassword;
    isLoading: boolean;
    isSuccess: boolean;
    errors: string;
}

const ForgotPasswordForm = (props: IProps) => {
    return (
        <>
            <Formik
                initialValues={props.initialValues}
                validationSchema={forgotEmailValidateSchema}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    props.forgotPasswordHandler(values);
                }}
            >
                <Form className="login">
                    {(!props.isSuccess) 
                    ?
                    <>
                        {props.errors != "" &&
                        <div className="card-body p-2" style={{ backgroundColor: "red" }}>
                            <h4 className="text-white ">
                                <i className="fa fa-times-circle "></i>{" "}
                                {props.errors}
                            </h4>
                        </div>
                        }

                        <h3><FormattedMessage id="forgot_password_titile" /></h3>
                        <div className="loginBox">
                            <div className="form-group ">
                                <Label
                                    required={true}
                                    label="forgot_email"
                                    htmlFor="email"
                                />
                                <Field
                                    data-testid="email"
                                    className="form-control"
                                    name="email"
                                    type="text"
                                    invalidcharacter={[" "]}
                                    placeholder="forgot_email"
                                    validcharacter={[
                                        "-",
                                        "_",
                                        "[",
                                        "]",
                                        "(",
                                        ")",
                                        ":",
                                        "#",
                                        "@",
                                        ".",
                                    ]}
                                    as={FormikFormInput}
                                />
                            </div>
                        </div>
                        <LoaderButton
                            type="submit"
                            id="ForgotPasswordButton"
                            className="btn btn-primary btn-user"
                            isLoading={props.isLoading}
                            text="send_password_reset_email"
                        />
                    </>
                    :
                    <>
                        <div className="card-body p-2" style={{ backgroundColor: "green" }}>
                            <h4 className="text-white ">
                                <i className="fa fa-envelope"></i>&nbsp;
                                <FormattedMessage id='reset_password_info'/>
                            </h4>
                        </div>
                        <Button type="button" className="btn btn-primary" onClick={props.handleReturnToLogin}>
                            <FormattedMessage id='return_to_login'/>
                        </Button>
                    </>
                    }
                </Form> 
            </Formik>
        </>
    );
};

export default observer(ForgotPasswordForm);
