import React from 'react';
import './AgentDashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faPencil } from '@fortawesome/free-solid-svg-icons';
import RoutesConstants from '../../shared-components/Routes/routes.constants';
import { useNavigate } from 'react-router';
import EventList from './EventList/EventList';
import { FormattedMessage } from 'react-intl';

export default function AgentDashboard() {
    return (
        <>
             <h1>
                {/* <span className='m-3'><FontAwesomeIcon name="lock" icon={faLock}/></span> */}
                <FormattedMessage id ="agent_dashboard"/>
            </h1>
        </>
    )
}

