import RoutesConstants from '../../shared-components/Routes/routes.constants';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react'
// import PaymentCard from './PaymentCard';
import { observer } from 'mobx-react';
import PaymentPage from './PaymentPage';
import { ProductType } from '../../constants/enum';

const TrainerDashboardPayment = () => {

    return <PaymentPage productType={ProductType.TrainerPlanRenew} amount={20000}/>
}
export default observer(TrainerDashboardPayment);