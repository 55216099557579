import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../contexts/StoreProvider";
import toast from "react-hot-toast";
import { formatMessage } from "../../../../translations/format-message";
import AddEditUserForm from "../forms/EditProfileForm";
import { FormTypeEnum } from "../../../../constants/enum";
import { IUser } from "../../../../models/IUser";
import { IAddUser } from "../../../../models/Form/IAddUser";
import AddUserFromAdminDashboardForm from "../forms/AddUserFromAdminDashboardForm";

interface IProps {
    modalClosed: () => void;
    submitHandler: (values: IAddUser) => void;
    deleteUserHandler?: (email: string) => void;
    initialValues: IAddUser;
    isLoading: boolean;
}

const CreateUserPopup = (props: IProps) => {
    const { adminStore } = useStore();
    const {addUpdateUserState, resetAddUpdateUserState}  = adminStore;

    useEffect(() => {
      if (addUpdateUserState.success) {
          // toast.success(formatMessage("updated_success"));
          resetAddUpdateUserState();
          props.modalClosed();
        }
      }, [addUpdateUserState.success]);
        
    return (
        <AddUserFromAdminDashboardForm modalClosed={props.modalClosed} isLoading={props.isLoading} 
          initialValues={props.initialValues} submitHandler={props.submitHandler} />
    );
};

export default observer(CreateUserPopup);
