/*    
<summary>
   This functional common component provides spinner.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React from 'react';
import {Spinner} from 'react-bootstrap';

interface IProps {

}

const Loader:React.FC<IProps>= props =>{
    return (
        
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status" 
        style={{color: "#06FFF0"}}
        />
    )
};

export default Loader;