import logo from '../../assets/Images/logo_original.png';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';

const AboutCompany = (props: any) => {
    return (
        <div className="container address--wrapper">
            <div className="address-container">
                <h2 className="">KaleidoCompass International Ltd</h2>
                <h3>Registered Office:</h3>
                <p>
                    Toruko Blue Bld 701<br />
                    Ishihara 1-7-13<br />
                    Sumida-ku Tokyo Giappone<br />
                </p>
                <p>E-mail: <a href="mailto:kaleidocompass@humanskills.jp"><FormattedMessage id='contact_mail'/></a></p>
            </div>

            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.0972874331965!2d139.79436807586578!3d35.699223468405044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188eccad395555%3A0x8d243a041ee0232d!2sKaleidoCompass%20International%20Ltd!5e0!3m2!1sen!2sin!4v1700421879116!5m2!1sen!2sin" width="550" height="400" loading="lazy"></iframe> 
            </div>
        </div>
    )
}
export default AboutCompany;