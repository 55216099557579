/*    
<summary>
   This function translates the string passed as argument into its one of the two translataion based on language selected.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
*/
import { IntlProvider } from 'react-intl';
import translations from './translation';
import moment from 'moment';
import preferencesStore from '../core/stores/preferences-store';

let intl: any;

export function formatMessage(key: string, value?: object): string {
  try {
    let locale: any = preferencesStore.getLanguage;
    let messages = translations[locale].messages;
    let intlProvider = new IntlProvider({ locale, messages });
    moment.locale(locale);
    intl = intlProvider.state.intl;
    let label: {
      [key: string]: any;
    } = {};
    label[key] = intl.formatMessage(
      {
        id: key
      },
      value
    );
    return label[key];
  } catch {
    return key;
  }
}
