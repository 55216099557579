import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import RoutesConstants from '../../../shared-components/Routes/routes.constants';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const RechargeTable = ({ data, setTotal }: any) => {
    const navigate = useNavigate();
    return (
        <>
            {data.map((item: any, index: number) => (
                <div className='card card-body mb-3'>
                    <div className='row align-items-center'>
                        <div className='col-5'>
                            <b className='text-dark'><i className='fa fa-yen-sign'></i>&nbsp;{item.price}</b>
                        </div>
                        <div className='col-5'>
                        <b className='text-dark'>{item.mail}</b> <small className='text-muted'><FormattedMessage id="test"/></small>
                        </div>
                        <div className='col-2 text-end'>
                            <button className="btn btn-outline-primary" onClick={() => navigate(`${RoutesConstants.TrainerRechargePayment}/${item.productType}`) }><FontAwesomeIcon icon={faArrowRight} /> </button>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default RechargeTable
