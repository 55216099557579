import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import PaymentPage from './PaymentPage';
import { ProductType } from '../../constants/enum';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

const TestPayment = () => {
    const { state } = useLocation();
    return <PaymentPage productType={ProductType.Test} amount={2000} testId={state ? state.testId : null} />
}
export default observer(TestPayment);