import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useRef, useState } from 'react';
import { formatMessage } from '../../../translations/format-message';
import { ICategoryInfo } from '../../../models/response/ITestInterpretation';
import { ITestInfo } from '../../../models/ITestInfo';
import ITestColorMapping from '../../../models/ITestColorMapping';

interface IProps {
    dataPoint: ICategoryInfo;
    allTestInfo: ITestInfo[];
    hiddenTestId: number[];
}

const BarGraph = ({dataPoint, allTestInfo, hiddenTestId}: IProps) => {
    const chartRef = useRef<HighchartsReact.RefObject>(null);
    const [dataPointOptions, setDataPointOptions] = useState<any>();
    const getChartOptions = () => {
        const flattenDataToBePlotted = dataPoint.SubCategoryList.flatMap((subCat) => subCat.Results);
        return {
            chart: {
                type: 'column',
            },
            title: {
                text: '',
            }, 
            credits: {
                enabled: false
            },
            xAxis: {
                categories: [...dataPoint.SubCategoryList.map((subQuestion) => formatMessage(subQuestion.SubCategoryKey))],
                // categories: ["Variety", "Routine"],
            },
            yAxis: [
                {
                    title: {
                        text: undefined,
                    },
                    plotLines: [
                        {
                            color: 'blue',
                            value: dataPoint.MinThreshold,
                            // events: {
                            //     mouseover: () => dataPoint.MinThreshold
                            // },
                            width: 1,
                            label: {
                                // text: 'Min Threshold',
                                align: 'right',
                                style: {
                                    color: 'blue',
                                },
                                // formatter: function () {
                                //     return `Min Threshold: ${dataPoint.MinThreshold}`;
                                // }
                            },
                        },
                        {
                            color: 'red',
                            value: dataPoint.MaxThreshold,
                            width: 1,
                            label: {
                                // text: 'Max Threshold',
                                align: 'right',
                                style: {
                                    color: 'red',
                                },
                                // formatter: function () {
                                //     return `Max Threshold: ${dataPoint.MaxThreshold}`;
                                // }
                            },
                        },
                    ],
                    min: 20,
                    max: 80,
                    tickPixelInterval: 100
                },
            ],
            plotOptions: {
                // column: {
                //     pointWidth: 40,
                // },
                column: {
                    maxPointWidth: 40,
                },
                series: {
                    pointPadding: 0,
                }
            },

            series:
                allTestInfo.filter(test => !hiddenTestId.includes(test.TestId)).map((test, index) => {
                        const dataToBePlotted = flattenDataToBePlotted.filter((res) => (res.TestId == test.TestId)).map(x => x.Result);
                        return {
                            showInLegend: false,
                            cursor: 'pointer',
                            name: test.UserEmail,
                            data: [...dataToBePlotted],
                            color: test.Color,
                            events: {
                                legendItemClick: function() {
                                    return false
                                }
                            }
                        }
                    })
        }
    };

    useEffect(()=> {
        if (dataPoint && dataPointOptions === undefined) {
            const currentChartOptions = getChartOptions();
            setDataPointOptions(currentChartOptions);
        }
    }, [dataPoint, allTestInfo]);

    useEffect(() => {
        const currentChartOptions = getChartOptions();
        setDataPointOptions(currentChartOptions);
        chartRef.current?.chart.reflow();
    }, [hiddenTestId])

    useEffect(() => {
        let width = 0;
        let height = 0;
        const handleBeforePrint = () => {
            const chart = chartRef.current?.chart;
            if (chart) {
                width = chart.chartWidth;
                height = chart.chartHeight;
                chart.yAxis[0].options.tickPixelInterval = 80;
                chart.setSize(300, 300, false);
                chart.reflow();
            }
          };
      
          const handleAfterPrint = () => {
            const chart = chartRef.current?.chart;
            if (chart) {
                chart.yAxis[0].options.tickPixelInterval = 100;
                chart.setSize(null, height, false); // Reset to default size
                chart.reflow();
            }
          };
      
          window.addEventListener('beforeprint', handleBeforePrint);
          window.addEventListener('afterprint', handleAfterPrint);
      
          // Clean up the event listeners
          return () => {
            window.removeEventListener('beforeprint', handleBeforePrint);
            window.removeEventListener('afterprint', handleAfterPrint);
          };
    }, []);

    return (
        <>
            { dataPointOptions && 
                <HighchartsReact highcharts={Highcharts} options={dataPointOptions} ref={chartRef} />
            }
        </>
    );
};

export default BarGraph;
