import React, { useState } from "react";

const EditEventPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showEditEventPopup, setShowEditEventPopup] = useState<boolean>(false);

    const editEventToggleHandler = () => {
      setShowEditEventPopup(!showEditEventPopup);
    };

    return (
      <Component
        showEditEventPopup={showEditEventPopup}
        editEventPopupToggleHandler={editEventToggleHandler}
        {...props}
      />
    );
  };

export default EditEventPopupHOC;
