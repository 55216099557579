import React, { useEffect, useState } from 'react'
import InputTag from '../InputTag/InputTag'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useStore } from '../../../contexts/StoreProvider'
import { observer } from 'mobx-react'
import { Col, Row } from 'react-bootstrap'
import ILabelInfo from '../../../models/response/IAdminResponse'
import Pagination from '../../../shared-components/Grid/Pagination'
import { FormattedMessage } from 'react-intl'
import { formatMessage } from '../../../translations/format-message'
import { toast } from 'react-hot-toast'
import { pageLimitOptions } from '../../../constants/options.constant'

const Label = (props: any) => {
    const { adminStore } = useStore();
    const { allLabel, getAllLabels, updateLabel, updateLabelState, resetUpdateLabelState, updateFilteredText,updateSearchingText,searchingFilterState,labelPaginationState,updatePageNumber, updatePageSize,resetLabelPagingState} = adminStore
    const [createObj, setCreateObj] = useState<any>([])
    const [errorMessages, setErrorMessages] = useState<any>({});
    const [searchText, setSearchText] = useState<string>("");
    const [isSearchPerformed, setIsSearchPerformed] = useState<boolean>(false);
    /*
      function to get all labels 
    */
    const callGetAllLabels=()=>{
        getAllLabels(labelPaginationState.PagingDetails.PageNo, labelPaginationState.PagingDetails.PageSize, searchText, searchingFilterState.filter);
        resetUpdateLabelState();
        setCreateObj([]);
    }

    /*
      get all labels based on updateLabelState
    */
    useEffect(() => {
        if(updateLabelState.success){
            callGetAllLabels();
            resetUpdateLabelState();
            // toast.success(formatMessage("saved_success"));
        }
    }, [updateLabelState.success]);

    // get all labels based on pagerChange
    useEffect(() => {
       if(labelPaginationState.PagingDetails.isPagerChange){
           callGetAllLabels();
           resetLabelPagingState();
       }
    }, [labelPaginationState.PagingDetails.isPagerChange]);

    useEffect(() => {
        if (searchText.replace(/\s+/g,' ').trim()  === '' && isSearchPerformed) {
            setIsSearchPerformed(() => false);
            setSearchText(() => '');
            callGetAllLabels();
        }
    }, [searchText]);

    const [disablePagination, setDisablePagination] = useState<boolean>(false);

       const onPageChanged = (pageNumber: number) => {
         updatePageNumber(pageNumber);
       };

       const onPageLimitChanged = (evt: any) => {
         updatePageSize(parseInt(evt.target.value));
         let pages: number;
         pages = props.data && Math.ceil(props.data.length / evt.target.value);
       
       };
    
   
    const textAreaFn = (value: string, index: number, key: string) => {

        let createObjs = {
            Id: index,
            [key]: value
        }

        if (Array.isArray(createObj)) {

            const itemExists = createObj.some((data: any) => data.id === index);

            if (itemExists) {
                const updatedCreateObj = createObj.map((data: any) => {
                    if (data.id === index) {
                        return { ...data, [key]: value };
                    }
                    return data;
                });
                setCreateObj(updatedCreateObj);

            } else {

                if (createObjs.hasOwnProperty('jp') && createObjs.hasOwnProperty('it') && createObjs.hasOwnProperty('it')) {
                    setCreateObj((prevCreateObj: any) => [...prevCreateObj, createObjs]);
                }
                else if (createObjs.hasOwnProperty('jp') && !createObjs.hasOwnProperty('it') && !createObjs.hasOwnProperty('en')) {
                    let find = allLabel.find(value => value.id === index)
                    let NewcreateObj = {
                        ...createObjs,
                        id: find?.id,
                        en: find?.en,
                        it:find?.it
                    }
                    setCreateObj((prevCreateObj: any) => [...prevCreateObj, NewcreateObj]);
                }
                else if (!createObjs.hasOwnProperty('jp') && createObjs.hasOwnProperty('it') && !createObjs.hasOwnProperty('en')) {
                    let find = allLabel.find(value => value.id === index)
                    let NewcreateObj = {
                        ...createObjs,
                        id: find?.id,
                        jp: find?.jp,
                        en: find?.en
                    }
                    setCreateObj((prevCreateObj: any) => [...prevCreateObj, NewcreateObj]);
                }
                else if (!createObjs.hasOwnProperty('jp') && !createObjs.hasOwnProperty('it') && createObjs.hasOwnProperty('en')) {
                    let find = allLabel.find(value => value.id === index)
                    if (createObjs.en.toString().trim() != "") {
                        let NewcreateObj = {
                            ...createObjs,
                            id: find?.id,
                            jp: find?.jp,
                            it: find?.it
                        }
                        setCreateObj((prevCreateObj: any) => [...prevCreateObj, NewcreateObj]);
                    }
                }
            }
        } else {
            setCreateObj([createObjs]);
        }

    };

    const handleSaveClick = () => {
        const labelInfoArray: ILabelInfo[] = createObj.map((obj: any) => ({
            id: obj.id,
            it: obj.it,
            en: obj.en,
            jp: obj.jp,
        }));
        if(labelInfoArray.length > 0) {
            updateLabel(labelInfoArray);
        }
    };
 
    const handleGetAllBasedOnCondition = (Search: string | null, value: string | null) => {
      if(value){
        setSearchText(() => "");
        updateFilteredText(value)
        updatePageNumber(1,true);
      }
      else if(Search){
        updateFilteredText("");
        updatePageNumber(1, true);
      }
      else{
        updateFilteredText("");
        setSearchText(() => "");
        updatePageNumber(1, true);

      }
  };

  const handleSearch = () => {
    updatePageNumber(1, true);
    updateFilteredText("");
    setIsSearchPerformed(() => true);
  }

  const handleKeyDown=(event:any)=>{
    if (event.key === 'Enter' && searchText.replace(/\s+/g,' ').trim() !== '') {
        handleSearch();
    }
  }
const handleChange=(value:string,index:number,key:string)=>{
       if(value.trim()!=""){
           setErrorMessages((prevErrorMessages: any) => (
            {
               ...prevErrorMessages,
               [index]: undefined // Clear error message when not empty
            }));
        }
        else{

           setErrorMessages((prevErrorMessages: any) => (
            {
               ...prevErrorMessages,
               [index]: 'Invalid input. English value cannot be null.'
            }));
        }
    
}
    const DummyArray = [
        <div className='d-flex align-items-center'>
            <button className="filter-wrap me-2" onClick={()=>handleGetAllBasedOnCondition(null, null)}><FormattedMessage id="see_all" /></button>
            <button className="filter-wrap me-2" onClick={()=>handleGetAllBasedOnCondition(null,"jp")}><FormattedMessage id="missing_jpn" /></button>
            <button className="filter-wrap me-2" onClick={()=>handleGetAllBasedOnCondition(null,"en")}><FormattedMessage id="missing_en" /></button>
            <button className="filter-wrap me-2" onClick={()=>handleGetAllBasedOnCondition(null,"it")}><FormattedMessage id="missing_it" /></button>
        </div>
    ]

    const TextArea: any = (data: string, index: number, key: string) => {
        return <p className='col-md-4 d-flex flex-column'>
        <textarea className='form-control' onBlur={e => textAreaFn?.(e.target.value, index, key)}  onChange={(e) => key === 'en' && handleChange(e.target.value, index, key)} >{data}</textarea>
            {key === 'en' && errorMessages[index] && <div style={{ color: 'red' }}>{errorMessages[index]}</div>}
        </p>
    }

    return (
        <>
            <div className="dashboardTopbar">
                <div className="container">
                    <Row className='align-items-center'>
                        <Col xs={7}>
                            <InputTag size="search" icon={true} inputValue={setSearchText} inputvalue={searchText} onKeyDown={handleKeyDown} inputType="text" clickedFn={handleSearch} placeholderText={formatMessage("search")} FaIcon={faSearch} />
                        </Col>
                        <Col xs={5}>
                            <div className='d-flex justify-content-end'> 
                                <button className="btn btn-primary" disabled={createObj?.length > 0 ? false : true} onClick={handleSaveClick}><i className='fa fa-save'></i> <FormattedMessage id="save_fields" /></button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='container'>
                {
                    DummyArray.map((data, index) => <React.Fragment key={index}>
                        {data}
                    </React.Fragment>
                    )
                } 
                <div className=' p-2 bg-dark text-white d-none d-md-block mt-3'>
                    <div className='row g-0'>
                        <div className='col-md-4  text-center'>
                            <h3 className='text-white'>ENGLISH</h3>
                        </div>
                        <div className='col-md-4  text-center'>
                        <h3 className='text-white'>JAPANESE</h3>
                        </div>
                        <div className='col-md-4  text-center'>
                        <h3 className='text-white'>ITALIAN</h3>
                        </div>
                    </div>
                </div>
                {allLabel.map((data: any, index: number) => {

                    return <><div key={index} className='row py-3 languageTextarea'>
                        <span className='d-md-none'>English</span>
                        {TextArea(data.en, data.id, "en")}
                        <span className='d-md-none'>Japanese</span>
                        {TextArea(data.jp, data.id, "jp")}
                        <span className='d-md-none'>Italian</span>
                        {TextArea(data.it, data.id, "it")}
                    </div>
                    </>
                })}
            <button className="btn btn-primary" disabled={createObj?.length > 0 ? false : true} onClick={handleSaveClick}><FormattedMessage id="button_save" /></button>
            <ul className="d-flex flex-column flex-md-row justify-content-between mt-4">
            <li className="page-count-no mb-2">
              <FormattedMessage id="showing_result_for" />
              <select
                data-testid="pageSelect"
                className="pageLimit ms-2"
                onChange={onPageLimitChanged}
                value={labelPaginationState.PagingDetails.PageSize}
              >
                {pageLimitOptions.map((op: any) => (
                   <option key={op} value={op}>
                    {op}
                   </option>
                 ))}
              </select>
            </li>
              <li className="page-number">
               <Pagination
                  isRefreshPage={labelPaginationState.PagingDetails.isRefreshPage}
                  totalPages={labelPaginationState.PagingDetails.TotalPages}
                  pageLimit={labelPaginationState.PagingDetails.PageSize}
                  onPageChanged={onPageChanged}
                  disablePagination={disablePagination}
                />
             </li>
         </ul> 
            </div >
        </>
        

    )
}

export default observer(Label);
