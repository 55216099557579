import React, { useState } from "react";
const CreateGroupPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showCreateGroupPopup, setShowCreateGroupPopup] = useState<boolean>(false);

    const createGroupToggleHandler = () => {
      setShowCreateGroupPopup(!showCreateGroupPopup);
    };

    return (
      <Component
        showCreateGroupPopup={showCreateGroupPopup}
        createGroupPopupToggleHandler={createGroupToggleHandler}
        {...props}
      />
    );
  };

export default CreateGroupPopupHOC;
