/*    
<summary>
   This class component is all about Managing common functionalities (i.e. language, version).
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from "mobx";
import { ICommonState } from "../../models/state/ICommonState";
import { IPreferenceState } from "../../models/state/IPreferenceState";

export class PreferencesStore implements IPreferenceState, ICommonState {
    inProgress = false;
    error = '';
    lang = localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'en';
    theme = localStorage.getItem('darkTheme') !== null ? localStorage.getItem('darkTheme') : 'false';
    language = this.lang!;
    version = '1.0';
    userSetting: any = {
        alertViewerConfig: 0,
        deviceViewerConfig: 0,
        deviceManagementConfig: 1,
        pageLimitConfig: 5,
    };

    successToastTheme ={
        border: '1px solid #28B463',
        backgroundColor: '#EAFAF1',
      }

    islanguageChanged = false;
    activeGroupId: number = 0;

    constructor() {
        makeObservable(this, {
            language: observable,
            islanguageChanged: observable,
            version: observable,
            userSetting: observable,
            theme: observable,
            activeGroupId: observable,
            getLanguage: computed,
            getTheme: computed,
            getVersionFromStore: computed,
            successToast: computed,
            changeLanguage: action,
            UpdateLanguage: action,
            setUpdateTheme: action,
            setUserSetting: action,
            setUpdateActiveGroup: action,
            updateLanguageChangedFlag: action
            // setPageLimitConfig:action,
        })
    }

    get getLanguage() {
        return this.language;
    }

    get getTheme() {
        return this.theme;
    }

    get successToast() {
        return this.successToastTheme;
    }
    /*
    This function is used to Change Language.  
    */

    changeLanguage(language: string) {
        this.islanguageChanged = this.language === language ? false : true;
        this.language = language;
        localStorage.setItem('language', language);
    }

    /*
    This function is used to set Language Changed Flag.  
    */
    updateLanguageChangedFlag = () => {
        this.islanguageChanged = false;
    }

    /*
    This function Updates language with the language getting from local Storage.  
    */

    UpdateLanguage() {
        let language = localStorage.getItem('language');
        if (language)
            this.language = language;
    }

    get getVersionFromStore() {
        return this.version != "" ? this.version : localStorage.getItem('version');
    }

    setUserSetting = (key: string, id: number) => {
        this.userSetting[key] = id;
    }

    setUpdateTheme = (theme: string) => {
        this.theme = theme;
        localStorage.setItem('darkTheme', theme)
    }

    setUpdateActiveGroup = (id: number) => {
        this.activeGroupId = id;
    }

    // setPageLimitConfig = (pageLimit: number) => {
    //     this.pageLimitConfig = pageLimit;
    // }
}

export default new PreferencesStore();