import React, { useState } from "react";

const SendEventMailPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showSendEventMailPopup, setShowSendEventMailPopup] = useState<boolean>(false);

    const sendEventMailToggleHandler = () => {
      setShowSendEventMailPopup(!showSendEventMailPopup);
    };

    return (
      <Component
        showSendEventMailPopup={showSendEventMailPopup}
        sendEventMailPopupToggleHandler={sendEventMailToggleHandler}
        {...props}
      />
    );
  };

export default SendEventMailPopupHOC;
