
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { observer } from "mobx-react";
import { Field, Form, Formik } from "formik";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { FormattedMessage } from "react-intl";
interface IProps {
    modalClosed: () => void;
    initialValues: any;
    submitHandler: (data: any, isRepeatTest: boolean) => void;
    ResultTypes: any;
}
const RepeatForwardMailInfoForm = (props: IProps) => {
    const { initialValues, submitHandler, ResultTypes } = props;
    return (
        <Modal show={true} centered
            onHide={props.modalClosed}
            backdrop="static"
            scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h1> <FormattedMessage id="Mail_Info" /></h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validateOnBlur={false}
                    onSubmit={async (values: any) => {
                        if (initialValues.TestId > 0 && initialValues.RepeatTestCount >= 1) {
                            return;
                        }
                        const IsResultVisible = !!parseInt(values.ResultVisible)
                        const MailInfo: any = {
                            InvitiesId: values.InvitesId,
                            IsResultVisible: IsResultVisible
                        }
                        const isRepeatTest: boolean = (initialValues.RepeatTestCount < 1 && initialValues.TestId !== 0);
                        submitHandler(MailInfo, isRepeatTest);
                    }}
                    render={({ values }) => {
                        return (
                            <>
                                <div className='row'>
                                    <div className='col-lg-6 col-12 mb-3'>
                                        <label className='badge bg-primary '>{initialValues.TestType}</label>
                                    </div>
                                </div>
                               
                                <Form id="RepeatForwardForm" className="modal-form-SendInvities">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className="form-group ">
                                                <Field
                                                    data-testid="ResultVisible"
                                                    className="form-control text-dark"
                                                    name="ResultVisible"
                                                    options={ResultTypes}
                                                    as={FormikFormSelect} />
                                            </div>
                                        </div>
                                    </div>

                                </Form>
                            </>
                        );
                    }}
                >
                </Formik >
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end ">
                    {
                        ((initialValues.TestId !== 0) && (initialValues.RepeatTestCount < 1)) && (
                            <LoaderButton
                                type="submit"
                                id="UpdateButton"
                                form="RepeatForwardForm"
                                className="btnOkSize"
                                text="RepeatTest"
                                variant='danger'
                            />
                        )
                    }
                    {
                        (initialValues.RepeatTestCount >= 1 || (initialValues.TestId === 0)) && (
                            <LoaderButton
                                type="submit"
                                id="UpdateButton"
                                form="RepeatForwardForm"
                                className="btnOkSize"
                                text="ForwardEmail"
                                disabled={initialValues.TestId > 0}
                            />
                        )


                    }
                </div>
            </Modal.Footer>
        </Modal >
    );
};

export default observer(RepeatForwardMailInfoForm);
