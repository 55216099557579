import { IGetAllUsers } from "../../models/response/IAdminResponse";

export const initialState: IGetAllUsers = {
  PagingDetails:{
    TotalRecords: 1,
    TotalPages: 1,
    PageNo: 1,
    PageSize: 10,
},
  Users: [],
};