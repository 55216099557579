import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { CardItem } from './Threshold';
import { Field, Form, Formik, useFormik, useFormikContext } from "formik";
import { thresholdValidateSchema } from '../../../shared-components/FormikFormHandlers/FormikValidationSchema';
import FormikFormInput from '../../../shared-components/FormikFormHandlers/FormikFormInput';
import Label from '../../../shared-components/Label/Label';
import { useStore } from '../../../contexts/StoreProvider';
import { IThreshold } from '../../../models/state/IThreshold';
import { FormattedMessage } from 'react-intl';
import { formatMessage } from '../../../translations/format-message';

interface ThresholdCardProps {
    JsonData: IThreshold;
    DeleteJson: (id: number) => void;
    isNew?: boolean;
    index?: number;
    isEdit?: boolean;
    onEdit: () => void;
    onSave: () => void;
    DeleteThreshold:(thresholdId:number, thresholdName:string)=>void;
}

const ThresholdCard: React.FC<ThresholdCardProps> = ({ JsonData, DeleteJson, isNew, index, isEdit, onEdit, onSave,DeleteThreshold }) => {

    const { adminStore } = useStore();
    const { updateThreshold, createThreshold } = adminStore

    const cardsRef = useRef<HTMLDivElement>(null);

    const handleCancel = () => {
        // Reset the Form
        const form = document.getElementById(`threshold-${JsonData.Key}`) as HTMLFormElement;
        form.reset();

        //set the editing state to false
        setIsEditing(false);
        onSave();
    }


    useEffect(() => {
        if (cardsRef.current) {
            cardsRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [JsonData]);

    const [isEditing, setIsEditing] = useState<boolean>(isNew ? true : false)

    return (
        <Formik
            initialValues={JsonData}
            validationSchema={thresholdValidateSchema}
            enableReinitialize={true}
            validateOnBlur={false}
            onSubmit={async (values) => {
                if (isNew) {
                    createThreshold(values);
                    if (index != null && index >= 0) {
                        DeleteJson(index);
                    }
                    setIsEditing(false);
                    onSave();
                }
                else {
                    if (isEditing) {
                        updateThreshold(values);
                        setIsEditing(false);
                        onSave();
                    } else {
                        setIsEditing(true);
                    }
                }
            }}
        >

            {({ values, errors }) => (
                <Form id={`threshold-${JsonData.Key}`}>
                    <Card className="h-100">
                        <Card.Body>
                            <div className="mb-3 form-group">
                                <Label
                                    required={true}
                                    className="fw-bold"
                                    label="label"
                                    htmlFor="label"
                                />
                                <Field
                                    data-testid="Label"
                                    className="form-control"
                                    name="Label"
                                    type="text"
                                    placeholder="label"
                                    validcharacter={[
                                        "-",
                                        "_",
                                        "[",
                                        "]",
                                        "(",
                                        ")",
                                        ":",
                                        "#",
                                        "@"
                                    ]}
                                    as={FormikFormInput}
                                    disabled={!isEdit}
                                />
                            </div>
                            <div className="mb-3 form-group">
                                <Label
                                    required={true}
                                    className="fw-bold"
                                    label="value"
                                    htmlFor="value"
                                />
                                <Field
                                    data-testid="Value"
                                    className="form-control"
                                    name="Value"
                                    type="number"
                                    placeholder="value"
                                    as={FormikFormInput}
                                    disabled={!isEdit}
                                />
                            </div>
                            <div className="mb-3 form-group">
                                <Label
                                    required={true}
                                    className="fw-bold"
                                    label="slug"
                                    htmlFor="slug"
                                />

                                <Field
                                    data-testid="Slug"
                                    className="form-control"
                                    name="Slug"
                                    type="text"
                                    placeholder="slug"
                                    validcharacter={[
                                        "-",
                                        "_",
                                        "[",
                                        "]",
                                        "(",
                                        ")",
                                        ":",
                                        "#",
                                        "@"
                                    ]}
                                    as={FormikFormInput}
                                    disabled={!isEdit}
                                />
                            </div>

                            <div className="d-flex justify-content-end mt-3">
                                {(!JsonData.IsOriginal) &&
                                    (<button className="btn btn-sm btn-outline-danger me-auto" type="button" onClick={()=>DeleteThreshold(JsonData.ThresholdId,JsonData.Label)} title={formatMessage('delete')}>
                                        <svg version="1.1" id="Layer_1" viewBox="0 0 512 512" >
                                            <g>
                                                <g>
                                                    <path d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"/>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <path d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </button>)}
                                {isEdit && (
                                    <button className="btn btn-sm btn-secondary me-2" type="button" onClick={handleCancel}>
                                        <FormattedMessage id='button_cancel' />
                                    </button>)}
                                {!isEdit ?
                                    <button
                                        className='btn btn-sm btn-secondary '
                                        type="button"
                                        onClick={onEdit}
                                    > <FormattedMessage id="edit" /></button> : <button
                                        className='btn btn-sm btn-primary'
                                        type="submit"
                                    > <FormattedMessage id="button_save" /></button>
                                }

                            </div>
                        </Card.Body>
                    </Card>
                    {
                        isNew && 
                            <div ref={cardsRef}></div>
                    }
                </Form>)
            }
        </Formik >
    );
};

export default ThresholdCard;
