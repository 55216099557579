import { IGetAllEvents, IGetEventSummary, IGetEventSummaryList } from "../../models/response/IAgentResponse";

export const initialState: IGetAllEvents = {
  PagingDetails:{
    TotalRecords: 1,
    TotalPages: 1,
    PageNo: 1,
    PageSize: 10,
},
  Events: [],
};

export const initialEventInfoState: IGetEventSummary = {
  EventSummary: {
    CouponCode: "",
    CreatedDate: "",
    EventId: -1,
    EventMails: [],
    EventName: "",
    Note: "",
    TestType: "",
    UpdatedDate: "",
    UserId:-1,
    UserEmailId:"",
    DiscountPercentage:0
  },
};
  