import React, { useEffect } from "react";
import { useStore } from "../../../../contexts/StoreProvider";
import { formatMessage } from "../../../../translations/format-message";
import toast from "react-hot-toast";
import { observer } from "mobx-react";
import AddEditEventForm from "../../../../shared-components/AddEditEventForm/AddEditEventForm";
import { FormTypeEnum } from "../../../../constants/enum";

interface IProps {
  modalClosed: () => void;
  isLoading: boolean;
  eventId?: any;
}

const EditEventPopup = (props: IProps) => {
  const { agentStore } = useStore();
  const { addUpdateEventState, resetAddUpdateEvent,deleteEventState,resetDeleteEvent,getAllEvents } = agentStore;

  useEffect(() => {
    if (addUpdateEventState.success) {
      resetAddUpdateEvent();
      props.modalClosed();
    }
  }, [addUpdateEventState.success]);

   useEffect(() => {
    if(deleteEventState.success){
       resetDeleteEvent();
       props.modalClosed();
     }
   }, [deleteEventState.success]);


  return (
    <AddEditEventForm FormType={FormTypeEnum.Edit} modalClosed={props.modalClosed} isLoading={props.isLoading} EventId={props.eventId}/>
  );
};

export default observer(EditEventPopup);
