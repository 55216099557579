import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormTypeEnum } from "../../../constants/enum";
import AddEditStatForm from "../AddEditStatForm/AddEditStatForm";
import { IStatState } from "../../../models/state/IStatState";
import { postStatState } from "../../../core/initial-state/add-stat-state";


interface IProps {
    modalClosed: () => void;
    setFetchStats: any
    isLoading:boolean;
}

const CreateStatPopup = (props: IProps) => {
    const { setFetchStats, modalClosed } = props;

    const onSubmitForm = (values: IStatState) => {
        const colors = [
            "#F5F5DC", "#FFFDD0", "#D1E9F6", "#F6EACB", "#E6E6FA", "#FFDAB9", "#F08080",
            "#D1E9F6", "#FFDAB9", "#FFE4E1", "#FFF0F5", "#F0FFF0", "#F0F8FF", "#FFFFF0",
            "#FFA07A", "#FFFAF0", "#FAF0E6", "#FAEBD7", "#FFF5EE", "#DCDCDC", "#B0C4DE",
            "#FAFAD2", "#D8BFD8", "#F5DEB3", "#F5F5DC", "#F5FFFA", "#EEE8AA", "#FDF5E6", 
            "#F4A460", "#FFDAB9", "#FFB6C1", "#87CEEB", "#E6E6FA", "#DCDCDC", "#DEB887",
            "#E0FFFF", "#FFF8DC", "#FFFFE0", "#FFFAF0", "#FFF5EE", "#F8F8FF",
            "#D87093"
        ];
        
        let StoringValue: any = JSON.parse(localStorage.getItem("statFilterList") as string)
        if(values.TestIds.length > 0){
            let TestIdArraySetting: any = values.TestIds
            values.TestIds = TestIdArraySetting.split(",")
        }
        //StoringValue.push({...values, Color:'#'+('ffe'+(Math.random()*0xFFF<<0).toString(16)).slice(-6)})
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        StoringValue.push({ ...values, Color: randomColor });

        localStorage.setItem("statFilterList", JSON.stringify(StoringValue))
        setFetchStats((old: boolean) => !old)
        modalClosed();
    };
    return (
        <AddEditStatForm modalClosed={props.modalClosed} isLoading={props.isLoading} initialValues={postStatState} FormType={FormTypeEnum.Create} submitHandler={onSubmitForm} EditStatId={undefined} />
    );
};

export default observer(CreateStatPopup);
