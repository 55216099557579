import React, { useState } from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { FormattedMessage } from "react-intl";
import IForgotPassword from "../../../../models/IForgotPassword";
import RoutesConstants from "../../../../shared-components/Routes/routes.constants";
import { useNavigate } from 'react-router-dom';
import { observer } from "mobx-react";
import { initialState as forgotPasswordInitialState } from "../../../../core/initial-state/forgot-password-state";
import { useStore } from "../../../../contexts/StoreProvider";
import TopNavBar from "../../../../shared-components/NavBar/TopNavBar";
import Footer from "../../../../shared-components/Footer/Footer";


const ForgotPassword = () => {
    const { authStore } = useStore();
    const navigate = useNavigate();
    const { forgotPassword, forgotPasswordState, resetForgotPasswordState } = authStore
    const handleForgotPassword = (values: IForgotPassword) => {
        forgotPassword(values.email);
    };

    const handleReturnToLogin = () => {
        resetForgotPasswordState();
        navigate(RoutesConstants.Login)
    }

    return (
        <>
        <TopNavBar/>
            <div className="banner--light breadcrumb-area">
                <div className="container">
                    <h1 className="page-title"> <FormattedMessage id="forgot_password" /></h1>
                </div>
            </div>
            <ForgotPasswordForm
                forgotPasswordHandler={handleForgotPassword}
                handleReturnToLogin={handleReturnToLogin}
                initialValues={forgotPasswordInitialState}
                isLoading={forgotPasswordState.inProgress} 
                errors={forgotPasswordState.error}
                isSuccess={forgotPasswordState.success}/>
            <Footer />
        </>
    );
};

export default observer(ForgotPassword);
