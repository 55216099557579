import React, { useState } from "react";
const SendInvitiesEmailPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showSendInvitiesEmailPopup, setShowSendInvitiesEmailPopup] = useState<boolean>(false);

    const sendInvitiesEmailPopupToggleHandler = () => {
        setShowSendInvitiesEmailPopup(!showSendInvitiesEmailPopup);
    };

    return (
      <Component
      showSendInvitiesEmailPopup={showSendInvitiesEmailPopup}
      sendInvitiesEmailPopupToggleHandler={sendInvitiesEmailPopupToggleHandler}
        {...props}
      />
    );
  };

export default SendInvitiesEmailPopupHOC;