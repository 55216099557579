import React from 'react'
import { Alert } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

export const SendEmail_Error_TrainerInvities = () => {
    return (
        <>
            <div className='container' >
                <Alert variant='danger'> <FormattedMessage id="SendEmail_Error_TrainerInvities" /></Alert>
            </div>
        </>
    )
}


export const SendEmail_Error_SubscriptionExpiry = () => {
    return (
        <>
            <div className='container'>
                <Alert variant='danger'> <FormattedMessage id="SendEmail_Error_SubscriptionExpiry" /></Alert>
            </div>
        </>
    )
}
