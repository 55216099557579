import logo from '../../assets/Images/logo_original.png';
import React, { useState } from 'react';
import './KaleidoCompassIdea.css';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import pastIcon from "../../../assets/Images/svg/001-past.svg";
import databseIcon from "../../../assets/Images/svg/002-database.svg";
import careIcon from "../../../assets/Images/svg/003-care.svg";
import referIcon from "../../../assets/Images/svg/004-refer.svg";
import creativIcon from "../../../assets/Images/svg/005-creativity.svg";
import feedbackIcon from "../../../assets/Images/svg/006-feedback.svg";
import preceptionIcon from "../../../assets/Images/svg/007-perception.svg";
import rotationIcon from "../../../assets/Images/svg/008-rotation.svg";
import decisionIcon from "../../../assets/Images/svg/009-decision-making.svg";
import abstractIcon from "../../../assets/Images/svg/010-abstract-shape.svg";
import proactivIcon from "../../../assets/Images/svg/011-proactive.svg";
import deadlineIcon from "../../../assets/Images/svg/012-deadline.svg";
import orientationIcon from "../../../assets/Images/svg/013-arrows.svg";
import introImg from "../../../assets/Images/img-2-1.png";
import aboutImg from "../../../assets/Images/colored.png";

const KaleidoCompassIdea = (props: any) => {
    return (
        <>
            <div className="banner--light breadcrumb-area">
                <div className="container">
                    <h1 className="page-title"><FormattedMessage id="the_idea" /></h1>
                    <h3><FormattedMessage id="idea_paragraph_1" />   </h3>
                    <FormattedMessage id="idea_paragraph_2" />
                </div>
            </div>
            
            <section className='what_kaleido'>
                <div className='container'>
                    <div className='row flex-md-row-reverse align-items-md-center'>
                        <div className='col-lg-4 col-12'>
                            <img src={aboutImg} alt="" />
                        </div>
                        <div className='col-lg-7 col-12'>
                            <h2 className='about-title'><FormattedMessage id="what_kaleidocompass" /></h2>
                            <p className='text-dark'>
                                <FormattedMessage id="para_what_kaleidocompass_1" />
                            </p>                            
                            <p>
                                <FormattedMessage id="para_what_kaleidocompass_2" />
                            </p>
                            
                            <p>
                                <FormattedMessage id="para_what_kaleidocompass_3" />
                            </p>
                            <p>
                                <FormattedMessage id="para_what_kaleidocompass_4" />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className='thinnking_intro'>
                <div className='container'>
                    <div className='row  justify-content-between'>
                        <div className='col-xl-5 col-12 image-v4-item'>
                            <img src={introImg} alt="" />
                            <div className='tagline'>
                                <FormattedMessage id="idea_paragraph_1" />   
                            </div>
                        </div>
                        <div className='col-xl-7 col-12'>
                            <h2 className='about-title'><FormattedMessage id="thinking_Style" /></h2>
                            <p>
                                <FormattedMessage id="para_thinking_styles_1" />
                            </p>
                            
                            <p>
                                <FormattedMessage id="para_thinking_styles_2" />
                            </p>
                            
                            <p>
                                <FormattedMessage id="para_thinking_styles_3" />
                            </p>
                            
                            <p>
                                <FormattedMessage id="para_thinking_styles_4" />
                            </p>
                            
                            <p className='mb-md-5'>
                                <FormattedMessage id="para_thinking_styles_5" />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="thinking_styles">
                <div className="text-center">
                    <span className='section-small-title mx-auto'><FormattedMessage id="in_Short" /></span>
                    <h1 className="section-title text-center">
                        <FormattedMessage id="13_Thinking_Styles" />
                    </h1>

                    <div className="container">
                        <div className="row justify-content-around twoCard--wrapper">
                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={pastIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="orientations_PPF" /></h2>
                                    <p><FormattedMessage id="orientation_PPF_text" /></p>
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={databseIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="dimensions_of_info" /></h2>
                                    <p><FormattedMessage id="dimension_Text" /></p>
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={careIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="self_care" /></h2>
                                    <p><FormattedMessage id="self_care_text" /></p>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={referIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="internal_or_external" /></h2>
                                    <p><FormattedMessage id="IOE_text" /></p>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={creativIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="novelty_or_habit" /></h2>
                                    <p><FormattedMessage id="novelty_or_habit_text" /></p>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={feedbackIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="reaction" /></h2>
                                    <p><FormattedMessage id="reaction_text" /></p>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={preceptionIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="perceptive_channels" /></h2>
                                    <p><FormattedMessage id="pc_text" /></p>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={rotationIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="point_of_view" /></h2>
                                    <p><FormattedMessage id="pov_text" /></p>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={decisionIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="direction" /></h2>
                                    <p><FormattedMessage id="direction_text" /></p>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={abstractIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="concrete_or_abstract" /></h2>
                                    <p><FormattedMessage id="COA_text" /></p>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={proactivIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="proactive_or_reactive" /></h2>
                                    <p><FormattedMessage id="POA_Text" /></p>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={deadlineIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="deadline" /></h2>
                                    <p><FormattedMessage id="deadline_text" /></p>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="card--wrapper">
                                <div className="card--image">
                                    <img src={orientationIcon} alt="" className='img-fluid' />
                                </div>
                                <div className="card--text">
                                    <h2><FormattedMessage id="orientation_ROR" /></h2>
                                    <p><FormattedMessage id="orientation_text_ROR" /></p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default KaleidoCompassIdea;