import React, { useState } from "react";

const EditCouponHOC = (Component: any) =>
  function Comp(props: any) {
    const [showEditCouponPopup, setShowEditCouponPopup] = useState<boolean>(false);

    const editCouponToggleHandler = () => {
      setShowEditCouponPopup(!showEditCouponPopup);
    };

    return (
      <Component
        showEditCouponPopup={showEditCouponPopup}
        editCouponPopupToggleHandler={editCouponToggleHandler}
        {...props}
      />
    );
  };

export default EditCouponHOC;
