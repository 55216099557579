import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { FormattedMessage } from "react-intl";
import { observer } from "mobx-react";
import { Tab, Tabs, Nav, TabContainer, TabContent } from "react-bootstrap";
import TestView from "./TestView/TestView";
import Profile from "./Profile/Profile";
import ChangePassword from "./ChangePassword/ChangePassword";
import FriendRequest from "./FriendRequest/FriendRequest";
import { ITestInfo } from "../../models/ITestInfo";
import TestCompare from "../test-compare/TestCompare";
import { useLocation, useNavigate } from "react-router-dom";

const Dashboard = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [key, setKey] = useState<string>('tests');
  const [selectedTestsForComparison, setSelectedTestsForComparison] = useState<number[]>([])
  const handleRemoveAllTests = () => {
    setSelectedTestsForComparison([]);
  }


  useEffect(() => {
    // Comparison button clicked from the Result page.
    if (location.state !== null && location.state.testId && !Number.isNaN(location.state.testId)){
      if (location.state.friendTab){
        setKey("friends")
      }
      setSelectedTestsForComparison([Number(location.state.testId)]);
      // Clear the state after it's usage
      navigate(location.pathname, {replace: true}); 
    }
  }, [])
  return (
    <>
      <div className="dashboar-container  ">
        <div className="dashboard_heading">
          <div className="container">
            <h1>
              <FormattedMessage id="Dashboard" />
            </h1>
          </div>
        </div>
        <div className="">
          <Tab.Container
            defaultActiveKey={key}
            activeKey={key}
            onSelect={(k) => (k) ? setKey(k) : null}
            id="uncontrolled-tab-example"            
          >
            <div className="tabWrapper">
              <div className="container">
                <Nav variant="underline">
                  <Nav.Item>
                    <Nav.Link eventKey="tests"><FormattedMessage id="your_test" /></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="friends"><FormattedMessage id="Friends_Title" /></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="profile"><FormattedMessage id="profile" /></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="changePassword"><FormattedMessage id="change_password" /></Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
            <Tab.Content>
                <Tab.Pane eventKey="tests">
                  {key == "tests" &&
                    <TestView setSelectedTestsForComparison={setSelectedTestsForComparison} selectedTestsForComparison={selectedTestsForComparison}/>
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="friends" >
                  {key == "friends" &&
                    <FriendRequest setSelectedTestsForComparison={setSelectedTestsForComparison} selectedTestsForComparison={selectedTestsForComparison}/>
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="profile" >
                  {key == "profile" &&
                    <Profile />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="changePassword" >
                  {/* This is done to mount the Component only when required. */}
                  {key == "changePassword" &&
                    <ChangePassword />
                  }
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          {
            (key == "tests" || key == "friends") && 
              <TestCompare selectedTestsForComparison={selectedTestsForComparison} removeAll={handleRemoveAllTests}/>
          }
        </div>
      </div>
    </>
  );
};

export default observer(Dashboard);
