import React, { useState } from 'react'

const DetailsPopupHOC = (Component: any) =>
    function Comp(props: any) {
        const [showGraphPopup, setShowGraphPopup] = useState<boolean>(false);

        const createGraphToggleHandler = () => {
            setShowGraphPopup(!showGraphPopup);
        };
        return (
            <Component
                showGraphPopup={showGraphPopup}
                createGraphToggleHandler={createGraphToggleHandler}
                {...props}
            />
        )
    }

export default DetailsPopupHOC
