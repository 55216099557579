import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { useStore } from '../../../contexts/StoreProvider';
import Label from '../../../shared-components/Label/Label';
import FormikFormSelect from '../../../shared-components/FormikFormHandlers/FormikFormSelect';
import FormikFormInput from '../../../shared-components/FormikFormHandlers/FormikFormInput';
import { Box, CircularProgress } from '@mui/material';


const CurrencySchema = Yup.object().shape({
    JPY: Yup.number().required('Amount is required').positive('Amount must be positive'),
    EUR: Yup.number().required('Amount is required').positive('Amount must be positive'),
    USD: Yup.number().required('Amount is required').positive('Amount must be positive'),
});

export const exchangeRateOptions = [
    { id: "1", value: "JPY" },
    { id: "2", value: "USD" },
    { id: "3", value: "EUR" },
];

const CurrencySettings = (props: any) => {
    const { adminStore } = useStore();
    const { getPaymentSetting, getPaymentSettingsState, allPaymentSettingInfo, updatePaymentSettingsInfo, getPaymentDetail, addUpdatePaymentSettingState } = adminStore;
    const settingIndex = allPaymentSettingInfo.findIndex(setting => setting.ProductType === 1);
    const trainerSettingIndex = allPaymentSettingInfo.findIndex(setting => setting.ProductType === 2);
    useEffect(() => {
        getPaymentSetting();
    }, []);
    useEffect(() => {
        if (addUpdatePaymentSettingState.success) {
            getPaymentSetting();
        }
    }, [addUpdatePaymentSettingState.success]);

    return (
        <>
            {
                getPaymentSettingsState.inProgress || (!getPaymentSettingsState.success && getPaymentSettingsState.error === '' ) 
                ?
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px'}}>
                        <CircularProgress disableShrink={true}/>
                    </Box>
                </>
                :
            allPaymentSettingInfo.length !== 0 && (
                <>
                    <div className="dashboardTopbar">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-7">
                                    <p className="text-dark"><FormattedMessage id={"current_currency"} />: &nbsp;JPY </p>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                    <div className='container'>
                        <Formik
                            initialValues={getPaymentDetail}

                            onSubmit={(values) => {
                                updatePaymentSettingsInfo(values);
                                // console.log(values);
                                // toast.success('Form submitted successfully!');
                            }}
                        >
                            {({ errors, touched }) => (
                                <Form className='mw-100'>
                                    <div className='bg-light p-3'>
                                        <h3 className="section-small-title mb-0 fw-bold"><FormattedMessage id={"Test_Price"} /></h3>
                                        <p className='text-muted'><FormattedMessage id="Amount_Paid_Currency" /></p>
                                        <div className='row align-items-center'>

                                            <div className='col-12 col-lg-3 form-group'>
                                                <Label
                                                    required={true}
                                                    className="fw-bold"
                                                    label="JPY"
                                                    htmlFor={`PaymentSettingList[${settingIndex}].JPY`} />
                                                <div className='input-group'>
                                                    <span className='input-group-text'><i className='fa fa-yen-sign'></i></span>
                                                    <Field
                                                        type='text'
                                                        className='form-control'
                                                        name={`PaymentSettingList[${settingIndex}].JPY`}
                                                        data-testid={`PaymentSettingList[${settingIndex}].JPY`}
                                                        as={FormikFormInput} />
                                                </div>
                                            </div>
                                            <div className='col-12 col-lg-3 form-group'>
                                                <Label
                                                    required={true}
                                                    className="fw-bold"
                                                    label="EUR"
                                                    htmlFor={`PaymentSettingList[${settingIndex}].EUR`} />
                                                <div className='input-group'>
                                                    <span className='input-group-text'><i className='fa fa-euro-sign'></i></span>
                                                    <Field
                                                        type='text'
                                                        className='form-control'
                                                        name={`PaymentSettingList[${settingIndex}].EUR`}
                                                        data-testid={`PaymentSettingList[${settingIndex}].EUR`}
                                                        as={FormikFormInput} />
                                                </div>
                                            </div>
                                            <div className='col-12 col-lg-3 form-group'>
                                                <Label
                                                    required={true}
                                                    className="fw-bold"
                                                    label="USD"
                                                    htmlFor={`PaymentSettingList[${settingIndex}].USD`} />
                                                <div className='input-group'>
                                                    <span className='input-group-text'><i className='fa fa-dollar-sign'></i></span>
                                                    <Field
                                                        type='text'
                                                        className='form-control'
                                                        name={`PaymentSettingList[${settingIndex}].USD`}
                                                        data-testid={`PaymentSettingList[${settingIndex}].USD`}
                                                        as={FormikFormInput} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=' p-3'>
                                        <h3 className="section-small-title mb-0 fw-bold"><FormattedMessage id={"setting-2"} /></h3>
                                        <p className='text-muted'><FormattedMessage id="Trainer_Dashboard_Subscription" /></p>
                                        <div className='row align-items-center'>

                                            <div className='col-12 col-lg-3 form-group'>
                                                <Label
                                                    required={true}
                                                    className="fw-bold"
                                                    label="JPY"
                                                    htmlFor={`PaymentSettingList[${trainerSettingIndex}].JPY`} />
                                                <div className='input-group'>
                                                    <span className='input-group-text'><i className='fa fa-yen-sign'></i></span>
                                                    <Field
                                                        type='text'
                                                        className='form-control'
                                                        name={`PaymentSettingList[${trainerSettingIndex}].JPY`}
                                                        data-testid={`PaymentSettingList[${trainerSettingIndex}].JPY`}
                                                        as={FormikFormInput} />
                                                </div>
                                            </div>
                                            <div className='col-12 col-lg-3 form-group'>
                                                <Label
                                                    required={true}
                                                    className="fw-bold"
                                                    label="EUR"
                                                    htmlFor={`PaymentSettingList[${trainerSettingIndex}].EUR`} />
                                                <div className='input-group'>
                                                    <span className='input-group-text'><i className='fa fa-euro-sign'></i></span>
                                                    <Field
                                                        type='text'
                                                        className='form-control'
                                                        name={`PaymentSettingList[${trainerSettingIndex}].EUR`}
                                                        data-testid={`PaymentSettingList[${trainerSettingIndex}].EUR`}
                                                        as={FormikFormInput} />
                                                </div>
                                            </div>
                                            <div className='col-12 col-lg-3 form-group'>
                                                <Label
                                                    required={true}
                                                    className="fw-bold"
                                                    label="USD"
                                                    htmlFor={`PaymentSettingList[${trainerSettingIndex}].USD`} />
                                                <div className='input-group'>
                                                    <span className='input-group-text'><i className='fa fa-dollar-sign'></i></span>
                                                    <Field
                                                        type='text'
                                                        className='form-control'
                                                        name={`PaymentSettingList[${trainerSettingIndex}].USD`}
                                                        data-testid={`PaymentSettingList[${trainerSettingIndex}].USD`}
                                                        as={FormikFormInput} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <table className='table table-striped mt-4'>
                                        <thead className='bg-dark'>
                                            <tr>
                                                <th className=' text-white'>
                                                    <FormattedMessage id="email" />
                                                </th>
                                                <th className=' text-white'>
                                                    <FormattedMessage id="JPY" />
                                                </th>
                                                <th className=' text-white'>
                                                    <FormattedMessage id="EUR" />
                                                </th>
                                                <th className=' text-white'>
                                                    <FormattedMessage id="USD" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allPaymentSettingInfo.map((setting, index: number) => (
                                                (index !== settingIndex)  && (index !== trainerSettingIndex) && (
                                                <tr key={index} className=''>
                                                    <td>
                                                        <i className="fa fa-envelope text-muted me-2"></i> <span className='text-dark fw-bold'><FormattedMessage id={`setting-${setting.ProductType}`} /> </span>
                                                    </td>
                                                    <td>
                                                        <div className='input-group'>
                                                            <span className='input-group-text'><i className='fa fa-yen-sign'></i></span>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                name={`PaymentSettingList[${index}].JPY`}
                                                                data-testid={`PaymentSettingList[${index}].JPY`}
                                                                as={FormikFormInput} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='input-group'>
                                                            <span className='input-group-text'><i className='fa fa-euro-sign'></i></span>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                name={`PaymentSettingList[${index}].EUR`}
                                                                data-testid={`PaymentSettingList[${index}].EUR`}
                                                                as={FormikFormInput} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='input-group'>
                                                            <span className='input-group-text'><i className='fa fa-dollar-sign'></i></span>
                                                            <Field
                                                                type='text'
                                                                className='form-control'
                                                                name={`PaymentSettingList[${index}].USD`}
                                                                data-testid={`PaymentSettingList[${index}].USD`}
                                                                as={FormikFormInput} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )))}
                                        </tbody>
                                    </table>

                                    <div className='mt-4'>
                                        <div className='col-12 col-md-6 form-group'>
                                            <Label
                                                required={true}
                                                label="PreferredCurrency"
                                                className="section-small-title mb-0 fw-bold"
                                                htmlFor="PreferredCurrency"
                                            />
                                            <Field
                                                data-testid="PreferredCurrency"
                                                className="form-control"
                                                name="PreferredCurrency"
                                                placeholder="Exchange Rate"
                                                as={FormikFormSelect}
                                                options={exchangeRateOptions}
                                            />

                                        </div>
                                    </div>

                                    <Button type='submit' className='btn btn-primary'>
                                        <FormattedMessage id="button_save" />
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </>
            )}
        </>
    );
}

export default observer(CurrencySettings);
