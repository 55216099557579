import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ISentTrainerInvities } from "../../../models/response/ITrainerResponse";
import { Field, Form, Formik } from "formik";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { SentTrainerInvitiesValidationSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { Button, Modal } from "react-bootstrap";
import Label from "../../../shared-components/Label/Label";
import { SendEmail_Error_SubscriptionExpiry, SendEmail_Error_TrainerInvities } from "./SendEmail_Error";
import { FormattedMessage } from "react-intl";
import FormikMultiEmailField from "../../../shared-components/FormikFormHandlers/FormikMultiEmailField";

interface IProps {
    initialValues: ISentTrainerInvities;
    submitHandler: (data: ISentTrainerInvities) => void;
    allTrainerGroups: any
    modalClosed: () => void;
    addSentTrainerInvitiesState: any
    allTrainerInvitiesCount: any;
    isLoading: boolean;
}

export const TestTypes = [
    { id: "", "value": "please_select" },
    { id: 1, value: "LIFE" },
    { id: 2, value: "TRAINING" },
    { id: 3, value: "RACING" },
    { id: 4, value: "JOB" },
];

export const ResultTypes = [
    { id: "", value: "please_select" },
    { id: "1", value: "Result_visible" },
    { id: "0", value: "Result_invisible" },
];

const SendEmail_Form = (props: IProps) => {
    const { initialValues, submitHandler, allTrainerGroups, allTrainerInvitiesCount } = props;
    return (
        <>
            <Modal show={true} centered
                onHide={props.modalClosed}
                backdrop="static"
                scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id="SentInvities_heading" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        allTrainerInvitiesCount.TrainerInvites === 0 ?
                            <>
                                <SendEmail_Error_TrainerInvities />
                                {
                                    !allTrainerInvitiesCount.IsValidSubscriptionDate ? <SendEmail_Error_SubscriptionExpiry /> : <></>
                                }
                            </>
                            : !allTrainerInvitiesCount.IsValidSubscriptionDate ? <SendEmail_Error_SubscriptionExpiry /> :
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={SentTrainerInvitiesValidationSchema}
                                    enableReinitialize
                                    validateOnBlur={false}
                                    onSubmit={async (values: ISentTrainerInvities) => {
                                        values.IsResultVisible = !!parseInt(values.IsResultVisible)
                                        submitHandler(values);
                                    }}
                                    render={({ values, handleBlur, handleChange, errors, touched, setTouched, setFieldTouched }) => {
                                        return (
                                            <Form id="sentInvitiesForm" className="modal-form-SendInvities">
                                                <div className="row ">
                                                    <div className="col-12">
                                                        <div className='form-group' >
                                                            <label className='fw-bold'><FormattedMessage id="email" /></label>
                                                                <Field
                                                                    data-testid="RecipientUserEmail"
                                                                    name="RecipientUserEmail"
                                                                    as={FormikMultiEmailField}
                                                                />

                                                                {/* <ReactMultiEmail
                                                                    className="form-control flex-grow-1"
                                                                    placeholder='jondoe@example.com'
                                                                    emails={emails}
                                                                    onChange={(_emails: string[]) => {
                                                                        setEmails(_emails);
                                                                        values.RecipientUserEmail = _emails;
                                                                        setTouched(touched);
                                                                    }}
                                                                    autoFocus={true}
                                                                    onFocus={() => setFocused(true)}
                                                                    onBlur={() => setFocused(false)}
                                                                    getLabel={(email, index, removeEmail) => {
                                                                        return (
                                                                            <div data-tag key={index}>
                                                                            <div data-tag-item>{email}</div>
                                                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                                                                ×
                                                                            </span>
                                                                            </div>
                                                                        );
                                                                    }}
                                                                /> */}

                                                        </div>
                                                        <div className="form-group ">
                                                            <Label
                                                                required={true}
                                                                className="fw-bold"
                                                                label="Message"
                                                                htmlFor="Message" />
                                                            <Field
                                                                data-testid="Message"
                                                                className="form-control"
                                                                name="Message"
                                                                // getData={true}
                                                                as={FormikFormTextArea}
                                                                invalidcharacter={[" "]}
                                                            />
                                                        </div>
                                                        <div className="form-group ">
                                                            <Label
                                                                required={true}
                                                                className="fw-bold"
                                                                label="trainer_invite_title"
                                                                htmlFor="Title" />
                                                            <Field
                                                                data-testid="Title"
                                                                className="form-control"
                                                                name="Title"
                                                                as={FormikFormTextArea}
                                                                invalidcharacter={[" "]}
                                                               
                                                            />
                                                        </div>
                                                        <div className="form-group ">
                                                            <Label
                                                                className="fw-bold"
                                                                label="group_name"
                                                                htmlFor="GroupName" />
                                                            <Field
                                                                data-testid="GroupName"
                                                                className="form-control"
                                                                name="GroupName"
                                                                // getData={true}
                                                                options={allTrainerGroups}
                                                                as={FormikFormSelect}
                                                                invalidcharacter={[" "]}
                                                            />
                                                        </div>
                                                        <div className="form-group ">
                                                            <Label
                                                                required={true}
                                                                className="fw-bold"
                                                                label="test_type"
                                                                htmlFor="TestType" />
                                                            <Field
                                                                data-testid="TestType"
                                                                className="form-control"
                                                                name="TestType"
                                                                // getData={true}
                                                                options={TestTypes}
                                                                as={FormikFormSelect}
                                                            />
                                                        </div>
                                                        <div className="form-group ">
                                                            <Label
                                                                required={true}
                                                                className="fw-bold"
                                                                label="result_visible"
                                                                htmlFor="IsResultVisible" />
                                                            <Field
                                                                data-testid="IsResultVisible"
                                                                className="form-control"
                                                                name="IsResultVisible"
                                                                options={ResultTypes}
                                                                as={FormikFormSelect}
                                                            />
                                                        </div>
                                                    </div >
                                                    {/* <div className="col-12 mt-3">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <p> <FormattedMessage id="SentInvities_title_1" /></p>
                                                            </div>
                                                        </div>
                                                        <div className="card mt-3">
                                                            <div className="card-body">
                                                                <p> <FormattedMessage id="SentInvities_title_2" /></p>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </Form>
                                        );
                                    }}
                                >
                                </Formik>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end ">
                        <Button
                            data-testid="Cancel"
                            variant="secondary"
                            disabled={props.isLoading}
                            onClick={props.modalClosed}
                            className="me-2">
                            <FormattedMessage id="button_cancel" />
                        </Button>
                        <LoaderButton
                            type="submit"
                            id="SentInvities"
                            className=""
                            form="sentInvitiesForm"
                            isLoading={props.isLoading}
                            text={"send"}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default observer(SendEmail_Form);