import React, { useState } from "react";

const CreateProfileHOC = (Component: any) =>
  function Comp(props: any) {
    const [showCreateProfilePopup, setShowCreateProfilePopup] = useState<boolean>(false);

    const createProfileToggleHandler = () => {
      setShowCreateProfilePopup(!showCreateProfilePopup);
    };

    return (
      <Component
        showCreateProfilePopup={showCreateProfilePopup}
        createProfilePopupToggleHandler={createProfileToggleHandler}
        {...props}
      />
    );
  };

export default CreateProfileHOC;
