import React, { useEffect, useState } from 'react'
import { Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import Label from '../../../shared-components/Label/Label';
import FormikFormInput from '../../../shared-components/FormikFormHandlers/FormikFormInput';
import LoaderButton from '../../../shared-components/Button/LoaderButton';
import { Button } from 'react-bootstrap';
import { initialState as ChangePasswordInitialState } from '../../../core/initial-state/change-password-state';
import RoutesConstants from '../../../shared-components/Routes/routes.constants';
import './ChangePassword.css';
import PasswordStrengthBar from 'react-password-strength-bar';
import { formatMessage } from '../../../translations/format-message';
import { useStore } from '../../../contexts/StoreProvider';
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';
import { ChangePasswordValidationSchema } from '../../../shared-components/FormikFormHandlers/FormikValidationSchema';

const ChangePassword = () => {
    const [showHideCurrentPassword, setShowHideCurrentPassword] = useState(false);
    const [showHideNewPassword, setShowHideNewPassword] = useState(false);
    const [showHideConfirmPassword, setShowHideConfirmPassword] = useState(false);
    const { authStore } = useStore();
    const { changePassword, changePasswordState, resetChangePasswordState } = authStore;

    useEffect(() =>{
        if (changePasswordState.success){
            const changePasswordForm = document.getElementById("changePasswordForm") as HTMLFormElement; 
            changePasswordForm.reset();
            resetChangePasswordState();
            // toast.success(formatMessage("forgot_reset_password_message"));
        }
    }, [changePasswordState.success]);

    return (
        <>
            <div className='container'>
                <Formik
                    initialValues = {ChangePasswordInitialState}
                    validationSchema = {ChangePasswordValidationSchema}
                    validateOnBlur = {false}
                    onSubmit = {async (values) => {
                        changePassword(values)
                    }}
                >
                    {({ values, handleChange, errors }) => (
                    <>
                    <Form id="changePasswordForm">
                        <div className="showHideOuterDiv form-group">
                            <Label
                                required={true}
                                label="current_password"
                                htmlFor="CurrentPassword"
                            />
                            <Field
                                data-testid="CurrentPassword"
                                id="CurrentPassword"
                                className="form-control"
                                name="currentPassword"
                                type={showHideCurrentPassword ? "text" : "password"}
                                invalidcharacter={[" "]}
                                validcharacter={[
                                "~",
                                "`",
                                "!",
                                "@",
                                "#",
                                "$",
                                "%",
                                "^",
                                "&",
                                "*",
                                "(",
                                ")",
                                "_",
                                "-",
                                "+",
                                "=",
                                "{",
                                "[",
                                "}",
                                "]",
                                "|",
                                "\\",
                                ":",
                                ";",
                                "'",
                                '"',
                                "<",
                                ",",
                                ">",
                                ".",
                                "?",
                                "/",
                                ]}
                                placeholder="current_password"
                                as={FormikFormInput}
                            />
                            {showHideCurrentPassword ? (
                                <svg
                                type="button"
                                onClick={() => {
                                    setShowHideCurrentPassword(!showHideCurrentPassword);
                                }}
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="showHidePass css-i6dzq1"
                                >
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                            ) : (
                                <svg
                                type="button"
                                onClick={() => {
                                    setShowHideCurrentPassword(!showHideCurrentPassword);
                                }}
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="showHidePass css-i6dzq1"
                                >
                                <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                                <line x1="1" y1="1" x2="23" y2="23"></line>
                                </svg>
                            )}
                        </div>
                        <div className="showHideOuterDiv form-group">
                            <Label
                                required={true}
                                label="new_password"
                                htmlFor="NewPassword"
                            />
                            <Field
                                data-testid="NewPassword"
                                id="NewPassword"
                                className="form-control"
                                name="newPassword"
                                type={showHideNewPassword ? "text" : "password"}
                                invalidcharacter={[" "]}
                                validcharacter={[
                                "~",
                                "`",
                                "!",
                                "@",
                                "#",
                                "$",
                                "%",
                                "^",
                                "&",
                                "*",
                                "(",
                                ")",
                                "_",
                                "-",
                                "+",
                                "=",
                                "{",
                                "[",
                                "}",
                                "]",
                                "|",
                                "\\",
                                ":",
                                ";",
                                "'",
                                '"',
                                "<",
                                ",",
                                ">",
                                ".",
                                "?",
                                "/",
                                ]}
                                placeholder="new_password"
                                as={FormikFormInput}
                            />
                            {showHideNewPassword ? (
                                <svg
                                type="button"
                                onClick={() => {
                                    setShowHideNewPassword(!showHideNewPassword);
                                }}
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="showHidePass css-i6dzq1"
                                >
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                            ) : (
                                <svg
                                type="button"
                                onClick={() => {
                                    setShowHideNewPassword(!showHideNewPassword);
                                }}
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="showHidePass css-i6dzq1"
                                >
                                <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                                <line x1="1" y1="1" x2="23" y2="23"></line>
                                </svg>
                            )}

                            {/* { values.newPassword.length > 0 &&  
                                <PasswordStrengthBar
                                    // scoreWords={[ formatMessage("Too Short"), formatMessage("Weak"), formatMessage("Okay"), formatMessage("Good"), formatMessage("Strong")]}
                                    className="mt-3"
                                    password={values.newPassword}
                                />
                            } */}
                        </div>

                        <div className="showHideOuterDiv form-group">
                            <Label
                                required={true}
                                label="confirm_password"
                                htmlFor="ConfirmPassword"
                            />
                            <Field
                                data-testid="ConfirmPassword"
                                id="ConfirmPassword"
                                className="form-control"
                                name="confirmPassword"
                                type={showHideConfirmPassword ? "text" : "password"}
                                invalidcharacter={[" "]}
                                validcharacter={[
                                "~",
                                "`",
                                "!",
                                "@",
                                "#",
                                "$",
                                "%",
                                "^",
                                "&",
                                "*",
                                "(",
                                ")",
                                "_",
                                "-",
                                "+",
                                "=",
                                "{",
                                "[",
                                "}",
                                "]",
                                "|",
                                "\\",
                                ":",
                                ";",
                                "'",
                                '"',
                                "<",
                                ",",
                                ">",
                                ".",
                                "?",
                                "/",
                                ]}
                                placeholder="confirm_password"
                                as={FormikFormInput}
                            />
                            {showHideConfirmPassword ? (
                                <svg
                                type="button"
                                onClick={() => {
                                    setShowHideConfirmPassword(!showHideConfirmPassword);
                                }}
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="showHidePass css-i6dzq1"
                                >
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                            ) : (
                                <svg
                                type="button"
                                onClick={() => {
                                    setShowHideConfirmPassword(!showHideConfirmPassword);
                                }}
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="showHidePass css-i6dzq1"
                                >
                                <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                                <line x1="1" y1="1" x2="23" y2="23"></line>
                                </svg>
                            )}
                        </div>
                        <div className="text-center mt-3">
                            <LoaderButton
                                type="submit"
                                id="LoginButton"
                                className="btn btn-primary btn-user"
                                // isLoading={props.isLoading}
                                text="update"
                            />
                        </div>
                    </Form>
                    </>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default observer(ChangePassword);
